export interface IResult {
    message: string
    error: boolean
    success: boolean
    errorFields: string[]
    formType?: string
}

export class ResultFactory {
    static create(): IResult {
        return {
            message: "",
            error: false,
            success: false,
            errorFields: [],
        }
    }

    static createSuccess(message: string, formType?: string): IResult {
        if (formType) {
            return {
                message: message,
                error: false,
                success: true,
                errorFields: [],
                formType,
            }
        }
        return {
            message: message,
            error: false,
            success: true,
            errorFields: [],
        }
    }

    static createError(message: string, errorFields: string[], formType?: string): IResult {
        if (formType) {
            return {
                message: message,
                error: true,
                success: false,
                errorFields,
                formType,
            }
        }
        return {
            message: message,
            error: true,
            success: false,
            errorFields,
        }
    }
}

export function setErrors(incorrectFields: string[], errors: { [key: string]: boolean }): void {
    for (const [key] of Object.entries(errors)) {
        errors[key] = false
    }
    for (const [key] of Object.entries(errors)) {
        for (const f of incorrectFields) {
            if (key.toLowerCase() === f.toLowerCase()) {
                errors[key] = true
            }
        }
    }
}
