export interface DependsOnData {
    CompanyIds?: string[]
    OpsCsIds?: string[]
    SectorPcIds?: string[]
    DepartmentIds?: string[]
    JobGroupIds?: string[]
}

export class DependsOnDataFactory {
    static create(): DependsOnData {
        return {
            CompanyIds: [],
            OpsCsIds: [],
            SectorPcIds: [],
            DepartmentIds: [],
            JobGroupIds: [],
        }
    }
}
