import { FileContent, FileContentFactory } from ".."

export interface CreateNews {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    link: string
    image: FileContent
}

export class CreateNewsFactory {
    static create(): CreateNews {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            descriptions: {
                hr: "",
                en: "",
            },
            link: "",
            image: FileContentFactory.create(),
        }
    }
}

enum CreateNewsKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    Descriptions = "descriptions",
    Link = "link",
    Image = "image",
}

export function CreateNewsKeysObject() {
    return {
        IsDraft: CreateNewsKeys.IsDraft,
        IsActive: CreateNewsKeys.IsActive,
        Names: CreateNewsKeys.Names,
        Descriptions: CreateNewsKeys.Descriptions,
        Link: CreateNewsKeys.Link,
        Image: CreateNewsKeys.Image,
    }
}

export function CreateEmptyNewsErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateNewsKeys.IsActive]: false,
        [CreateNewsKeys.Image]: false,
        [CreateNewsKeys.Names]: false,
        [CreateNewsKeys.Descriptions]: false,
        [CreateNewsKeys.Link]: false,
    }
}
