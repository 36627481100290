<button class="valben-button" (click)="showModal()">Detalji</button>
<p-dialog
    header=""
    [(visible)]="visible"
    [modal]="true"
    styleClass="w-[60vw] h-[80vh]">
    <div class="h-full w-full flex">
        <div [id]="mapId" class="border-4 rounded-md border-valamargray h-full w-full"></div>
        <p *ngIf="!this.data.latitude && !this.data.longitude">Nema podataka za prikaz.</p>
    </div>
</p-dialog>

