import { Pipe, PipeTransform } from "@angular/core"
import { ContextService } from "../services"

@Pipe({
    name: "costCenterNames",
    pure: false,
})
export class CostCenterNamesPipe implements PipeTransform {
    constructor(private _cs: ContextService) { }

    transform(costCenterIds: string[]): string[] {
        return costCenterIds.map(id => this._cs.CostCenters().find(cc => cc.masterId === id)?.names["hr"] || `Nepoznato (${id})`)
    }
}
