export interface CreateSurvey {
    isDraft: boolean
    isActive: boolean
    name: string
    description: string
    link: string
}

export class CreateSurveyFactory {
    static create(): CreateSurvey {
        return {
            isDraft: false,
            isActive: false,
            name: "",
            description: "",
            link: "",
        }
    }
}

enum CreateSurveyKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Name = "name",
    Description = "description",
    Link = "link",
}

export function CreateSurveyKeysObject() {
    return {
        IsDraft: CreateSurveyKeys.IsDraft,
        IsActive: CreateSurveyKeys.IsActive,
        Name: CreateSurveyKeys.Name,
        Description: CreateSurveyKeys.Description,
        Link: CreateSurveyKeys.Link,
    }
}

export function CreateEmptySurveyErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateSurveyKeys.IsActive]: false,
        [CreateSurveyKeys.Name]: false,
        [CreateSurveyKeys.Description]: false,
        [CreateSurveyKeys.Link]: false,
    }
}
