import { Component, Input } from "@angular/core"

@Component({
    selector: "valben-virtual-listbox",
    templateUrl: "./virtual-listbox.component.html",
})
export class VirtualListBoxComponent {
    @Input() title: string = ""
    @Input() items: string[] = []

    filterItems: string[] = []
    filtering: boolean = false

    filter(val: string) {
        if (!val) {
            this.filtering = false
            this.filterItems = this.items
        } else {
            this.filtering = true
            this.filterItems = this.items.filter(el => el.toLowerCase().includes(val.toLowerCase()))
        }
    }
}
