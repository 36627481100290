import { Component, Input, OnInit } from "@angular/core"
import { PhoneNumberPrefixes } from "src/app/helpers/PhoneNumberPrefixes"
import { Observable } from "rxjs"
import { compareContactObject } from "src/app/helpers/Comparators"

@Component({
    selector: "valben-contact-input",
    templateUrl: "./contact-input.component.html",
    styleUrls: ["../../helpers/flags.css"],
})
export class ContactInputComponent implements OnInit {
    @Input() title: string = ""
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() currentItem!: () => any | undefined
    @Input() error: boolean = false

    selectedCountry: { code: string; number: string } | undefined = undefined

    countries: { code: string; number: string }[] | undefined = []

    currentValues: { email: string; phoneNumber: string } = {
        email: "",
        phoneNumber: "",
    }

    onChangeEmail() {
        const value = this.currentItem()[this.key]
        value.email = this.currentValues.email
        this.update(value)
    }

    phoneNumberPrefixes(): { code: string; number: string }[] {
        return PhoneNumberPrefixes.getFormated()
    }

    selectCountryPrefix() {
        if (this.selectedCountry) {
            const value = this.currentItem()[this.key]
            value.phoneNumber = "+" + this.selectedCountry.number
            this.update(value)
        }
    }

    onChangePhoneNumber() {
        const value = this.currentItem()[this.key]
        value.phoneNumber = this.currentValues.phoneNumber
        if (value.phoneNumber === "") {
            this.selectedCountry = undefined
        }
        this.update(value)
    }

    ngOnInit(): void {
        this.countries = this.phoneNumberPrefixes()
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (!compareContactObject(this.currentValues, value[this.key])) {
                    this.currentValues = JSON.parse(JSON.stringify(value[this.key]))
                    if (this.currentValues.phoneNumber === "") {
                        this.selectedCountry = undefined
                    }
                }
            }
        })
    }
}
