<div
    class="flex flex-col px-10 py-4 border-2 border-gray-900/10 rounded-md"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1">{{ title }}</div>
    <div class="p-4 w-1/3">
        <div class="py-2 text-lg">Email</div>
        <input
            class="w-full h-10 border focus:outline-none rounded-md text-md p-2"
            type="email"
            placeholder="Unesite email"
            [(ngModel)]="currentValues.email"
            (keyup)="onChangeEmail()"
        />
        <div class="py-2 text-lg">Broj mobitela</div>
        <p-dropdown
            (onClick)="selectCountryPrefix()"
            placeholder="Odaberite državu"
            [editable]="false"
            [autoDisplayFirst]="false"
            [options]="countries"
            [filter]="true"
            styleClass="w-52"
            panelStyleClass="w-52"
            [(ngModel)]="selectedCountry"
            optionLabel="number"
            [virtualScroll]="true"
            [virtualScrollItemSize]="48"
        >
            <ng-template let-country pTemplate="item">
                <div class="flex items-center justify-between px-4 gap-4">
                    <img
                        src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        [class]="'flag flag-' + country.code.toLowerCase()"
                        style="width: 18px"
                    />
                    <div class="font-semibold">{{ country.number | phoneNumberPrefix }}</div>
                </div>
            </ng-template>
            <ng-template pTemplate="selectedItem">
                <div class="flex items-center justify-between pl-4 gap-4" *ngIf="selectedCountry">
                    <img
                        src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                        [class]="'flag flag-' + selectedCountry.code.toLowerCase()"
                        style="width: 18px"
                    />
                    <div class="font-semibold">{{ selectedCountry.number | phoneNumberPrefix }}</div>
                </div>
            </ng-template>
        </p-dropdown>
        <div class="py-2">
            <input
                class="w-52 h-10 border focus:outline-none rounded-md text-md p-2"
                valbenOnlyNumbers
                type="text"
                placeholder="Unesite broj mobitela"
                [(ngModel)]="currentValues.phoneNumber"
                (keyup)="onChangePhoneNumber()"
            />
        </div>
    </div>
</div>
