<div
    class="flex px-10 py-4 border-2 border-gray-900/10 rounded-md gap-2 items-center"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1 items-center">
        {{ title }}
        <span *ngIf="optional" class="italic text-xs font-light px-1">neobavezno</span>
    </div>
    <p-dropdown
        (onChange)="onChange()"
        [editable]="false"
        [autoDisplayFirst]="false"
        [options]="options"
        [filter]="true"
        styleClass="w-52"
        panelStyleClass="w-52"
        [(ngModel)]="selectedOption"
        optionLabel="name"
    ></p-dropdown>
</div>
