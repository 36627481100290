import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-boolean-input",
    templateUrl: "./boolean-input.component.html",
})
export class BooleanInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() optional: boolean = false
    @Input() error: boolean = false

    currentValue: boolean = false

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (value[this.key] !== this.currentValue) {
                    this.currentValue = value[this.key]
                }
            }
        })
    }

    onChange() {
        this.update(this.currentValue)
    }
}
