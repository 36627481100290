<div *ngFor="let value of values" class="text-xl mb-10 space-y-4">
    <div class="flex gap-5 items-center">
        <hr class="h-2 bg-valamardarkgray rounded-xl w-[10rem]">
        <p class="whitespace-nowrap text-black">Verzija {{ value.version }}</p>
        <hr class="h-2 bg-valamardarkgray rounded-xl w-full">
    </div>

    <div class="flex gap-10">
        <div>
            <b>Izmjena:</b><p class="ml-5 inline-block">{{ value.modifiedByUser }}</p>
        </div>
        <div>
            <b>Vrijeme izmjene:</b><p class="ml-5 inline-block">{{ value.modifiedDate | date: "dd.MM.yyyy., HH:mm:ss" }}</p>
        </div>
    </div>
    <div class="flex gap-10">
        <div class="flex items-center">
            <b>Aktivan:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isActive" [binary]="true" />
        </div>
        <div class="flex items-center">
            <b>Skica:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isDraft" [binary]="true" />
        </div>
    </div>
    <div>
        <b>Media link:</b>
        <a *ngIf="value.contentUrl; else defaultView" href="{{ value.contentUrl }}" target="_blank" class="break-all ml-5 hover:text-valamarbluesecondary cursor-pointer">{{ value.contentUrl }}</a>
    </div>
</div>

<ng-template #defaultView>
    <p class="inline-block ml-5">/</p>
</ng-template>
