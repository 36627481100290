import { FileContent, FileContentFactory } from ".."

export interface CreatePromotionalContent {
    isDraft: boolean
    isActive: boolean
    media: FileContent
}

export class CreatePromotionalContentFactory {
    static create(): CreatePromotionalContent {
        return {
            isDraft: false,
            isActive: false,
            media: FileContentFactory.create(),
        }
    }
}

enum CreatePromotionalContentKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Media = "media",
}

export function CreatePromotionalContentKeysObject() {
    return {
        IsDraft: CreatePromotionalContentKeys.IsDraft,
        IsActive: CreatePromotionalContentKeys.IsActive,
        Media: CreatePromotionalContentKeys.Media,
    }
}

export function CreateEmptyPromotionalContentErrorsObject(): { [key: string]: boolean } {
    return {
        [CreatePromotionalContentKeys.IsActive]: false,
        [CreatePromotionalContentKeys.Media]: false,
    }
}
