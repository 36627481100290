<div class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
        <div class="text-lg font-semibold my-1">Spol</div>
        <div class="flex justify-end items-center gap-4">
            <button class="valben-button" (click)="closeOnClick()">X</button>
            <button class="valben-button" (click)="confirmOnClick()">Spremi</button>
        </div>
    </div>
    <div *ngFor="let g of genders" class="mb-4">
        <div class="flex flex-row gap-4 w-[10rem]">
            <button
                class="text-sm px-4 py-2 rounded-md border-2 border-valamarblueprimary cursor-pointer w-full text-center"
                (click)="genderCheckboxClicked(g.value)"
                [ngClass]="g.checked ? 'bg-valamarblueprimary text-valamargray' : 'bg-transparent text-valamarblueprimary'"
            >
                {{ g.label }}
            </button>
        </div>
    </div>
</div>
