import { Component, Input } from "@angular/core"
import { Observable, map } from "rxjs"
import { Category } from "../../models"
import { ContextService } from "../../services"

@Component({
    selector: "valben-categories-multiple-choice",
    templateUrl: "./categories-multiple-choice.component.html",
})
export class CategoriesMultipleChoiceComponent {
    @Input() key!: string
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() type!: string
    @Input() title: string = ""
    @Input() error: boolean = false

    dataObservable: () => Observable<any> | undefined = () =>
        this.contextService
            .getCategoriesSubject()
            ?.asObservable()
            .pipe(
                map(data => {
                    return data.filter((category: Category) => {
                        return category.type.toLowerCase() === this.type.toLowerCase()
                    })
                })
            )

    constructor(private contextService: ContextService) {}
}
