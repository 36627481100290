<div
    class="flex flex-col px-10 py-4 border-2 border-gray-900/10 rounded-md"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1">
        {{ title }}
        <span *ngIf="optional" class="italic text-xs font-light">neobavezno</span>
    </div>
    <p-calendar
        class="max-w-full"
        (onSelect)="onSelect()"
        (onClearClick)="onClear()"
        [(ngModel)]="date"
        [showButtonBar]="true"
        [inline]="true"
        [showWeek]="true" />
</div>
