import { Component, Input } from "@angular/core"
import { PromotionalContent } from "src/app/models"

@Component({
    selector: "valben-prom-content-history",
    templateUrl: "./prom-content-history.component.html",
    styles: [`
        b {
            white-space: nowrap;
        }
    `],
})

export class PromContentHistoryComponent {
    @Input() values!: PromotionalContent[]
}

