<div class="border border-black rounded-xl w-full">
    <div class="bg-valamargray w-full p-5 rounded-t-xl">
        <p *ngIf="title" class="text-lg">{{ title }}</p>
        <span class="p-input-icon-right w-full">
            <input
                class="w-full"
                #sInput
                pInputText
                type="text"
                placeholder="Pretraži..."
                (input)="filter(sInput.value)"
            />
            <i class="pi pi-search"></i>
        </span>
    </div>

    <div class="p-2">
        <cdk-virtual-scroll-viewport [itemSize]="50" [minBufferPx]="1000" [maxBufferPx]="1000" class="h-64">
            <ul>
                <li *ngIf="filtering ? filterItems.length === 0 : items.length === 0" class="px-2 py-4">
                    Nema podataka
                </li>
                <li *cdkVirtualFor="let el of (filtering ? filterItems : items)" class="px-2 py-4">
                    {{ el }}
                </li>
            </ul>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
