import { Component, Input } from "@angular/core"
import { OrgUnits } from "src/app/models"

@Component({
    selector: "valben-org-units-renderer",
    templateUrl: "./org-units-renderer.component.html",
})
export class OrgUnitsRendererComponent {
    @Input() orgUnits!: OrgUnits
}
