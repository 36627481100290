import { Geolocation, Location } from ".."

export interface ModifyLocation {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
    geolocation: Geolocation | null
}

export class ModifyLocationFactory {
    static from(location: Location): ModifyLocation {
        const modifyLocation: ModifyLocation = JSON.parse(
            JSON.stringify({
                isDraft: location.isDraft,
                isActive: location.isActive,
                isDeleted: location.isDeleted,
                names: location.names,
                geolocation: location.geolocation,
            })
        )
        return modifyLocation
    }
}

enum ModifyLocationKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    Geolocation = "geolocation",
}

export function ModifyLocationKeysObject() {
    return {
        IsDraft: ModifyLocationKeys.IsDraft,
        IsActive: ModifyLocationKeys.IsActive,
        IsDeleted: ModifyLocationKeys.IsDeleted,
        Names: ModifyLocationKeys.Names,
        Geolocation: ModifyLocationKeys.Geolocation,
    }
}

export function ModifyEmptyLocationErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyLocationKeys.IsDraft]: false,
        [ModifyLocationKeys.IsActive]: false,
        [ModifyLocationKeys.IsDeleted]: false,
        [ModifyLocationKeys.Names]: false,
        [ModifyLocationKeys.Geolocation]: false,
    }
}
