import { AfterViewInit, Component, Input, OnInit, OnDestroy } from "@angular/core"
import { Observable } from "rxjs"
import * as L from "leaflet"

const iconRetinaUrl = "assets/marker-icon-2x.png"
const iconUrl = "assets/marker-icon.png"
const shadowUrl = "assets/marker-shadow.png"
const iconDefault = L.icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
})
L.Marker.prototype.options.icon = iconDefault

@Component({
    selector: "valben-location-input",
    templateUrl: "./location-input.component.html",
    styles: [
        `
            .map-container {
                position: relative;
                min-width: 500px;
                width: 35%;
                height: 500px;
            }

            .map-frame {
                height: 100%;
            }

            .map-component {
                height: 100%;
            }
        `,
    ],
})
export class LocationInputComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    mapId: string = "map"

    private map!: L.Map
    private marker!: L.Marker
    private latlng: { latitude: number | null; longitude: number | null } = { latitude: null, longitude: null }

    private initMap(): void {
        this.map = L.map(this.mapId, {
            center: [45.2033, 13.9114],
            zoom: 7,
        })

        const tiles = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 18,
            minZoom: 8,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        })

        this.map.on("click", (e: L.LeafletMouseEvent) => {
            this.update({ latitude: e.latlng.lat, longitude: e.latlng.lng })
        })

        tiles.addTo(this.map)

        if (this.latlng.latitude !== null && this.latlng.longitude !== null) {
            const ll = L.latLng(this.latlng.latitude, this.latlng.longitude)
            this.setMarker(ll)
        }
    }

    private setMarker(latlng: L.LatLng | null): void {
        if (!this.map) {
            return
        }
        if (this.marker) {
            this.map.removeLayer(this.marker)
        }

        if (latlng === null) {
            return
        }
        this.marker = L.marker(latlng).addTo(this.map)
    }

    private generateGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            const r = (Math.random() * 16) | 0
            const v = c === "x" ? r : (r & 0x3) | 0x8
            return v.toString(16)
        })
    }

    ngOnInit(): void {
        this.mapId = this.generateGuid()
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (value[this.key] === null && this.latlng.latitude !== null && this.latlng.longitude !== null) {
                    this.latlng = { latitude: null, longitude: null }
                    this.setMarker(null)
                } else if (value[this.key] === null) {
                    return
                } else if (
                    ((this.latlng.latitude === null || this.latlng.longitude) && value[this.key] !== null) ||
                    value[this.key].lat !== this.latlng?.latitude ||
                    value[this.key].lng !== this.latlng?.longitude
                ) {
                    this.latlng = value[this.key]
                    if (this.latlng === null) {
                        this.setMarker(null)
                    } else {
                        const ll = L.latLng(this.latlng.latitude!, this.latlng.longitude!)
                        this.setMarker(ll)
                    }
                }
            }
        })
    }

    removeLocation(): void {
        this.update({ latitude: null, longitude: null })
    }

    ngAfterViewInit(): void {
        this.initMap()
    }

    ngOnDestroy(): void {
        this.update(null)
    }
}
