import { Survey } from ".."

export interface ModifySurvey {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    name: string
    description: string
    link: string
}

export class ModifySurveyFactory {
    static from(survey: Survey): ModifySurvey {
        const modifySurvey: ModifySurvey = JSON.parse(
            JSON.stringify({
                isDraft: survey.isDraft,
                isActive: survey.isActive,
                isDeleted: survey.isDeleted,
                name: survey.name,
                description: survey.description,
                link: survey.link,
            })
        )
        return modifySurvey
    }
}

enum ModifySurveyKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Name = "name",
    Description = "description",
    Link = "link",
}

export function ModifySurveyKeysObject() {
    return {
        IsDraft: ModifySurveyKeys.IsDraft,
        IsActive: ModifySurveyKeys.IsActive,
        IsDeleted: ModifySurveyKeys.IsDeleted,
        Name: ModifySurveyKeys.Name,
        Description: ModifySurveyKeys.Description,
        Link: ModifySurveyKeys.Link,
    }
}

export function ModifyEmptySurveyErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifySurveyKeys.IsActive]: false,
        [ModifySurveyKeys.IsDeleted]: false,
        [ModifySurveyKeys.IsDraft]: false,
        [ModifySurveyKeys.Name]: false,
        [ModifySurveyKeys.Description]: false,
        [ModifySurveyKeys.Link]: false,
    }
}
