<div class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
        <div class="text-lg font-semibold my-1">Mjesto troška</div>
        <div class="flex justify-end items-center gap-4">
            <button class="valben-button" (click)="closeOnClick()">X</button>
            <button class="valben-button" (click)="confirmOnClick()">Spremi</button>
        </div>
    </div>
    <div class="flex max-w-[30%] justify-start gap-4">
        <input
            class="w-full h-10 border focus:outline-none rounded-md p-2"
            [(ngModel)]="searchTerm"
            placeholder="Pretraži po nazivu ili mjestu troška"
            (ngModelChange)="onSearch()"
        />
    </div>
    <div class="relative p-4 border-2 border-gray-900/10 rounded-md min-w-[16rem] min-h-[8rem] mb-2">
        <div class="text-base font-semibold mb-3">TIP</div>
        <ul>
            <li *ngFor="let type of types" class="flex items-center">
                <button
                    class="border-2 w-7 h-7 border-valamarblueprimary rounded-md p-1 m-2 flex items-center pi"
                    (click)="typeCheckBoxClicked(type.type)"
                    [ngClass]="{
                        '': type.checked === 'none',
                        'pi-check bg-valamarblueprimary text-white': type.checked === 'all',
                        'pi-minus bg-valamarblueprimary/20 text-valamarblueprimary': type.checked === 'partially'
                    }"
                ></button>
                <button
                    class="border-2 w-7 h-7 border-valamarblueprimary rounded-md p-1 mr-2 pi"
                    (click)="typeFilterClicked(type.type)"
                    [ngClass]="
                        type.filtered
                            ? 'pi-filter-fill bg-valamarblueprimary text-white'
                            : 'pi-filter text-valamarblueprimary'
                    "
                ></button>
                <span>{{ type.type }}</span>
            </li>
        </ul>
    </div>
    <div class="relative p-4 border-2 border-gray-900/10 rounded-md min-w-[16rem] min-h-[8rem]">
        <div class="text-base font-semibold mb-3">Mjesto troška</div>
        <ul class="max-h-80 overflow-y-scroll">
            <li *ngFor="let cc of costCenters" class="flex items-center">
                <ng-container *ngIf="cc.show">
                    <button
                        class="border-2 w-7 h-7 border-valamarblueprimary rounded-md p-1 m-2"
                        (click)="costCenterCheckBoxClicked(cc.masterId)"
                        [ngClass]="cc.checked ? 'pi pi-check bg-valamarblueprimary text-white' : ''"
                    ></button>
                    <span>{{ cc.name }}</span>
                </ng-container>
            </li>
        </ul>
    </div>
</div>
