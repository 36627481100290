import { Pipe, PipeTransform } from "@angular/core"
import { ContextService } from "../services"
import { Positions } from "../models"

@Pipe({
    name: "positionsNames",
    pure: false,
})
export class PositionsNamesPipe implements PipeTransform {
    constructor(private _cs: ContextService) {}

    private _findName(p: Positions, masterId: string): string {
        for (const position of p.jobGroups) {
            if (position.masterId === masterId) {
                return position.names["hr"] || ""
            }
        }
        for (const position of p.workPlaces) {
            if (position.masterId === masterId) {
                return position.names["hr"] || ""
            }
        }
        return ""
    }

    transform(positionsIds: string[]): string[] {
        const names: string[] = []
        const rawPos = this._cs.RawPositions()
        positionsIds.forEach(id => {
            rawPos ? names.push(this._findName(rawPos, id)) : ""
        })
        return names
    }
}
