<div class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
        <div class="flex min-w-[40%] justify-start gap-4">
            <input
                id="search-input"
                class="w-full h-10 border focus:outline-none rounded-md text-md p-2"
                [(ngModel)]="searchValue"
                type="text"
                placeholder="Pretraži..."
                (keyup.enter)="onSearchClick()"
            />
            <button class="valben-button" (click)="onSearchClick()">Pretraži</button>
        </div>
        <div class="flex justify-end items-center gap-4">
            <button class="valben-button" (click)="closeOnClick()">X</button>
            <button class="valben-button" (click)="confirmOnClick()">Spremi</button>
        </div>
    </div>
</div>
<div id="root" #root class="overflow-x-scroll py-4 caret-transparent"></div>
