import { Component, Input } from "@angular/core"

@Component({
    selector: "valben-expand-modal",
    templateUrl: "./expand-modal.component.html",
})
export class ExpandModalComponent {
    @Input() data!: any
    visible: boolean = false
}

