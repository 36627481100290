import { Component, Input, AfterViewInit, OnInit } from "@angular/core"
import { Location } from "src/app/models"
import * as L from "leaflet"

@Component({
    selector: "valben-locations-history",
    templateUrl: "./locations-history.component.html",
    styles: [`
        b {
            white-space: nowrap;
        }
    `],
})

export class LocationsHistoryComponent implements AfterViewInit, OnInit {
    @Input() values: Location[] = []
    langs: string[] = []

    mapId: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0
        const v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })

    ngOnInit(): void {
        this.langs = this.values.map(_ => "hr")
    }

    ngAfterViewInit(): void {
        this.values.forEach((value, i) => {
            if (!value.geolocation.latitude || !value.geolocation.longitude) return

            const ll = L.latLng(value.geolocation.latitude, value.geolocation.longitude)
            const map = L.map(`${this.mapId}-${i}`, { center: ll, zoom: 7 })
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 18,
                minZoom: 8,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            }).addTo(map)
            L.marker(ll).addTo(map)
        })
    }
}

