import { Component, Input } from "@angular/core"
import { Geolocation } from "../../models"
import * as L from "leaflet"

const iconRetinaUrl = "assets/marker-icon-2x.png"
const iconUrl = "assets/marker-icon.png"
const shadowUrl = "assets/marker-shadow.png"
const iconDefault = L.icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
})
L.Marker.prototype.options.icon = iconDefault

@Component({
    selector: "valben-map-modal",
    templateUrl: "./map-modal.component.html",
})
export class MapModalComponent {
    @Input() data!: Geolocation
    visible: boolean = false
    mapId: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0
        const v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
    private map: L.Map | null = null

    showModal() {
        this.visible = true

        if (!this.map && this.data.latitude && this.data.longitude) {
            setTimeout(() => {
                const ll = L.latLng(this.data.latitude!, this.data.longitude!)
                this.map = L.map(this.mapId, { center: ll, zoom: 7 })
                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    maxZoom: 18,
                    minZoom: 8,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                }).addTo(this.map)
                L.marker(ll).addTo(this.map)
            }, 0)
        }
    }
}

