import { Benefit, FileContentFactory, FileContent, Dependencies } from ".."

export interface ModifyBenefit {
    isDraft: boolean
    isActive: boolean
    isDelete: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    categoryIds: string[]
    dependencies: Dependencies
}

export class ModifyBenefitFactory {
    static from(benefit: Benefit): ModifyBenefit {
        const modifyBenefit: ModifyBenefit = JSON.parse(
            JSON.stringify({
                isDraft: benefit.isDraft,
                isActive: benefit.isActive,
                isDeleted: benefit.isDeleted,
                names: benefit.names,
                descriptions: benefit.descriptions,
                image: FileContentFactory.fromUrl(benefit.imageUrl),
                categoryIds: benefit.categoryIds,
                dependencies: benefit.dependencies,
            })
        )
        return modifyBenefit
    }
}

enum ModifyBenefitKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    CategoryIds = "categoryIds",
    Dependencies = "dependencies",
}

export function ModifyBenefitKeysObject() {
    return {
        IsDraft: ModifyBenefitKeys.IsDraft,
        IsActive: ModifyBenefitKeys.IsActive,
        IsDeleted: ModifyBenefitKeys.IsDeleted,
        Names: ModifyBenefitKeys.Names,
        Descriptions: ModifyBenefitKeys.Descriptions,
        Image: ModifyBenefitKeys.Image,
        CategoryIds: ModifyBenefitKeys.CategoryIds,
        Dependencies: ModifyBenefitKeys.Dependencies,
    }
}

export function ModifyEmptyBenefitErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyBenefitKeys.IsActive]: false,
        [ModifyBenefitKeys.IsDeleted]: false,
        [ModifyBenefitKeys.IsDraft]: false,
        [ModifyBenefitKeys.Names]: false,
        [ModifyBenefitKeys.Descriptions]: false,
        [ModifyBenefitKeys.Image]: false,
        [ModifyBenefitKeys.CategoryIds]: false,
        [ModifyBenefitKeys.Dependencies]: false,
    }
}
