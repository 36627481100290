import { Component, OnInit, EventEmitter, Output } from "@angular/core"
import { ContextService } from "src/app/services"
import { CostCenter, CostCenters } from "src/app/models"

interface CostCenterState {
    masterId: string
    name: string
    type: string
    checked: boolean
    show: boolean
}

enum CheckedState {
    All = "all",
    None = "none",
    Partially = "partially",
}

interface Types {
    type: string
    checked: CheckedState
    filtered: boolean
}

@Component({
    selector: "valben-cost-centers",
    templateUrl: "./cost-centers.component.html",
})
export class CostCentersComponent implements OnInit {
    @Output() closeSignal = new EventEmitter<any>()
    @Output() confirmSignal = new EventEmitter<any>()
    types: Types[] = []
    costCenters: CostCenterState[] = []
    searchTerm: string = ""

    constructor(private contextService: ContextService) {}

    ngOnInit(): void {
        this.contextService.CostCentersObservable().subscribe(costCenters => {
            if (costCenters) {
                const currentCostCenters = this.contextService.CurrentCostCenters()
                this.types = this.getDistinctTypes(costCenters).map(type => {
                    return {
                        type,
                        checked: this.getCheckedStateForType(type),
                        filtered: false,
                    }
                })
                const sortedCostCenters = (costCenters || []).sort((a, b) => a.code.localeCompare(b.code))
                this.costCenters = sortedCostCenters.map(cc => ({
                    masterId: cc.masterId,
                    name: `(${cc.code}) - ${cc.names["hr"]}`,
                    type: cc.type,
                    checked: currentCostCenters?.costCenters.includes(cc.masterId) || false,
                    show: true,
                }))
                this.types.forEach(type => {
                    type.checked = this.getCheckedStateForType(type.type)
                })
            }
        })
    }

    getCheckedStateForType(type: string): CheckedState {
        const costCentersOfType = this.costCenters.filter(cc => cc.type === type)

        if (costCentersOfType.every(cc => cc.checked)) {
            return CheckedState.All
        } else if (costCentersOfType.some(cc => cc.checked)) {
            return CheckedState.Partially
        } else {
            return CheckedState.None
        }
    }

    filterCostCentersBySelectedTypes(): void {
        const selectedTypeNames = this.types.filter(t => t.filtered).map(t => t.type)
        this.costCenters.forEach(cc => {
            cc.show = selectedTypeNames.length === 0 || selectedTypeNames.includes(cc.type)
        })
    }

    getDistinctTypes(costcenters: CostCenter[]): string[] {
        if (costcenters) {
            return [...new Set(costcenters.map(cc => cc.type))]
        }
        return []
    }

    onSearch(): void {
        this.costCenters.forEach(cc => {
            cc.show = cc.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        })
    }

    typeCheckBoxClicked(type: string) {
        const selectedType = this.types.find(t => t.type === type)
        if (selectedType) {
            if (selectedType.checked === CheckedState.All) {
                this.costCenters.forEach(cc => {
                    if (cc.type === type) {
                        cc.checked = false
                    }
                })
            } else {
                this.costCenters.forEach(cc => {
                    if (cc.type === type) {
                        cc.checked = true
                    }
                })
            }
            selectedType.checked = this.getCheckedStateForType(type)
        }
    }

    typeFilterClicked(type: string) {
        this.types.forEach(t => {
            if (t.type === type) {
                t.filtered = !t.filtered
            }
        })
        this.filterCostCentersBySelectedTypes()
    }

    costCenterCheckBoxClicked(masterId: string) {
        this.costCenters.forEach(cc => {
            if (cc.masterId === masterId) {
                cc.checked = !cc.checked
                this.types.forEach(type => {
                    if (type.type === cc.type) {
                        type.checked = this.getCheckedStateForType(cc.type)
                    }
                })
            }
        })
    }

    toCostCentersPayload(): CostCenters {
        const types = this.types.filter(t => t.checked !== CheckedState.None).map(t => t.type)
        const costCenters = this.costCenters.filter(cc => cc.checked).map(cc => cc.masterId)

        return {
            costCenters,
            types,
        }
    }

    closeOnClick() {
        this.closeSignal.emit()
    }

    confirmOnClick() {
        this.contextService.UpdateCurrentCostCenters(this.toCostCentersPayload())
        this.confirmSignal.emit()
    }
}
