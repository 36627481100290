export interface CreateCategory {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    parentId: string
    type: string
}

export class CreateCategoryFactory {
    static create(): CreateCategory {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            parentId: "",
            type: "Award",
        }
    }
}

enum CreateCategoryKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    ParentId = "parentId",
    Type = "type",
}

export function CreateCategoryKeysObject() {
    return {
        IsDraft: CreateCategoryKeys.IsDraft,
        IsActive: CreateCategoryKeys.IsActive,
        Names: CreateCategoryKeys.Names,
        ParentId: CreateCategoryKeys.ParentId,
        Type: CreateCategoryKeys.Type,
    }
}

export function CreateEmptyCategoryErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateCategoryKeys.IsActive]: false,
        [CreateCategoryKeys.Names]: false,
        [CreateCategoryKeys.ParentId]: false,
        [CreateCategoryKeys.Type]: false,
    }
}
