import { JsonPatchDocumentElement } from "../models"

export interface ModifyObject {
    jsonPatchDocument: JsonPatchDocumentElement[]
    version: number
}

export class ModifyObjectFactory {
    static create(jsonPatchDocument: JsonPatchDocumentElement[], version: number): ModifyObject {
        return { jsonPatchDocument, version }
    }
}
