export enum PickListSide {
    SOURCE = 0,
    TARGET = 1,
}

export interface PickListItem {
    item: any
    selected: boolean
    filtered: boolean
    originalSide: PickListSide
    currentSide: PickListSide
}

export interface PickListEmitter {
    newInSource: any[]
    newInTarget: any[]
}
