<div class="grid grid-flow-col pick-list-cols gap-5 w-fit">
    <div class="space-y-2 border border-black rounded-xl p-5">
        <p>{{ sourceLabel }}</p>

        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
                #sInput
                pInputText
                type="text"
                placeholder="Pretraži..."
                (input)="filter(sInput.value, 0)"
            />
        </span>

        <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="overflow-y-auto h-64">
            <ul>
                <li
                    *cdkVirtualFor="let el of allItems | pickListRender: 0"
                    (click)="toggleSelected(el)">
                    <ng-container
                        [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                        [ngTemplateOutletContext]="{$implicit: el}">
                        <ng-template #defaultItemTemplate let-item>
                            <div
                                class="capitalize rounded-lg px-2 py-4 cursor-pointer transition-colors duration-100 ease-in-out hover:bg-valamarbluesecondary/10"
                                [ngClass]="{'bg-valamarbluesecondary/20': item.selected}">
                                {{ item.item[filterFields[0]] }}
                            </div>
                        </ng-template>
                    </ng-container>
                </li>
            </ul>
        </cdk-virtual-scroll-viewport>

        <p class="text-center">Označeni: {{ selectedSourceCount | async }}</p>
    </div>

    <div class="inline-flex flex-col gap-4 justify-center">
        <button
            (click)="reset()"
            class="pi pi-undo w-fit h-fit valben-button disabled:border-2 disabled:border-valamarblueprimary">
        </button>
        <button
            [disabled]="(selectedSourceCount | async) === 0"
            (click)="move(0, 1)"
            class="pi pi-angle-right w-fit h-fit valben-button disabled:border-2 disabled:border-valamarblueprimary">
        </button>
        <button
            [disabled]="(selectedTargetCount | async) === 0"
            (click)="move(1, 0)"
            class="pi pi-angle-left w-fit h-fit valben-button disabled:border-2 disabled:border-valamarblueprimary">
        </button>
    </div>

    <div class="space-y-2 border border-black rounded-xl p-5">
        <p>{{ targetLabel }}</p>

        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
                #tInput
                pInputText
                type="text"
                placeholder="Pretraži..."
                (input)="filter(tInput.value, 1)"
            />
        </span>

        <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="overflow-y-auto h-64">
            <ul>
                <li
                    *cdkVirtualFor="let el of allItems | pickListRender: 1"
                    (click)="toggleSelected(el)">
                    <ng-container
                        [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                        [ngTemplateOutletContext]="{$implicit: el}">
                        <ng-template #defaultItemTemplate let-item>
                            <div
                                class="capitalize rounded-lg px-2 py-4 cursor-pointer transition-colors duration-100 ease-in-out hover:bg-valamarbluesecondary/10"
                                [ngClass]="{'bg-valamarbluesecondary/20': item.selected}">
                                {{ item.item[filterFields[0]] }}
                            </div>
                        </ng-template>
                    </ng-container>
                </li>
            </ul>
        </cdk-virtual-scroll-viewport>

        <p class="text-center">Označeni: {{ selectedTargetCount | async }}</p>
    </div>
</div>
