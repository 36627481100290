<div class="flex flex-col px-10 py-4 border-2 rounded-md" [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'">
    <div class="text-lg font-semibold my-1">{{ title }}</div>
    <div class="py-2">
        <div *ngFor="let key of currentValuesKeys" class="flex justify-start items-center my-2">
            <div class="text-md font-semibold m-1 p-1 w-10">{{ key | uppercase }}:</div>
            <div class="w-1/4">
                <input
                    id="{{ key }}"
                    *ngIf="!textArea"
                    class="border w-full focus:outline-none rounded-md p-2"
                    type="text"
                    value="{{ currentValues[key] }}"
                    (keyup)="onChange($event)"
                />
                <textarea
                    id="{{ key }}"
                    *ngIf="!!textArea"
                    class="border w-full focus:outline-none rounded-md p-2"
                    type="text"
                    value="{{ currentValues[key] }}"
                    (keyup)="onChange($event)"
                ></textarea>
            </div>
            <div
                (click)="deleteFromDictionary(key)"
                class="pi pi-times p-1 rounded-md border-valamarblueprimary border-2 cursor-pointer hover:bg-valamarblueprimary hover:text-white mx-4 text-valamarblueprimary"
            ></div>
        </div>
        <div *ngIf="!showNewCountryButton">
            <div
                (click)="newCountryOnClick()"
                class="px-8 py-2 border-2 border-valamarblueprimary rounded-md font-semibold hover:bg-valamarblueprimary hover:text-white cursor-pointer w-fit"
            >
                Novo
            </div>
        </div>
        <div *ngIf="showSelectCountry" class="flex items-center">
            <div class="card flex justify-content-center py-2">
                <valben-countries-dropdown
                    [options]="getCountries()"
                    (signal)="selectCountry($event)"
                ></valben-countries-dropdown>
            </div>
            <div
                (click)="cancelOnClick()"
                class="pi pi-times h-fit border-valamarblueprimary rounded-lg text-valamarblueprimary hover:bg-valamarblueprimary hover:text-white cursor-pointer border-2 p-1 mx-2"
            ></div>
        </div>
        <div *ngIf="showCountryNameInput" class="py-2">
            <input
                *ngIf="!textArea"
                class="border focus:outline-none rounded-md p-2"
                type="text"
                placeholder="{{ getCountryName(selectedCountry) }}"
                [(ngModel)]="inputedValue"
            />
            <textarea
                *ngIf="!!textArea"
                class="border w-1/3 h-36 focus:outline-none rounded-md p-2"
                type="text"
                placeholder="{{ getCountryName(selectedCountry) }}"
                [(ngModel)]="inputedValue">
            </textarea>
            <div
                (click)="pushToDictionary(selectedCountry, inputedValue)"
                class="my-2 px-8 py-2 border-2 border-valamarblueprimary rounded-md font-semibold hover:bg-valamarblueprimary hover:text-white cursor-pointer w-fit"
            >
                Dodaj
            </div>
        </div>
    </div>
</div>
