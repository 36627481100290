<div class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
        <div class="flex min-w-[40%] justify-start gap-4">
            <input
                id="search-input"
                class="w-full h-10 border focus:outline-none rounded-md p-2"
                [(ngModel)]="searchValue"
                type="text"
                placeholder="Pretraži..."
                (keyup.enter)="onSearchClick()"
            />
            <button class="valben-button" (click)="onSearchClick()">Pretraži</button>
            <button class="valben-button pi pi-arrow-left" [disabled]="previousButtonDisabled" (click)="onSearchPreviousClick()"></button>
            <button class="valben-button pi pi-arrow-right" [disabled]="nextButtonDisabled" (click)="onSearchNextClick()"></button>
        </div>
        <div class="flex justify-end items-center gap-4">
            <button class="valben-button" (click)="closeOnClick()">X</button>
            <button class="valben-button" (click)="confirmOnClick()">Spremi</button>
        </div>
    </div>
    <div class="flex justify-start items-center">
        <p-radioButton
            (onClick)="orgUnitsRadioButtonOnClick($event)"
            name="orgUnits"
            value="CS"
            [(ngModel)]="orgUnitsToFilter"
            inputId="cs"
        />
        <label for="cs" class="mx-3">Centralne službe</label>
    </div>
    <div class="flex justify-start items-center">
        <p-radioButton
            (onClick)="orgUnitsRadioButtonOnClick($event)"
            name="orgUnits"
            value="OPS"
            [(ngModel)]="orgUnitsToFilter"
            inputId="ops"
        />
        <label for="ops" class="mx-3">Operacije</label>
    </div>
    <div class="flex justify-start items-center">
        <p-radioButton
            (onClick)="orgUnitsRadioButtonOnClick($event)"
            name="orgUnits"
            value="ALL"
            [(ngModel)]="orgUnitsToFilter"
            inputId="all"
        />
        <label for="all" class="mx-3">Sve</label>
    </div>
</div>
<div id="root" #root class="overflow-x-hidden py-4 caret-transparent"></div>
