<div class="w-screen h-screen flex flex-col">

    <nav class="flex items-center justify-between text-white p-3 bg-valamarblueprimary">
        <span class="text-xl">Benefits</span>
        <span>
            {{ user }}
            <button
                (click)="logout()"
                class="ml-5 valben-button font-semibold py-1 border-valamargray bg-valamargray
                    text-valamarblueprimary [&&]:hover:text-valamargray">
                Odjava
            </button>
        </span>
    </nav>

    <div class="grow w-screen flex flex-nowrap overflow-hidden">
        <ul class="h-full py-2 w-32 bg-valamarblueprimary text-white">
            <li
                *ngFor="let page of pages"
                class="p-2 cursor-pointer rounded-lg border-2 border-transparent transition-colors hover:bg-valamarbluesecondary"
                (click)="navigateTo(page.url)"
                [ngClass]="{ 'bg-valamarbluesecondary': currPage === page.url }">
                {{ page.name }}
            </li>
        </ul>

        <div class="[width:calc(100%-8rem)] flex">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
