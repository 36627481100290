import { Component, Input, OnInit } from "@angular/core"
import { ContextService } from "../../services"
import * as L from "leaflet"

@Component({
    selector: "valben-id-map-modal",
    templateUrl: "./id-map-modal.component.html",
})
export class IdMapModalComponent implements OnInit {
    @Input() data!: string[]
    visible: boolean = false
    mapId: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0
        const v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
    private map!: L.Map
    private lls: L.LatLngExpression[] = []

    constructor(private context: ContextService) {}

    showModal() {
        this.visible = true
        setTimeout(() => {
            this.initMap()
        }, 0)
    }

    private initMap() {
        if (this.map) {
            return
        }

        this.map = L.map(this.mapId, {
            center: [45.2033, 13.9114],
            zoom: 7,
        })
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 18,
            minZoom: 8,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }).addTo(this.map)

        this.setMarkers()
    }

    private setMarkers() {
        if (!this.map) {
            return
        }

        this.lls.forEach(loc => {
            L.marker(loc).addTo(this.map!)
        })

        const bounds = L.latLngBounds(this.lls)
        this.map.fitBounds(bounds, { padding: [5, 5] })
    }

    ngOnInit(): void {
        this.context.getLocationsSubject()?.subscribe(locs => {
            this.lls = []

            this.data.forEach(id => {
                const loc = locs.find(l => l.masterId === id)
                if (loc && loc.geolocation.latitude && loc.geolocation.longitude) {
                    this.lls.push([loc.geolocation.latitude, loc.geolocation.longitude])
                }
            })

            if (this.lls.length > 0) {
                this.setMarkers()
            } else {
                const ref = document.getElementById(this.mapId)
                if (ref) {
                    ref.innerHTML = ""
                    ref.textContent = "Nema lokacija za prikaz ili lokacije nemaju valjane koordinate."
                }
            }
        })
    }
}

