import { Component, Input, OnInit } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"

@Component({
    selector: "valben-multiple-choice-list",
    templateUrl: "./multiple-choice-list.component.html",
})
export class MultipleChoiceListComponent implements OnInit {
    @Input() key: string = ""
    @Input() dataObservable!: () => Observable<any> | undefined
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void

    showOptions: boolean = false
    showNewOptionButton: boolean = true

    selectedOptions: { masterId: string; name: string }[] = []
    options: Map<string, string> = new Map<string, string>()
    selectedOption: { masterId: string; name: string } | undefined = undefined
    optionsFiltered$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

    selectOptionOnClick(): void {
        if (this.selectedOption) {
            const opt = [...this.selectedOptions, this.selectedOption].map(opt => opt.masterId)
            this.update(opt)
        }
    }

    deleteOptionOnClick(index: number): void {
        const opt = this.selectedOptions.filter((_, i) => i !== index).map(opt => opt.masterId)
        this.update(opt)
    }

    newOptionOnClick(): void {
        this.showOptions = !this.showOptions
        this.showNewOptionButton = !this.showNewOptionButton
    }

    cancelOnClick(): void {
        this.showOptions = !this.showOptions
        this.showNewOptionButton = !this.showNewOptionButton
    }

    ngOnInit(): void {
        this.dataObservable()?.subscribe((data: any) => {
            this.options.clear()
            data.map((option: any) => {
                this.options.set(option.masterId, option.names["hr"] || "")
            })
            const selectedOptionsCopy = JSON.parse(JSON.stringify(this.selectedOptions))
            this.selectedOptions = this.selectedOptions.filter((opt: any) => {
                return this.options.has(opt.masterId)
            })
            if (selectedOptionsCopy.length !== this.selectedOptions.length) {
                this.update(this.selectedOptions.map(opt => opt.masterId))
            }
            this.optionsFiltered$.next(
                data
                    .map((option: any) => {
                        return { masterId: option.masterId, name: option.names["hr"] || "" }
                    })
                    .filter((option: any) => {
                        return !this.selectedOptions.map(opt => opt.masterId).includes(option.masterId)
                    })
            )
        })
        this.itemObservable()?.subscribe((item: any) => {
            if (item !== null && item !== undefined) {
                this.selectedOptions = item[this.key].map((opt: string) => {
                    return { masterId: opt, name: this.options.get(opt) || "" }
                })
                const opt: { masterId: string; name: string }[] = []
                for (const [key, value] of this.options) {
                    if (!this.selectedOptions.map(opt => opt.masterId).includes(key)) {
                        opt.push({ masterId: key, name: value })
                    }
                }
                this.optionsFiltered$.next(
                    opt.map((option: any) => {
                        return { masterId: option.masterId, name: option.name }
                    })
                )
            }
        })
    }
}
