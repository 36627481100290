import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "langsDistinct",
    pure: false,
})
export class LangsDistinctPipe implements PipeTransform {
    transform(value: any[], field: string): string[] {
        if (!value) return []
        const langsArr: string[] = []
        value.forEach(item => {
            const langs = Object.keys(item[field])
            langs.forEach(lang => {
                if (!langsArr.includes(lang)) {
                    langsArr.push(lang)
                }
            })
        })
        return langsArr
    }
}
