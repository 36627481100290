<button class="valben-button" (click)="visible = true">Detalji</button>
<p-dialog
    header=""
    [(visible)]="visible"
    [modal]="true"
    styleClass="w-[60vw] h-[60vh]">
    <div class="h-full w-full flex">
        <valben-expand-row class="grow" [value]="data"></valben-expand-row>
    </div>
</p-dialog>

