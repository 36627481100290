import { Component, OnDestroy, OnInit } from "@angular/core"
import { BehaviorSubject, Observable, Subscription } from "rxjs"
import { MessageService } from "primeng/api"
import {
    Column,
    ContentType,
    CreateCategory,
    CreateCategoryKeysObject,
    CreateEmptyCategoryErrorsObject,
    ModifyCategory,
    ModifyCategoryKeysObject,
    ModifyEmptyCategoryErrorsObject,
    setErrors,
} from "../../models"
import { ContextService, LoadingService } from "../../services"

@Component({
    selector: "app-categories",
    templateUrl: "./categories.page.html",
    styles: [
        `
            :host {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        `,
    ],
})
export class CategoriesPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getAddCategorySubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditCategorySubject()?.asObservable()

    updateAddNames: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateAddParentId: (value: any) => void
    updateAddType: (value: any) => void

    updateEditNames: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditParentId: (value: any) => void
    updateEditType: (value: any) => void

    createCategoryKeys = CreateCategoryKeysObject()
    modifyCategoryKeys = ModifyCategoryKeysObject()
    currentAddItem: () => CreateCategory
    currentEditItem: () => ModifyCategory

    parentIdAddDataBH: BehaviorSubject<any> = new BehaviorSubject<any>([])
    parentIdEditDataBH: BehaviorSubject<any> = new BehaviorSubject<any>([])
    parentIdAddDataObservable: () => Observable<any> | undefined = () => this.parentIdAddDataBH.asObservable()
    parentIdEditDataObservable: () => Observable<any> | undefined = () => this.parentIdEditDataBH.asObservable()

    addFormType: string = ""
    editFormType: string = ""
    categoryKey: string = ""

    currentEditedCategory: () => string | undefined = () => this.contextService.currentEditedCategory()

    loading$ = this.loadingService.categoriesLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyCategoryErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyCategoryErrorsObject()
    refresh = () => this.contextService.resetCategories()

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "level", header: "Level", filterType: "numeric", bodyType: "number" },
        { field: "parentId", header: "Parent Id", filterType: "text", bodyType: "string" },
        { field: "type", header: "Tip", filterType: "text", bodyType: "string" },
    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.categoryKey = this.contextService.getCategoriesKey()
        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.createCategoryKeys.Names, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.createCategoryKeys.IsActive, value, this.addFormType)
        }
        this.updateAddParentId = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.createCategoryKeys.ParentId, value, this.addFormType)
        }
        this.updateAddType = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.createCategoryKeys.Type, value, this.addFormType)
            this.parentIdAddDataBH.next(
                this.contextService.getCategoriesSubject()?.value.filter((category: any) => {
                    return category.type.toLowerCase() === value.toLowerCase()
                })
            )
        }
        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.Names, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditParentId = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.ParentId, value, this.editFormType)
        }
        this.updateEditType = (value: any) => {
            this.contextService.setItem(this.categoryKey, this.modifyCategoryKeys.Type, value, this.editFormType)
            this.parentIdEditDataBH.next(
                this.contextService.getCategoriesSubject()?.value.filter((category: any) => {
                    return category.type.toLowerCase() === value.toLowerCase()
                })
            )
        }
        this.currentAddItem = () => this.contextService.getAddCategorySubject()?.value
        this.currentEditItem = () => this.contextService.getEditCategorySubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getCategoriesSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (
                    (x.createdDate = new Date(x.createdDate)),
                    (x.parentId = this.contextService.categoryName(x.parentId))
                )
            })
            this.parentIdAddDataBH.next(
                res.filter((category: any) => category.type.toLowerCase() === this.currentAddItem().type.toLowerCase())
            )
        })
        this.resultSubscription = this.contextService.getCategoriesResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.itemsSubscription?.unsubscribe()
        this.resultSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditCategory(masterId)
        this.parentIdEditDataBH.next(
            this.contextService.getCategoriesSubject()?.value.filter((category: any) => {
                return category.type.toLowerCase() === this.currentEditItem().type.toLowerCase()
            })
        )
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveCategory()
    }

    draft() {
        this.contextService.draftCategory()
    }

    modify() {
        this.contextService.modifyCategory()
    }
}
