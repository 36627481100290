import { Award, FileContent, FileContentFactory, Dependencies } from ".."

export interface ModifyAward {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    amount: number | null
    categoryIds: string[]
    dependencies: Dependencies
}

export class ModifyAwardFactory {
    static from(award: Award): ModifyAward {
        const modifyAward: ModifyAward = JSON.parse(
            JSON.stringify({
                isDraft: award.isDraft,
                isActive: award.isActive,
                isDeleted: award.isDeleted,
                names: award.names,
                descriptions: award.descriptions,
                image: FileContentFactory.fromUrl(award.imageUrl),
                amount: award.amount,
                categoryIds: award.categoryIds,
                dependencies: award.dependencies,
            })
        )
        return modifyAward
    }
}

enum ModifyAwardKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    Amount = "amount",
    CategoryIds = "categoryIds",
    Dependencies = "dependencies",
}

export function ModifyAwardKeysObject() {
    return {
        IsDraft: ModifyAwardKeys.IsDraft,
        IsActive: ModifyAwardKeys.IsActive,
        IsDeleted: ModifyAwardKeys.IsDeleted,
        Names: ModifyAwardKeys.Names,
        Descriptions: ModifyAwardKeys.Descriptions,
        Image: ModifyAwardKeys.Image,
        Amount: ModifyAwardKeys.Amount,
        CategoryIds: ModifyAwardKeys.CategoryIds,
        Dependencies: ModifyAwardKeys.Dependencies,
    }
}

export function ModifyEmptyAwardErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyAwardKeys.IsDraft]: false,
        [ModifyAwardKeys.IsActive]: false,
        [ModifyAwardKeys.IsDeleted]: false,
        [ModifyAwardKeys.Names]: false,
        [ModifyAwardKeys.Descriptions]: false,
        [ModifyAwardKeys.Image]: false,
        [ModifyAwardKeys.Amount]: false,
        [ModifyAwardKeys.CategoryIds]: false,
        [ModifyAwardKeys.Dependencies]: false,
    }
}
