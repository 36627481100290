import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { ContextService } from "src/app/services"

@Component({
    selector: "valben-genders",
    templateUrl: "./genders.component.html",
})
export class GendersComponent implements OnInit {
    @Output() closeSignal = new EventEmitter<any>()
    @Output() confirmSignal = new EventEmitter<any>()

    genders: { label: string; value: string; checked: boolean }[] = [
        { label: "Muško", value: "M", checked: false },
        { label: "Žensko", value: "Z", checked: false },
    ]

    constructor(private contextService: ContextService) {}

    genderCheckboxClicked(value: string) {
        this.genders = this.genders.map(g => {
            if (g.value === value) {
                return { ...g, checked: !g.checked }
            }
            return g
        })
    }

    toGendersPayload(): string[] {
        return this.genders.filter(g => g.checked).map(g => g.value)
    }

    closeOnClick() {
        this.closeSignal.emit()
    }

    confirmOnClick() {
        this.contextService.UpdateCurrentGenders(this.toGendersPayload())
        this.confirmSignal.emit()
    }

    ngOnInit(): void {
        const currentGenders = this.contextService.CurrentGenders()
        this.genders = this.genders.map(g => {
            return {
                ...g,
                checked: currentGenders?.includes(g.value) || false,
            }
        })
    }
}
