import { Dependencies, DependenciesFactory, FileContent, FileContentFactory } from ".."

export interface CreatePerk {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    contact: {
        email: string
        phoneNumber: string
    }
    link: string
    categoryIds: string[]
    locationIds: string[]
    dependencies: Dependencies
}

export class CreatePerkFactory {
    static create(): CreatePerk {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            descriptions: {
                hr: "",
                en: "",
            },
            image: FileContentFactory.create(),
            contact: {
                email: "",
                phoneNumber: "",
            },
            link: "",
            categoryIds: [],
            locationIds: [],
            dependencies: DependenciesFactory.create(),
        }
    }
}

enum CreatePerkKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    Contact = "contact",
    Link = "link",
    CategoryIds = "categoryIds",
    LocationIds = "locationIds",
    Dependencies = "dependencies",
}

export function CreatePerkKeysObject() {
    return {
        IsDraft: CreatePerkKeys.IsDraft,
        IsActive: CreatePerkKeys.IsActive,
        Names: CreatePerkKeys.Names,
        Descriptions: CreatePerkKeys.Descriptions,
        Image: CreatePerkKeys.Image,
        Contact: CreatePerkKeys.Contact,
        Link: CreatePerkKeys.Link,
        CategoryIds: CreatePerkKeys.CategoryIds,
        LocationIds: CreatePerkKeys.LocationIds,
        Dependencies: CreatePerkKeys.Dependencies,
    }
}

export function CreateEmptyPerkErrorsObject(): { [key: string]: boolean } {
    return {
        [CreatePerkKeys.IsActive]: false,
        [CreatePerkKeys.Names]: false,
        [CreatePerkKeys.Descriptions]: false,
        [CreatePerkKeys.Image]: false,
        [CreatePerkKeys.Contact]: false,
        [CreatePerkKeys.Link]: false,
        [CreatePerkKeys.CategoryIds]: false,
        [CreatePerkKeys.LocationIds]: false,
        [CreatePerkKeys.Dependencies]: false,
    }
}
