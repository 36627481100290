import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { EmployeeContracts } from "src/app/models"
import { ContextService } from "src/app/services"

interface EmployeeContractsState {
    masterId: string
    names: { [key: string]: string }
    children?: EmployeeSubTypeState[]
    checked: CheckedState
}

enum CheckedState {
    Yes = "Yes",
    No = "No",
    Partial = "Partial",
}

interface EmployeeSubTypeState {
    masterId: string
    names: { [key: string]: string }
    parentId: string
    checked: CheckedState
}

@Component({
    selector: "valben-employee-contracts",
    templateUrl: "./employee-contracts.component.html",
})
export class EmployeeContractsComponent implements OnInit {
    @Output() closeSignal = new EventEmitter<any>()
    @Output() confirmSignal = new EventEmitter<any>()
    employeeContracts: EmployeeContractsState[] = []

    constructor(private contextService: ContextService) {}

    ngOnInit(): void {
        this.contextService.EmployeeContractsObservable().subscribe(employeeContracts => {
            if (employeeContracts) {
                const currentEmployeeSubTypes = this.contextService.CurrentEmployeeContracts()

                this.employeeContracts = employeeContracts.map(ec => {
                    const item: EmployeeContractsState = {
                        masterId: ec.masterId,
                        names: ec.names,
                        children: ec.children
                            ? ec.children.map(child => ({
                                  masterId: child.masterId,
                                  names: child.names,
                                  parentId: ec.masterId,
                                  checked: currentEmployeeSubTypes?.subTypes.includes(child.masterId)
                                      ? CheckedState.Yes
                                      : CheckedState.No,
                              }))
                            : undefined,
                        checked: CheckedState.No,
                    }
                    item.checked = item.children ? this.getCheckedStateForParent(item.children) : CheckedState.No
                    return item
                })
            }
        })
    }

    private getCheckedStateForParent(children: EmployeeContractsState[]): CheckedState {
        if (children.length === 0) {
            return CheckedState.No
        }
        if (children.every(child => child.checked === CheckedState.Yes)) {
            return CheckedState.Yes
        }
        if (children.some(child => child.checked !== CheckedState.No)) {
            return CheckedState.Partial
        }
        return CheckedState.No
    }

    private toEmployeeContractsPayload(): EmployeeContracts {
        const employeeContracts: EmployeeContracts = {
            types: [],
            subTypes: [],
        }
        this.employeeContracts.forEach(ec => {
            if (ec.checked === CheckedState.Yes || ec.checked === CheckedState.Partial) {
                employeeContracts.types.push(ec.masterId)
            }
            ec.children?.forEach(child => {
                if (child.checked === CheckedState.Yes) {
                    employeeContracts.subTypes.push(child.masterId)
                }
            })
        })
        return employeeContracts
    }

    parentCheckBoxClicked(masterId: string) {
        const parent = this.employeeContracts.find(ec => ec.masterId === masterId)
        if (parent) {
            if (parent.checked === CheckedState.Yes) {
                parent.checked = CheckedState.No
                parent.children?.forEach(child => (child.checked = CheckedState.No))
            } else {
                parent.checked = CheckedState.Yes
                parent.children?.forEach(child => (child.checked = CheckedState.Yes))
            }
        }
    }

    childCheckBoxClicked(parentId: string, childId: string) {
        const parent = this.employeeContracts.find(ec => ec.masterId === parentId)
        const child = parent?.children?.find(c => c.masterId === childId)
        if (child) {
            child.checked = child.checked === CheckedState.No ? CheckedState.Yes : CheckedState.No
            if (parent) {
                parent.checked = this.getCheckedStateForParent(parent.children || [])
            }
        }
    }

    closeOnClick() {
        this.closeSignal.emit()
    }

    confirmOnClick() {
        this.contextService.UpdateCurrentEmployeeContracts(this.toEmployeeContractsPayload())
        this.confirmSignal.emit()
    }
}
