import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"
import {
    ModifyObject,
    CreatePromotionalContent,
    PromotionalContent,
} from "../models"

@Injectable({
    providedIn: "root",
})
export class PromotionalContentService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.promotionalContent

    constructor(private http: HttpClient) {}

    Get(): Observable<PromotionalContent[]> {
        return this.http.get<PromotionalContent[]>(this.apiUrl)
    }

    Create(createPromContent: CreatePromotionalContent): Observable<PromotionalContent> {
        return this.http.post<PromotionalContent>(this.apiUrl, createPromContent)
    }

    Modify(masterId: string, data: ModifyObject): Observable<PromotionalContent> {
        return this.http.patch<PromotionalContent>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<string> {
        return this.http.delete<string>(this.apiUrl + `/${masterId}`)
    }
}
