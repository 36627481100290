<div class="flex items-center max-w-full h-28">
    <div
        class="bg-valamarbluesecondary/20 min-h-[7rem] p-2 min-w-[34rem] w-fit max-w-full my-2 overflow-auto border-black rounded-l-md flex items-center"
    >
        <div class="flex gap-2">
            <div *ngFor="let option of selectedOptions; let i = index">
                <div class="flex justify-between gap-6 items-center m-1 py-2 px-4 bg-white rounded-sm">
                    <div class="flex flex-col w-max">
                        <div class="text-lg">{{ option.name }}</div>
                    </div>
                    <div
                        (click)="deleteOptionOnClick(i)"
                        class="pi pi-times border-valamarblueprimary rounded-lg text-valamarblueprimary hover:bg-valamarblueprimary hover:text-white cursor-pointer border-2 p-1"
                    ></div>
                </div>
            </div>
        </div>
    </div>
    <div class="h-full">
        <div class="px-3 w-fit bg-valamarbluesecondary/20 flex h-full justify-center items-center rounded-r-md">
            <div
                (click)="newOptionOnClick()"
                class="px-8 py-2 border-2 border-valamarblueprimary bg-white rounded-md font-semibold hover:bg-valamarblueprimary hover:text-white cursor-pointer"
                [ngClass]="
                    showNewOptionButton && ((optionsFiltered$ | async) || []).length > 0 ? '' : 'pointer-events-none select-none bg-white/0 text-white/0 border-white/0'
                "
            >
                NOVO
            </div>
        </div>
    </div>
</div>
<div *ngIf="showOptions" class="my-2">
    <p-dropdown
        (onChange)="selectOptionOnClick()"
        [editable]="false"
        [autoDisplayFirst]="false"
        [options]="(optionsFiltered$ | async) || []"
        [filter]="true"
        styleClass="min-w-[34rem] w-fit max-w-full"
        panelStyleClass="min-w-[34rem] w-fit max-w-full"
        [(ngModel)]="selectedOption"
        optionLabel="name"
        [virtualScroll]="true"
        [virtualScrollItemSize]="63"
    >
        <ng-template let-item pTemplate="item">
            <div>
                <div class="flex items-center h-10">
                    <div class="flex flex-col w-max">
                        <div class="text-lg">{{ item.name }}</div>
                        {{ item.company }}
                    </div>
                </div>
            </div>
        </ng-template></p-dropdown
    >
    <div
        (click)="cancelOnClick()"
        class="pi pi-times border-valamarblueprimary rounded-lg text-valamarblueprimary hover:bg-valamarblueprimary hover:text-white cursor-pointer border-2 p-1 mx-2"
    ></div>
</div>
