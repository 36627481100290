export class JsonPatchDocumentGenerator {
    private patchDocument: any[] = []

    public getJsonPatchDocument(oldValue: any, newValue: any, ignoreFields: string[]): any[] {
        this.patchDocument = []
        if (oldValue === undefined || newValue === undefined) {
            return []
        }
        const oldVal = JSON.parse(JSON.stringify(oldValue))
        const newVal = JSON.parse(JSON.stringify(newValue))
        const keysOldVal = Object.keys(oldVal)
        const keysNewVal = Object.keys(newVal)
        for (const key of ignoreFields) {
            if (keysOldVal.includes(key)) {
                delete oldVal[key]
            }
            if (keysNewVal.includes(key)) {
                delete newVal[key]
            }
        }
        this.compareObjects(oldVal, newVal, "")
        return this.patchDocument
    }

    private serielizeObjectAndAdd(object: any, path: string) {
        if (typeof object === "object") {
            if (Array.isArray(object)) {
                for (let i = 0; i < object.length; i++) {
                    this.serielizeObjectAndAdd(object[i], `${path}/${i}`)
                }
            } else {
                for (const key of Object.keys(object)) {
                    this.serielizeObjectAndAdd(object[key], `${path}/${key}`)
                }
            }
        } else {
            this.patchDocument.push({ op: "add", path, value: object })
        }
    }

    private serielizeObjectAndRemove(object: any, path: string) {
        if (object === null) {
            this.patchDocument.push({ op: "remove", path })
        } else if (typeof object === "object") {
            if (Array.isArray(object)) {
                for (let i = 0; i < object.length; i++) {
                    this.serielizeObjectAndRemove(object[i], `${path}/${i}`)
                }
            } else {
                for (const key of Object.keys(object)) {
                    this.serielizeObjectAndRemove(object[key], `${path}/${key}`)
                }
            }
        } else {
            this.patchDocument.push({ op: "remove", path })
        }
    }

    private compareObjects(oldVal: any, newVal: any, path: string) {
        if (typeof oldVal === "object" && typeof newVal === "object") {
            if (Array.isArray(oldVal) && Array.isArray(newVal)) {
                for (let i = 0; i < Math.max(oldVal.length, newVal.length); i++) {
                    if (oldVal[i] !== undefined && newVal[i] !== undefined && oldVal[i] !== newVal[i]) {
                        this.compareObjects(oldVal[i], newVal[i], `${path}/${i}`)
                    } else if (oldVal[i] === undefined && newVal[i] !== undefined) {
                        this.patchDocument.push({ op: "add", path: `${path}/${i}`, value: newVal[i] })
                    } else if (oldVal[i] !== undefined && newVal[i] === undefined) {
                        this.patchDocument.push({ op: "remove", path: `${path}/${i}` })
                    }
                }
            } else if (Array.isArray(oldVal)) {
                console.log("error")
            } else if (Array.isArray(newVal)) {
                console.log("error")
            } else {
                if (oldVal === null && newVal === null) {
                    return
                } else if (oldVal === null && newVal !== null) {
                    if (typeof newVal === "object") {
                        this.patchDocument.push({ op: "add", path, value: newVal })
                    } else {
                        this.patchDocument.push({ op: "replace", path, value: newVal })
                    }
                    return
                } else if (oldVal !== null && newVal === null) {
                    this.patchDocument.push({ op: "replace", path, value: null })
                    return
                } else {
                    for (const key of Object.keys(oldVal)) {
                        if (!newVal.hasOwnProperty(key)) {
                            this.serielizeObjectAndRemove(oldVal[key], `${path}/${key}`)
                        } else {
                            this.compareObjects(oldVal[key], newVal[key], `${path}/${key}`)
                        }
                    }
                    for (const key of Object.keys(newVal)) {
                        if (!oldVal.hasOwnProperty(key)) {
                            this.serielizeObjectAndAdd(newVal[key], `${path}/${key}`)
                        }
                    }
                }
            }
        } else if (oldVal !== newVal) {
            this.patchDocument.push({ op: "replace", path, value: newVal })
        }
    }
}
