import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-date-time-picker",
    templateUrl: "./date-time-picker.component.html",
})
export class DateTimePickerComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() optional: boolean = true
    @Input() error: boolean = false

    date: Date | undefined = undefined

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null && value[this.key] !== (this.date?.toISOString() || "")) {
                this.date = value[this.key] ? new Date(value[this.key]) : undefined
            }
            if (this.date === null) {
                this.date = undefined
            }
        })
    }

    onSelect(): void {
        if ((this.date?.toISOString() || "") === "") {
            const date = new Date()
            this.update(date.toISOString())
            return
        }
        this.update(this.date?.toISOString() || "")
    }

    onClear(): void {
        this.update("")
    }
}
