import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, take } from "rxjs"
import { environment } from "src/environments/environment"
import { Dependencies, EmployeeFilterResult, EmployeeIncludedName } from "../models"

@Injectable({
    providedIn: "root",
})
export class EmployeeService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.employee

    private _filteredEmployees$: BehaviorSubject<EmployeeFilterResult> = new BehaviorSubject<EmployeeFilterResult>({
        included: null,
        excluded: null,
    })

    constructor(private _http: HttpClient) {}

    FilteredEmployees(): BehaviorSubject<EmployeeFilterResult> {
        return this._filteredEmployees$
    }

    ResetFilteredEmployees(): void {
        this._filteredEmployees$.next({ included: null, excluded: null })
    }

    GetFilterResult(depends: Dependencies): void {
        this.GetIncludedEmployeeResult(depends).subscribe(res => {
            this._filteredEmployees$.next(res)
        })
    }

    GetIncludedEmployeeResult(depends: Dependencies): Observable<EmployeeFilterResult> {
        return this._http.post<EmployeeFilterResult>(this.apiUrl + "/included", depends)
    }

    GetEmployeesNames(included: string[], excluded: string[]): Observable<EmployeeIncludedName[]> {
        return this._http.get<EmployeeIncludedName[]>(this.apiUrl + "/names", {
            params: {
                included: included.join(","),
                excluded: excluded.join(","),
            }
        }).pipe(take(1))
    }
}
