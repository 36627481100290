import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { environment } from "src/environments/environment"
import { Dependencies, EmployeeFilterResult } from "../models"

@Injectable({
    providedIn: "root",
})
export class EmployeeService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.employee

    private filteredEmployees: BehaviorSubject<EmployeeFilterResult> = new BehaviorSubject<EmployeeFilterResult>({
        included: null,
        excluded: null,
    })

    constructor(private http: HttpClient) {}

    FilteredEmployees(): BehaviorSubject<EmployeeFilterResult> {
        return this.filteredEmployees
    }

    ResetFilteredEmployees(): void {
        this.filteredEmployees.next({ included: null, excluded: null })
    }

    GetFilterResult(depends: Dependencies): void {
        this.http.post<EmployeeFilterResult>(this.apiUrl, depends).subscribe(res => {
            this.filteredEmployees.next(res)
        })
    }
}
