import { Component, ElementRef, Input, ViewChild, OnInit } from "@angular/core"
import { MessageService } from "primeng/api"
import { Observable } from "rxjs"
import { FileContent, FileContentFactory } from "../../models"

@Component({
    selector: "valben-file-input",
    templateUrl: "./file-input.component.html",
})
export class FileInputComponent implements OnInit {
    @Input() key!: string
    @Input() update!: (value: any) => void
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() fileType: string = ""
    @Input() error: boolean = false
    @Input() title: string = ""
    selectedFile: FileContent = FileContentFactory.create()
    hovering: boolean = false

    @ViewChild("browseFile") browseFile!: ElementRef

    constructor(private messageService: MessageService) {}

    renderFileType(): number {
        let res = 0
        if (this.selectedFile.contentType.includes("image")) {
            res = 1
        } else if (this.selectedFile.contentType.includes("video")) {
            res = 2
        }
        return res
    }

    uploadDrop(e: DragEvent) {
        e.preventDefault()
        if (this.selectedFile.url) {
            return
        }
        const file = e.dataTransfer?.files[0]
        if (file) {
            this._readFile(file)
        }
    }

    uploadBrowse() {
        if (this.selectedFile.url || this.selectedFile.fileName) {
            return
        }
        this.browseFile?.nativeElement.click()
    }

    uploadBrowseChange(e: any) {
        const file = e.target.files[0]
        if (file) {
            this._readFile(file)
        }
    }

    urlChange() {
        this.update(this.selectedFile)
    }

    removeSelectedFile(e: Event) {
        e.stopPropagation()
        if (this.browseFile) {
            this.browseFile.nativeElement.value = null
        }
        this.update(FileContentFactory.create())
    }

    private _readFile(file: File) {
        const matchingType = this.fileType.includes("/*")
            ? this.fileType.replaceAll("/*", "").split(",").some(t => file.type.includes(t))
            : this.fileType.includes(file.type)

        if (!matchingType) {
            this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Priložena datoteka nije podržanog formata.",
            })
            return
        }

        const fileReader = new FileReader()
        fileReader.onload = () => {
            this.update({
                url: "",
                base64: fileReader.result as string,
                fileName: file.name,
                contentType: file.type,
            })
        }
        fileReader.readAsDataURL(file)
    }

    ngOnInit(): void {
        this.itemObservable()?.subscribe(value => {
            if (value !== null && value[this.key] !== this.selectedFile) {
                this.selectedFile = value[this.key]
            }
        })
    }
}
