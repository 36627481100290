<div class="flex flex-col px-10 py-4 border-2 rounded-md" [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'">
    <div class="text-lg font-semibold my-1">{{ title }}</div>

    <ng-container *ngIf="state !== 'employees'">
        <button class="valben-button w-fit my-4" (click)="addFilterGroup()">Dodaj grupu filtera</button>
        <div class="flex justify-start items-start gap-2 my-4 overflow-x-scroll">
            <div *ngFor="let f of dependenciesState.filterGroups; index as i">
                <div
                    class="flex flex-col space-y-2 relative p-4 border-2 border-gray-900/10 rounded-md min-w-[16rem] pb-[4rem] min-h-[8rem]"
                >
                    <div *ngIf="f.orgUnits !== null" class="flex w-full">
                        <valben-org-units-renderer [orgUnits]="f.orgUnits"></valben-org-units-renderer>
                        <div class="flex w-full justify-end items-center ml-4">
                            <div class="flex gap-2">
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="editOrgUnitsOnClick(i)"
                                >
                                    Uredi
                                </button>
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="removeOrgUnitsOnClick(i)"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="f.positions !== null" class="flex w-full">
                        <valben-positions-renderer [positions]="f.positions"></valben-positions-renderer>
                        <div class="flex w-full justify-end items-center ml-4">
                            <div class="flex gap-2">
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="editPositionsOnClick(i)"
                                >
                                    Uredi
                                </button>
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="removePositionsOnClick(i)"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="f.costCenters !== null" class="flex w-full">
                        <valben-cost-centers-renderer [costCenters]="f.costCenters"></valben-cost-centers-renderer>
                        <div class="flex w-full justify-end items-center ml-4">
                            <div class="flex gap-2">
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="editCostCentersOnClick(i)"
                                >
                                    Uredi
                                </button>
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="removeCostCentersOnClick(i)"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="f.contracts !== null" class="flex w-full">
                        <valben-employee-contracts-renderer
                            [employeeContracts]="f.contracts"
                            [allEmployeeContracts]="allEmployeeContracts"
                        ></valben-employee-contracts-renderer>
                        <div class="flex w-full justify-end items-center ml-4">
                            <div class="flex gap-2">
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="editEmployeeContractsOnClick(i)"
                                >
                                    Uredi
                                </button>
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="removeEmployeeContractsOnClick(i)"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="f.genders !== null" class="flex w-full">
                        <valben-genders-renderer [genders]="f.genders"></valben-genders-renderer>
                        <div class="flex w-full justify-end items-center ml-4">
                            <div class="flex gap-2">
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="editGendersOnClick(i)"
                                >
                                    Uredi
                                </button>
                                <button
                                    class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                                    [disabled]="state !== 'none'"
                                    (click)="removeGendersOnClick(i)"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="absolute p-2 w-full flex justify-between gap-2 items-center bottom-0 right-0">
                        <button
                            class="valben-button w-full disabled:border-2 disabled:border-valamarblueprimary"
                            [disabled]="state !== 'none'"
                            (click)="addFilter(i)"
                        >
                            Dodaj filter
                        </button>
                        <button
                            class="valben-button disabled:border-2 disabled:border-valamarblueprimary"
                            [disabled]="state !== 'none'"
                            (click)="removeFilterGroup(i)"
                        >
                            X
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="state === 'add'">
            <div class="flex justify-start items-center gap-2">
                <p-dropdown
                    [autoDisplayFirst]="false"
                    [options]="states"
                    optionLabel="label"
                    (onChange)="selectStateOnChange($event)"
                >
                </p-dropdown>
                <button class="valben-button" (click)="closeOnClick()">X</button>
            </div>
        </div>
        <valben-org-units
            *ngIf="state === 'orgunits'"
            (confirmSignal)="confirmOrgUnitsOnClick()"
            (closeSignal)="closeOnClick()"
        ></valben-org-units>
        <valben-positions
            *ngIf="state === 'positions'"
            (confirmSignal)="confirmPositionsOnClick()"
            (closeSignal)="closeOnClick()"
        ></valben-positions>
        <valben-cost-centers
            *ngIf="state === 'costcenters'"
            (confirmSignal)="confirmCostCentersOnClick()"
            (closeSignal)="closeOnClick()"
        ></valben-cost-centers>
        <valben-employee-contracts
            *ngIf="state === 'employeecontracts'"
            (confirmSignal)="confirmEmployeeContractsOnClick()"
            (closeSignal)="closeOnClick()"
        ></valben-employee-contracts>
        <valben-genders
            *ngIf="state === 'genders'"
            (confirmSignal)="confirmGendersOnClick()"
            (closeSignal)="closeOnClick()"
        ></valben-genders>

        <button
            (click)="filteringDone()"
            class="mt-4 valben-button disabled:border-2 disabled:border-valamarblueprimary w-fit"
            [disabled]="state !== 'none'"
        >
            Filtriraj
        </button>
    </ng-container>

    <ng-container *ngIf="state === 'employees'">
        <valben-pick-list
            (moveSignal)="addFilteredEmployee()"
            [allItems]="filteredEmployees"
            sourceLabel="Uključeni"
            targetLabel="Isključeni"
            [filterFields]="['mbradWithName']"
        >
        </valben-pick-list>

        <p class="my-4">Ukupno odabrani: {{ filteredIncludedEmployeesCount }}</p>

        <div class="flex mt-4 gap-4">
            <button (click)="stateToNone()" class="valben-button w-fit">Natrag</button>
            <button (click)="exportExcel()" class="valben-button w-fit">Export</button>
        </div>
    </ng-container>
</div>
