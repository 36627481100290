import {
    IContextState,
    createAwardsContextState,
    createCategoryContextState,
    createBenefitContextState,
    createPerksContextState,
    createSurveysContextState,
    createNewsContextState,
    createPromContentContextState,
} from ".."
import { createLocationsContextState } from "./contextState.model"

export const CATEGORIES_KEY = "categories"
export const AWARDS_KEY = "awards"
export const BENEFITS_KEY = "benefits"
export const PERKS_KEY = "perks"
export const SURVEYS_KEY = "surveys"
export const NEWS_KEY = "news"
export const PROM_CONTENT_KEY = "promContent"
export const LOCATIONS_KEY = "locations"

export interface IContext {
    states: Map<string, IContextState>
    categories: () => IContextState
    awards: () => IContextState
    benefits: () => IContextState
    perks: () => IContextState
    surveys: () => IContextState
    news: () => IContextState
    promContent: () => IContextState
    locations: () => IContextState
    get: (key: string) => IContextState | undefined
}

export function createContext(): IContext {
    const categoriesState = createCategoryContextState(CATEGORIES_KEY)
    const awardsState = createAwardsContextState(AWARDS_KEY)
    const benefitsState = createBenefitContextState(BENEFITS_KEY)
    const perksState = createPerksContextState(PERKS_KEY)
    const surveysState = createSurveysContextState(SURVEYS_KEY)
    const newsState = createNewsContextState(NEWS_KEY)
    const promContentState = createPromContentContextState(PROM_CONTENT_KEY)
    const locationsState = createLocationsContextState(LOCATIONS_KEY)
    const states: Map<string, IContextState> = new Map<string, IContextState>()
    states.set(CATEGORIES_KEY, categoriesState)
    states.set(AWARDS_KEY, awardsState)
    states.set(BENEFITS_KEY, benefitsState)
    states.set(PERKS_KEY, perksState)
    states.set(SURVEYS_KEY, surveysState)
    states.set(NEWS_KEY, newsState)
    states.set(PROM_CONTENT_KEY, promContentState)
    states.set(LOCATIONS_KEY, locationsState)

    const categories = () => {
        return states.get(CATEGORIES_KEY)!
    }

    const awards = () => {
        return states.get(AWARDS_KEY)!
    }

    const benefits = () => {
        return states.get(BENEFITS_KEY)!
    }

    const perks = () => {
        return states.get(PERKS_KEY)!
    }

    const surveys = () => {
        return states.get(SURVEYS_KEY)!
    }

    const news = () => {
        return states.get(NEWS_KEY)!
    }

    const promContent = () => {
        return states.get(PROM_CONTENT_KEY)!
    }

    const locations = () => {
        return states.get(LOCATIONS_KEY)!
    }

    const get = (key: string) => {
        return states.get(key)
    }

    return {
        states,
        categories,
        awards,
        benefits,
        perks,
        surveys,
        news,
        promContent,
        locations,
        get,
    }
}
