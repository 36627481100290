import { Pipe, PipeTransform } from "@angular/core"
import { ContextService } from "../services"
import { EmployeeContracts } from "../models"

@Pipe({
    name: "contractNames",
    pure: false,
})
export class ContractNamesPipe implements PipeTransform {
    constructor(private _cs: ContextService) {}

    transform(contract: EmployeeContracts): string[] {
        const subTypes = this._cs.EmployeeContracts().flatMap(t => t.children || [])
        const typeNames = contract.types.map(id => (
            this._cs.EmployeeContracts().find(c => c.masterId === id)?.names["hr"] || `Nepoznato (${id})`
        ))
        const subTypeNames = contract.subTypes.map(id => (
            subTypes.find(c => c.masterId === id)?.names["hr"] || `Nepoznato (${id})`
        ))
        const a = [...typeNames, ...subTypeNames]
        return a
    }
}
