import { Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { PickListItem, PickListSide } from "../../models"

@Component({
    selector: "valben-pick-list",
    templateUrl: "./pick-list.component.html",
    styles: [
        `
            .pick-list-cols {
                grid-template-columns: 1fr auto 1fr;
            }
        `,
    ],
})
export class PickListComponent {
    @Input() allItems: PickListItem[] = []
    @Input() sourceLabel: string = ""
    @Input() targetLabel: string = ""
    @Input() filterFields: string[] = []
    @Input() itemTemplate: TemplateRef<any> | undefined
    @Input() itemSize: number = 50
    @Output() moveSignal = new EventEmitter<null>()
    selectedSourceCount: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    selectedTargetCount: BehaviorSubject<number> = new BehaviorSubject<number>(0)

    filter(val: string, side: PickListSide) {
        if (!val) {
            this.allItems.forEach(el => {
                if (el.currentSide === side) {
                    el.filtered = true
                }
            })
        } else {
            this.allItems.forEach(el => {
                if (el.currentSide === side) {
                    el.filtered = this.filterFields.some(field => {
                        const value = String(el.item[field])
                        return value && value.toLowerCase().includes(val.toLowerCase())
                    })
                }
            })
        }
    }

    toggleSelected(el: PickListItem) {
        el.selected = !el.selected

        if (el.currentSide === PickListSide.SOURCE) {
            el.selected
                ? this.selectedSourceCount.next(this.selectedSourceCount.value + 1)
                : this.selectedSourceCount.next(this.selectedSourceCount.value - 1)
        } else {
            el.selected
                ? this.selectedTargetCount.next(this.selectedTargetCount.value + 1)
                : this.selectedTargetCount.next(this.selectedTargetCount.value - 1)
        }
    }

    move(fromSide: PickListSide, toSide: PickListSide) {
        let moved = false

        this.allItems.forEach(el => {
            if (el.selected && el.currentSide === fromSide) {
                el.currentSide = toSide
                el.selected = false
                moved = true
            }
        })

        if (moved && fromSide === PickListSide.SOURCE) {
            this.selectedSourceCount.next(0)
        }

        if (moved && fromSide === PickListSide.TARGET) {
            this.selectedTargetCount.next(0)
        }
        this.moveSignal.emit(null)
    }

    reset() {
        this.allItems.forEach(el => {
            el.selected = false
            el.filtered = true
            if (el.currentSide !== el.originalSide) {
                el.currentSide = el.originalSide
            }
        })
        this.selectedSourceCount.next(0)
        this.selectedTargetCount.next(0)
        this.moveSignal.emit(null)
    }
}
