import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { environment } from "src/environments/environment"
import {
    Checked,
    CostCenter,
    CostCenters,
    FolderStructureNode,
    Node,
    OpsCsSelected,
    OrgUnit,
    OrgUnitType,
    OrgUnits,
    PositionsNode,
    PositionsPayload,
    PositionsRepresentation,
    PositionsRepresentationChange,
    OrgUnitsRepresentation,
    OrgUnitsRepresentationChange,
    fromNode,
    fromPositions,
    EmployeeType,
    EmployeeContracts,
    EdpData,
} from "../models"
import { Positions } from "../models/dependencies/positions.model"

@Injectable({
    providedIn: "root",
})
export class DependenciesService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.dependencies

    private orgUnitTypes: BehaviorSubject<OrgUnitType[]> = new BehaviorSubject<OrgUnitType[]>([])
    private positions: BehaviorSubject<Positions | undefined> = new BehaviorSubject<Positions | undefined>(undefined)
    private orgUnitTree: BehaviorSubject<Node | undefined> = new BehaviorSubject<Node | undefined>(undefined)
    private costCenters: BehaviorSubject<CostCenter[]> = new BehaviorSubject<CostCenter[]>([])
    private employeeContracts: BehaviorSubject<EmployeeType[]> = new BehaviorSubject<EmployeeType[]>([])
    private currentOrgUnitTree: BehaviorSubject<FolderStructureNode | undefined> = new BehaviorSubject<
        FolderStructureNode | undefined
    >(undefined)
    private currentPositionsTree: BehaviorSubject<PositionsNode | undefined> = new BehaviorSubject<
        PositionsNode | undefined
    >(undefined)
    private currentSelectedCostCenters: CostCenters
    private currentOrgUnitTreeRepresentation: OrgUnitsRepresentation
    private currentPositionsTreeRepresentation: PositionsRepresentation
    private currentSelectedEmployeeContracts: EmployeeContracts
    private currentSelectedGenders: string[]

    constructor(private http: HttpClient) {
        this.getEdpData().subscribe(data => {
            if (data.orgUnits === null) {
                this.orgUnitTree.next(undefined)
                this.currentOrgUnitTree.next(undefined)
            } else {
                this.orgUnitTree.next(JSON.parse(JSON.stringify(data.orgUnits)))
                this.currentOrgUnitTree.next(fromNode(data.orgUnits, 0))
            }

            if (data.orgUnitTypes === null) {
                this.orgUnitTypes.next([])
            } else {
                this.orgUnitTypes.next(data.orgUnitTypes)
            }

            if (data.positions === null) {
                this.positions.next(undefined)
                this.currentPositionsTree.next(undefined)
            } else {
                this.positions.next(data.positions)
                this.currentPositionsTree.next(fromPositions(data.positions, 0))
            }

            if (data.costCenters === null) {
                this.costCenters.next([])
            } else {
                this.costCenters.next(data.costCenters)
            }

            if (data.employeeTypes === null) {
                this.employeeContracts.next([])
            } else {
                this.employeeContracts.next(data.employeeTypes)
            }
        })

        this.currentOrgUnitTreeRepresentation = {
            initialState: Checked.No,
            changes: [],
            opsCsSelected: OpsCsSelected.All,
        }

        this.currentPositionsTreeRepresentation = {
            initialState: Checked.No,
            changes: [],
        }

        this.currentSelectedCostCenters = {
            costCenters: [],
            types: [],
        }

        this.currentSelectedEmployeeContracts = {
            types: [],
            subTypes: [],
        }

        this.currentSelectedGenders = []
    }

    private createOrgUnitTreeRepresentation(
        tree: FolderStructureNode,
        opsCsSelected: OpsCsSelected
    ): OrgUnitsRepresentation {
        const initialState = tree.checked
        const changes: OrgUnitsRepresentationChange[] = []
        tree.children.forEach(child => {
            this.checkOrgUnitChange(child, initialState, changes)
        })
        return {
            initialState: initialState,
            changes: changes,
            opsCsSelected: opsCsSelected,
        }
    }

    private createPositionsTreeRepresentation(tree: PositionsNode): PositionsRepresentation {
        const initialState = tree.checked
        const changes: PositionsRepresentationChange[] = []
        tree.children.forEach(child => {
            this.checkPositionsChange(child, initialState, changes)
        })
        return {
            initialState: initialState,
            changes: changes,
        }
    }

    private parseOrgUnitTree(tree: FolderStructureNode, opsCsSelected: OpsCsSelected) {
        this.currentOrgUnitTreeRepresentation = this.createOrgUnitTreeRepresentation(tree, opsCsSelected)
    }

    private parsePositionsTree(tree: PositionsNode) {
        this.currentPositionsTreeRepresentation = this.createPositionsTreeRepresentation(tree)
    }

    private checkOrgUnitChange(
        node: FolderStructureNode,
        previous_state: Checked,
        changes: OrgUnitsRepresentationChange[]
    ) {
        if (node.checked !== previous_state) {
            changes.push({ masterId: node.orgUnit.masterId, checked: node.checked })
        }

        node.children.forEach(child => {
            this.checkOrgUnitChange(child, node.checked, changes)
        })
    }

    private checkPositionsChange(
        node: PositionsNode,
        previous_state: Checked,
        changes: PositionsRepresentationChange[]
    ) {
        if (node.checked !== previous_state) {
            changes.push({ masterId: node.position?.masterId || "", checked: node.checked })
        }

        node.children.forEach(child => {
            this.checkPositionsChange(child, node.checked, changes)
        })
    }

    private getEdpData(): Observable<EdpData> {
        return this.http.get<EdpData>(this.apiUrl + "/edp-data")
    }

    private checkOrgUnitNode(tree: FolderStructureNode, orgUnitIds: string[]) {
        if (tree.checked === Checked.Yes || tree.checked === Checked.PartialYes || tree.checked === Checked.Only) {
            orgUnitIds.push(tree.orgUnit.masterId)
        }

        tree.children.forEach(child => {
            this.checkOrgUnitNode(child, orgUnitIds)
        })
    }

    private checkPositionsNode(tree: PositionsNode, positions: string[]) {
        if (tree.checked === Checked.Yes || tree.checked === Checked.PartialYes || tree.checked === Checked.Only) {
            if (tree.position) {
                positions.push(tree.position.masterId)
            }
        }

        tree.children.forEach(child => {
            this.checkPositionsNode(child, positions)
        })
    }

    private parseToPayloadOrgUnits(tree: FolderStructureNode): OrgUnits {
        const orgUnitIds: string[] = []

        this.checkOrgUnitNode(tree, orgUnitIds)

        return {
            treeRepresentation: this.currentOrgUnitTreeRepresentation as OrgUnitsRepresentation,
            orgUnitIds: orgUnitIds,
        }
    }

    private parseToPayloadPositions(tree: PositionsNode): PositionsPayload {
        const positions: string[] = []

        this.checkPositionsNode(tree, positions)

        return {
            treeRepresentation: this.currentPositionsTreeRepresentation as PositionsRepresentation,
            positions: positions,
        }
    }

    OrgUnitTree(): BehaviorSubject<Node | undefined> {
        return this.orgUnitTree
    }

    CurrentOrgUnitTreeRepresentation(): OrgUnitsRepresentation {
        return this.currentOrgUnitTreeRepresentation
    }

    CurrentPositionsTreeRepresentation(): PositionsRepresentation {
        return this.currentPositionsTreeRepresentation
    }

    CurrentCostCenters(): CostCenters {
        return this.currentSelectedCostCenters
    }

    CurrentEmployeeContracts(): EmployeeContracts {
        return this.currentSelectedEmployeeContracts
    }

    CurrentGenders(): string[] {
        return this.currentSelectedGenders
    }

    SetCurrentOrgUnitTreeRepresentation(treeRepresentation: OrgUnitsRepresentation): void {
        this.currentOrgUnitTreeRepresentation = JSON.parse(JSON.stringify(treeRepresentation))
    }

    SetCurrentPositionsTreeRepresentation(treeRepresentation: PositionsRepresentation): void {
        this.currentPositionsTreeRepresentation = JSON.parse(JSON.stringify(treeRepresentation))
    }

    OrgUnitTypes(): BehaviorSubject<OrgUnitType[]> {
        return this.orgUnitTypes
    }

    Positions(): BehaviorSubject<Positions | undefined> {
        return this.positions
    }

    CostCenters(): BehaviorSubject<CostCenter[]> {
        return this.costCenters
    }

    EmployeeContracts(): BehaviorSubject<EmployeeType[]> {
        return this.employeeContracts
    }

    UpdateCurrentOrgUnitTree(tree: FolderStructureNode, opsCsSelected: OpsCsSelected): void {
        this.currentOrgUnitTree.next(tree)
        this.parseOrgUnitTree(tree, opsCsSelected)
    }

    UpdateCurrentPositionsTree(tree: PositionsNode): void {
        this.currentPositionsTree.next(tree)
        this.parsePositionsTree(tree)
    }

    UpdateCurrentCostCenters(costCenters: CostCenters): void {
        this.currentSelectedCostCenters = JSON.parse(JSON.stringify(costCenters))
    }

    UpdateCurrentEmployeeContracts(employeeContracts: EmployeeContracts): void {
        this.currentSelectedEmployeeContracts = JSON.parse(JSON.stringify(employeeContracts))
    }

    UpdateCurrentGenders(genders: string[]): void {
        this.currentSelectedGenders = JSON.parse(JSON.stringify(genders))
    }

    ResetCurrentOrgUnitTree(): void {
        this.currentOrgUnitTree.next(fromNode(this.orgUnitTree.value!, 0))
        this.parseOrgUnitTree(fromNode(this.orgUnitTree.value!, 0), OpsCsSelected.All)
    }

    ResetCurrentPositionsTree(): void {
        this.currentPositionsTree.next(fromPositions(this.positions.value!, 0))
        this.currentPositionsTreeRepresentation = this.createPositionsTreeRepresentation(
            fromPositions(this.positions.value!, 0)
        )
    }

    ResetCurrentCostCenters(): void {
        this.currentSelectedCostCenters = {
            costCenters: [],
            types: [],
        }
    }

    ResetCurrentEmployeeContracts(): void {
        this.currentSelectedEmployeeContracts = {
            types: [],
            subTypes: [],
        }
    }

    ResetCurrentGenders(): void {
        this.currentSelectedGenders = []
    }

    GetOrgUnitsPayload(): OrgUnits {
        return this.parseToPayloadOrgUnits(this.currentOrgUnitTree.value!)
    }

    GetPositionsPayload(): PositionsPayload {
        return this.parseToPayloadPositions(this.currentPositionsTree.value!)
    }

    IsOps(o: OrgUnit): boolean {
        return this.orgUnitTypes.value.find(ot => ot.masterId === o.typeId)?.names["hr"] === "Destinacija"
    }

    IsCompany(o: OrgUnit): boolean {
        const ot = this.orgUnitTypes.value.find(ot => ot.masterId === o.typeId)
        return ot?.names["hr"] === "d.d." || ot?.names["hr"] === "Poduzeće"
    }
}
