import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-text-input",
    templateUrl: "./text-input.component.html",
})
export class TextInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() type!: string
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    currentValue: string = ""

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (value[this.key] !== this.currentValue) {
                    this.currentValue = value[this.key]
                }
            }
        })
    }

    onChange() {
        this.update(this.currentValue)
    }
}
