import { Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-number-input",
    templateUrl: "./number-input.component.html",
})
export class NumberInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false
    currentValue: string = ""
    isNumberOrEmpty: boolean = true

    onChange() {
        if (this.isNumber()) {
            this.isNumberOrEmpty = true
            this.update(this.parseStringToNumber(this.currentValue))
        } else {
            this.isNumberOrEmpty = this.currentValue === ""
        }
    }

    isNumber() {
        return !isNaN(Number(this.currentValue))
    }

    parseStringToNumber(data: string) {
        if (data === "" || isNaN(Number(data))) {
            return null
        } else {
            return Number(data)
        }
    }

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value && value[this.key] !== this.currentValue) {
                if (value[this.key] === null || Number.isNaN(value[this.key])) {
                    this.currentValue = ""
                } else {
                    this.currentValue = value[this.key].toString()
                }
            }
        })
    }
}
