import { Component, Input } from "@angular/core"
import { EmployeeContracts, EmployeeType } from "src/app/models"

@Component({
    selector: "valben-employee-contracts-renderer",
    templateUrl: "./employee-contracts-renderer.component.html",
})
export class EmployeeContractsRendererComponent {
    @Input() employeeContracts!: EmployeeContracts
    @Input() allEmployeeContracts!: EmployeeType[]
    employeeContractNames: string[] = []

    ngOnInit(): void {
        const subTypes = this.allEmployeeContracts.flatMap(t => t.children || [])
        this.employeeContractNames = []
        this.employeeContracts.types.forEach(t => {
            const type = this.allEmployeeContracts.find(c => c.masterId === t)
            if (type) {
                this.employeeContractNames.push(type.names["hr"])
            }
        })
        this.employeeContracts.subTypes.forEach(st => {
            const subType = subTypes.find(c => c.masterId === st)
            if (subType) {
                this.employeeContractNames.push(subType.names["hr"])
            }
        })
    }
}
