<div class="max-w-md grid grid-flow-col items-center gap-5 select-none p-4 pb-0">
    <button
        class="valben-button text-lg rounded-t-xl rounded-b-none font-semibold border-b-0
            [&&]:hover:bg-valamarblueprimary [&&]:hover:text-white"
        [ngClass]="contentType !== 0
            ? 'bg-transparent text-valamarblueprimary'
            : null"
        (click)="setContentType('Search')">
        Tablica
    </button>
    <button
        class="valben-button text-lg rounded-t-xl rounded-b-none font-semibold border-b-0
            [&&]:hover:bg-valamarblueprimary [&&]:hover:text-white"
        [ngClass]="contentType !== 1
            ? 'bg-transparent text-valamarblueprimary'
            : ''"
        (click)="setContentType('Add')">
        Dodaj
    </button>
    <button
        class="valben-button text-lg rounded-t-xl rounded-b-none font-semibold border-b-0
            enabled:[&&]:hover:bg-valamarblueprimary enabled:[&&]:hover:text-white"
        [ngClass]="contentType !== 2
            ? 'bg-transparent text-valamarblueprimary '
            : ''"
        (click)="setContentType('Edit')"
        [disabled]="!editEnabled">
        Uredi
    </button>
</div>

