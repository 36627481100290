import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core"
import { FormInputSpecs } from "../../models"

@Component({
    selector: "valben-single-choice-list",
    templateUrl: "./single-choice-list.component.html",
})
export class SingleChoiceListComponent implements OnInit, OnChanges {
    @Output() specificationChange = new EventEmitter<{ data: { masterId: string; name: string }; type: string }>()

    @Input() specification!: FormInputSpecs
    @Input() name!: string
    @Input() type!: string
    @Input() showName!: boolean

    @Input() masterId!: string

    @Input() changeValues!: number

    selectedOption: { masterId: string; name: string } | undefined = undefined

    get options(): { masterId: string; name: string }[] {
        return this.specification.options.filter((option: { masterId: string; name: string }) => {
            if (option.masterId === "") {
                return true
            }
            return option.masterId !== this.masterId
        })
    }

    selectOptionOnClick(): void {
        if (this.selectedOption) {
            this.specificationChange.emit({
                data: {
                    masterId: this.selectedOption.masterId,
                    name: this.selectedOption.name,
                },
                type: this.type,
            })
        }
    }

    ngOnInit(): void {
        this.selectedOption = this.specification.options.find(
            (option: { masterId: string; name: string }) => option.masterId === this.specification.value
        ) as {
            masterId: string
            name: string
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["changeValues"]) {
            // needed settemout because this needs to be executed after options()
            setTimeout(() => {
                this.selectedOption = this.specification.options.find(
                    (option: { masterId: string; name: string }) => option.masterId === this.specification.value
                ) as {
                    masterId: string
                    name: string
                }
            }, 0);
        }
    }
}
