import { Component, Input } from "@angular/core"
import { HistoryService } from "src/app/services"
import { History } from "../../models"

@Component({
    selector: "valben-history",
    templateUrl: "./history.component.html",
})
export class HistoryComponent {
    @Input() entity!: any
    values: History[] = []
    historyEntities: object[] = []
    visible: boolean = false
    error: string = ""

    constructor(
        private historyService: HistoryService,
    ) { }

    getHistory() {
        if (this.historyEntities.length > 0) {
            return
        }
        this.historyService.Get(this.entity.masterId).subscribe({
            next: (res) => {
                const resSorted = res.sort((a, b) => b.currentVersion - a.currentVersion)
                resSorted.pop()
                if (resSorted.length === 0) {
                    this.error = "Najnovija verzija"
                    return
                }
                for (const item of resSorted) {
                    this.historyEntities.push(
                        this.diff(
                            item.modifiedDate,
                            item.currentVersion - 1,
                            item.modifiedByUser,
                            item.oldValues,
                        )
                    )
                }
            },
            error: () => {this.error = "Greška prilikom dohvaćanja promjena"},
        })
    }

    diff(modDate: Date, lastV: number, modUser: string, oldValues: Record<string, any>): object {
        const retObj = JSON.parse(JSON.stringify(
            this.historyEntities.length === 0
                ? this.entity
                : this.historyEntities[this.historyEntities.length - 1]
        ))

        for (const field in oldValues) {
            const splittedFields = field.split(".")
            let retObjField = retObj

            for (let i = 0; i < splittedFields.length; i++) {
                if (i === splittedFields.length - 1) {
                    if (oldValues[field] === null) {
                        delete retObjField[splittedFields[i]]
                    } else {
                        retObjField[splittedFields[i]] = oldValues[field]
                    }
                } else {
                    if (retObjField[splittedFields[i]]) {
                        retObjField = retObjField[splittedFields[i]]
                    } else {
                        retObjField[splittedFields[i]] = {}
                        retObjField = retObjField[splittedFields[i]]
                    }
                }
            }
        }

        retObj.modifiedDate = new Date(modDate)
        retObj.version = lastV
        retObj.modifiedByUser = modUser
        return retObj
    }
}

