<p-dropdown
    #dropdown
    (onBlur)="focusOnChange()"
    (onFocus)="focusOnChange()"
    (onClick)="selectCountryCodeOnClick()"
    placeholder="Odaberite državu"
    [editable]="false"
    [autoDisplayFirst]="false"
    [filter]="true"
    styleClass="w-52"
    panelStyleClass="w-52"
    [(ngModel)]="selectedCountry"
    optionLabel="name"
>
    <ng-template let-country pTemplate="item">
        <div class="flex items-center gap-4">
            <img
                src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                [class]="'flag flag-' + country.code.toLowerCase()"
                style="width: 18px"
            />
            <div class="font-semibold">
                {{ country.name }}
            </div>
        </div>
    </ng-template>
</p-dropdown>
