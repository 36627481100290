import { FileContent, FileContentFactory, PromotionalContent } from ".."

export interface ModifyPromotionalContent {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    isMobile: boolean
    media: FileContent
}

export class ModifyPromotionalContentFactory {
    static from(pcon: PromotionalContent): ModifyPromotionalContent {
        return JSON.parse(
            JSON.stringify({
                isDraft: pcon.isDraft,
                isActive: pcon.isActive,
                isDeleted: pcon.isDeleted,
                isMobile: pcon.isMobile,
                media: FileContentFactory.fromUrl(pcon.contentUrl),
            })
        )
    }
}

enum ModiftyPromotionalContentKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    IsMobile = "isMobile",
    Media = "media",
}

export function ModifyPromotionalContentKeysObject() {
    return {
        IsDraft: ModiftyPromotionalContentKeys.IsDraft,
        IsActive: ModiftyPromotionalContentKeys.IsActive,
        IsDeleted: ModiftyPromotionalContentKeys.IsDeleted,
        IsMobile: ModiftyPromotionalContentKeys.IsMobile,
        Media: ModiftyPromotionalContentKeys.Media,
    }
}

export function ModifyEmptyPromotionalContentErrorsObject(): { [key: string]: boolean } {
    return {
        [ModiftyPromotionalContentKeys.IsActive]: false,
        [ModiftyPromotionalContentKeys.IsDeleted]: false,
        [ModiftyPromotionalContentKeys.IsDraft]: false,
        [ModiftyPromotionalContentKeys.IsMobile]: false,
        [ModiftyPromotionalContentKeys.Media]: false,
    }
}
