import { Category } from ".."

export interface ModifyCategory {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
    parentId: string
    type: string
}

export class ModifyCategoryFactory {
    static from(category: Category): ModifyCategory {
        const modifiyCategory: ModifyCategory = JSON.parse(
            JSON.stringify({
                isDraft: category.isDraft,
                isActive: category.isActive,
                isDeleted: category.isDeleted,
                names: category.names,
                parentId: category.parentId,
                type: category.type,
            })
        )
        return modifiyCategory
    }
}

enum ModifyCategoryKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    ParentId = "parentId",
    Type = "type",
}

export function ModifyCategoryKeysObject() {
    return {
        IsDraft: ModifyCategoryKeys.IsDraft,
        IsActive: ModifyCategoryKeys.IsActive,
        IsDeleted: ModifyCategoryKeys.IsDeleted,
        Names: ModifyCategoryKeys.Names,
        ParentId: ModifyCategoryKeys.ParentId,
        Type: ModifyCategoryKeys.Type,
    }
}

export function ModifyEmptyCategoryErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyCategoryKeys.IsActive]: false,
        [ModifyCategoryKeys.IsDeleted]: false,
        [ModifyCategoryKeys.IsDraft]: false,
        [ModifyCategoryKeys.Names]: false,
        [ModifyCategoryKeys.ParentId]: false,
        [ModifyCategoryKeys.Type]: false,
    }
}
