<button
    class="valben-button"
    (click)="getHistory(); visible = true">
    Povijest
</button>
<p-dialog
    header="Prikaz povijesti"
    [(visible)]="visible"
    [modal]="true">
    <div class="p-5 text-xl">
        <p *ngIf="error" class="text-lg">{{ error }}</p>

        <ng-container *ngIf="!error" [ngSwitch]="collection">
            <valben-survey-history *ngSwitchCase="'surveys'" [values]="historyEntities" />
            <valben-benefits-history *ngSwitchCase="'benefits'" [values]="historyEntities" />
            <valben-categories-history *ngSwitchCase="'categories'" [values]="historyEntities" />
            <valben-locations-history *ngSwitchCase="'locations'" [values]="historyEntities" />
            <valben-awards-history *ngSwitchCase="'awards'" [values]="historyEntities" />
            <valben-news-history *ngSwitchCase="'news'" [values]="historyEntities" />
            <valben-perks-history *ngSwitchCase="'perks'" [values]="historyEntities" />
            <valben-prom-content-history *ngSwitchCase="'promotionalContent'" [values]="historyEntities" />
            <ng-container *ngSwitchDefault>
                <div *ngIf="collection.length > 0">Greška pri dohvaćanju povijesti</div>
            </ng-container>
        </ng-container>
    </div>
</p-dialog>

