import { Dependencies, DependenciesFactory, FileContentFactory, FileContent } from ".."

export interface CreateAward {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    amount: number | null
    categoryIds: string[]
    dependencies: Dependencies
}

export class CreateAwardFactory {
    static create(): CreateAward {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            descriptions: {
                hr: "",
                en: "",
            },
            image: FileContentFactory.create(),
            amount: null,
            categoryIds: [],
            dependencies: DependenciesFactory.create(),
        }
    }
}

enum CreateAwardKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    Amount = "amount",
    CategoryIds = "categoryIds",
    Dependencies = "dependencies",
}

export function CreateAwardKeysObject() {
    return {
        IsDraft: CreateAwardKeys.IsDraft,
        IsActive: CreateAwardKeys.IsActive,
        Names: CreateAwardKeys.Names,
        Descriptions: CreateAwardKeys.Descriptions,
        Image: CreateAwardKeys.Image,
        Amount: CreateAwardKeys.Amount,
        CategoryIds: CreateAwardKeys.CategoryIds,
        Dependencies: CreateAwardKeys.Dependencies,
    }
}

export function CreateEmptyAwardErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateAwardKeys.IsActive]: false,
        [CreateAwardKeys.Names]: false,
        [CreateAwardKeys.Descriptions]: false,
        [CreateAwardKeys.Image]: false,
        [CreateAwardKeys.Amount]: false,
        [CreateAwardKeys.CategoryIds]: false,
        [CreateAwardKeys.Dependencies]: false,
    }
}
