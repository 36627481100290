import { Component, OnDestroy, OnInit } from "@angular/core"
import { MessageService } from "primeng/api"
import { Observable, Subscription } from "rxjs"
import {
    Column,
    ContentType,
    CreateEmptyNewsErrorsObject,
    CreateNews,
    CreateNewsKeysObject,
    FileContent,
    ModifyEmptyNewsErrorsObject,
    ModifyNews,
    ModifyNewsKeysObject,
    setErrors,
} from "../../models"
import { ContextService, LoadingService } from "../../services"

@Component({
    selector: "valben-news",
    templateUrl: "./news.page.html",
    styles: [`
        :host {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    `],
})
export class NewsPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () => this.contextService.getAddNewsSubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditNewsSubject()?.asObservable()

    updateAddNames: (value: any) => void
    updateAddDescriptions: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateAddLink: (value: any) => void
    updateAddImage: (value: FileContent) => void

    updateEditNames: (value: any) => void
    updateEditDescriptions: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditLink: (value: any) => void
    updateEditImage: (value: FileContent) => void

    createNewsKeys = CreateNewsKeysObject()
    modifyNewsKeys = ModifyNewsKeysObject()
    currentAddItem: () => CreateNews
    currentEditItem: () => ModifyNews

    addFormType: string = ""
    editFormType: string = ""
    newsKey: string = ""

    loading$ = this.loadingService.newsLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyNewsErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyNewsErrorsObject()
    refresh = () => this.contextService.resetNews()

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        {
            field: "descriptions",
            header: "Opis",
            filterType: "text",
            default: "hr",
            bodyType: "langs",
            filterable: true,
        },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "imageUrl", header: "Slika", bodyType: "file", sortable: false, filterable: false },
        { field: "link", header: "Link", filterType: "text", bodyType: "string" },
    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService,
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.newsKey = this.contextService.getNewsKey()

        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.newsKey, this.createNewsKeys.Names, value, this.addFormType)
        }
        this.updateAddDescriptions = (value: any) => {
            this.contextService.setItem(this.newsKey, this.createNewsKeys.Descriptions, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.newsKey, this.createNewsKeys.IsActive, value, this.addFormType)
        }
        this.updateAddLink = (value: any) => {
            this.contextService.setItem(this.newsKey, this.createNewsKeys.Link, value, this.addFormType)
        }
        this.updateAddImage = (value: FileContent) => {
            this.contextService.setItem(this.newsKey, this.createNewsKeys.Image, value, this.addFormType)
        }

        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.Names, value, this.editFormType)
        }
        this.updateEditDescriptions = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.Descriptions, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditLink = (value: any) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.Link, value, this.editFormType)
        }
        this.updateEditImage = (value: FileContent) => {
            this.contextService.setItem(this.newsKey, this.modifyNewsKeys.Image, value, this.editFormType)
        }

        this.currentAddItem = () => this.contextService.getAddNewsSubject()?.value
        this.currentEditItem = () => this.contextService.getEditNewsSubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getNewsSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (x.createdDate = new Date(x.createdDate))
            })
        })
        this.resultSubscription = this.contextService.getNewsResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.resultSubscription?.unsubscribe()
        this.itemsSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditNews(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveNews()
    }

    draft() {
        this.contextService.draftNews()
    }

    modify() {
        this.contextService.modifyNews()
    }
}
