import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "objKeys",
    pure: true,
})
export class ObjectKeysPipe implements PipeTransform {
    transform(value: object): string[] {
        return value ? Object.keys(value) : []
    }
}
