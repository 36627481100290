<div
    class="space-y-4 px-10 py-4 border-2 border-gray-900/10 rounded-md"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <p class="text-lg font-semibold grid-cols-2">{{ title }}</p>

    <div class="flex gap-2">
        <div
            draggable="true"
            (click)="uploadBrowse()"
            (dragenter)="$event.preventDefault(); hovering = true"
            (dragover)="$event.preventDefault()"
            (dragleave)="$event.preventDefault(); hovering = false"
            (drop)="uploadDrop($event)"
            class="flex justify-center items-center max-w-3xl border-2 rounded-md border-dashed
                p-10 h-[20rem] w-2/3"
            [ngClass]="{
                'border-valamarblueprimary': selectedFile.fileName || hovering,
                'border-black': !(selectedFile.fileName || hovering),
                'cursor-pointer': !selectedFile.fileName,
            }">

            <div
                *ngIf="selectedFile.fileName"
                class="relative flex flex-col gap-2 items-center justify-center h-full w-full">
                <ng-container [ngSwitch]="renderFileType()">
                    <img
                        *ngSwitchCase="1"
                        [alt]="selectedFile.fileName"
                        [src]="selectedFile.base64"
                        class="max-w-full max-h-full object-contain"
                    />

                    <video
                        *ngSwitchCase="2"
                        class="max-w-full max-h-full object-contain">
                        <source [src]="selectedFile.base64" [type]="selectedFile.contentType" />
                        Browser ne podržava ovaj video format
                    </video>

                    <i *ngSwitchDefault class="pi pi-file text-[5rem]"></i>
                </ng-container>

                <p>{{ selectedFile.fileName }}</p>
                <i
                    (click)="removeSelectedFile($event)"
                    class="absolute place-self-end pi pi-times cursor-pointer transition-colors duration-100 p-2
                        ease-in-out text-red-500 hover:text-valamargray border-2 border-red-500
                        hover:border-valamardarkgray bg-transparent hover:bg-red-500 rounded-full">
                </i>
            </div>

            <div *ngIf="!selectedFile.fileName && !selectedFile.url" class="flex flex-col items-center justify-center">
                <i class="pointer-events-none pi pi-folder-open text-valamarblueprimary text-[5rem]"></i>
                <span class="text-lg pointer-events-none">Povuci ili klikni za odabir datoteke</span>
            </div>

            <p *ngIf="selectedFile.url">Zadan je url datoteke, obriši url za omugućen upload</p>
        </div>

        <div class="w-1 bg-valamardarkgray rounded-xl"></div>

        <input
            class="w-1/3 h-10 border focus:outline-none rounded-md text-md p-2 grid-cols-1"
            [(ngModel)]="selectedFile.url"
            type="text"
            (keyup)="urlChange()"
            placeholder="URL datoteke"
            [disabled]="!!selectedFile.fileName"
        />
    </div>
</div>

<input #browseFile type="file" hidden [accept]="fileType" (change)="uploadBrowseChange($event)" />
