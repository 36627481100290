import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Category, CreateCategory, ModifyObject } from "../models"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class CategoryService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.categories

    constructor(private http: HttpClient) {}

    Get(): Observable<Category[]> {
        return this.http.get<Category[]>(this.apiUrl)
    }

    Create(createCategory: CreateCategory): Observable<Category> {
        return this.http.post<Category>(this.apiUrl, createCategory)
    }

    Modify(masterId: string, data: ModifyObject): Observable<Category> {
        return this.http.patch<Category>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<any> {
        return this.http.delete(this.apiUrl + `/${masterId}`)
    }
}
