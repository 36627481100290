<button
    class="valben-button"
    (click)="getHistory(); visible = true">
    Povijest
</button>
<p-dialog
    header="Prikaz povijesti"
    [(visible)]="visible"
    [modal]="true">
    <div class="h-full w-full space-y-4 p-5">
        <p *ngIf="error" class="text-lg">{{ error }}</p>
        <div
            *ngFor="let val of historyEntities"
            class="space-y-4">
            <hr class="h-2 bg-valamardarkgray rounded-xl">
            <valben-expand-row [value]="val"></valben-expand-row>
        </div>
    </div>
</p-dialog>

