import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core"
import {
    Checked,
    FolderStructureNode,
    Node,
    OpsCsSelected,
    OrgUnitsRepresentation,
    create,
    fromNode,
} from "src/app/models"
import { ContextService } from "src/app/services"

@Component({
    selector: "valben-org-units",
    templateUrl: "./org-units.component.html",
})
export class OrgUnitsComponent implements OnInit, AfterViewInit {
    private tree: FolderStructureNode | undefined = undefined
    private previousOrgUnitsToFilter: string = "ALL"
    orgUnitsToFilter: string = "ALL"
    searchValue: string = ""
    searchResults: FolderStructureNode[] = []
    searchIndex: number | null = null
    nextButtonDisabled: boolean = true
    previousButtonDisabled: boolean = true
    opsCsSelected: OpsCsSelected = OpsCsSelected.All
    root: HTMLElement = document.createElement("div")

    @ViewChild("root") rootElement: ElementRef | null = null
    @Output() closeSignal = new EventEmitter<any>()
    @Output() confirmSignal = new EventEmitter<any>()

    private renderOrgUnit(t: FolderStructureNode): HTMLElement {
        const child = this.createChildElement(t)
        const element = this.createOrgUnitElement(t, child)
        const checkButton = this.createCheckButton(t, element)
        const collapseButton = this.createCollapseButton(t, element)

        const p: HTMLElement = document.createElement("p")
        p.textContent = t.orgUnit.names["hr"]
        if (t.children.length > 0) {
            child.appendChild(collapseButton)
            child.appendChild(p)
            child.appendChild(checkButton)
        } else {
            child.appendChild(p)
            child.appendChild(checkButton)
        }
        return element
    }

    private createOrgUnitElement(t: FolderStructureNode, child: HTMLElement): HTMLElement {
        const element: HTMLElement = document.createElement("div")
        element.appendChild(child)
        if (t.level > 0) {
            element.style.marginLeft = "3rem"
        }
        element.classList.add("min-w-full")
        return element
    }

    private createChildElement(t: FolderStructureNode): HTMLElement {
        const child: HTMLElement = document.createElement("div")
        child.classList.add("flex", "justify-start", "items-center", "gap-2")
        child.id = t.orgUnit.masterId
        return child
    }

    private createCheckButton(t: FolderStructureNode, el: HTMLElement): HTMLElement {
        const button: HTMLElement = document.createElement("button")
        button.classList.add(
            "border",
            "border-2",
            "w-[2rem]",
            "h-[2rem]",
            "border-valamarblueprimary",
            "rounded-lg",
            "p-1",
            "m-1",
            "check"
        )
        button.addEventListener("click", () => {
            if (t.checked === Checked.Yes) {
                if (t.children.length > 0) {
                    this.setChecked(t, Checked.Only, el)
                } else {
                    this.setChecked(t, Checked.No, el)
                }
            } else if (
                t.checked === Checked.Only ||
                t.checked === Checked.PartialYes ||
                t.checked === Checked.PartialNo
            ) {
                this.setChecked(t, Checked.No, el)
            } else if (t.checked === Checked.No) {
                this.setChecked(t, Checked.Yes, el)
            }
        })
        if (t.checked === Checked.Yes) {
            button.classList.add("bg-valamarblueprimary", "pi", "pi-check", "text-white")
        } else if (t.checked === Checked.No) {
            button.classList.add("bg-valamargray", "pi", "pi-times", "text-valamarblueprimary")
        } else if (t.checked === Checked.Only) {
            button.classList.add("bg-valamarblueprimary/20", "pi", "pi-check", "text-valamarblueprimary")
        } else {
            button.classList.add("bg-valamarblueprimary/20", "pi", "pi-minus", "text-valamarblueprimary")
        }
        return button
    }

    private createCollapseButton(t: FolderStructureNode, el: HTMLElement): HTMLElement {
        const button: HTMLElement = document.createElement("button")
        button.classList.add(
            "border",
            "border-2",
            "w-[2rem]",
            "h-[2rem]",
            "border-valamarblueprimary",
            "text-valamarblueprimary",
            "rounded-xl",
            "p-1",
            "m-1"
        )
        button.classList.add("pi")
        if (t.collapsed) {
            button.classList.remove("pi-angle-right")
            button.classList.add("pi-angle-down")
            const children = document.createElement("div")
            children.classList.add("children")
            t.children.forEach(child => {
                const e = this.renderOrgUnit(child)
                children.appendChild(e)
            })
            el.appendChild(children)
        } else {
            button.classList.remove("pi-angle-down")
            button.classList.add("pi-angle-right")
            this.setCollapsedChildren(t, false)
            const children = el.querySelector(".children")
            if (children) {
                el.removeChild(children)
            }
        }
        button.addEventListener("click", () => {
            t.collapsed = !t.collapsed
            if (t.collapsed) {
                button.classList.remove("pi-angle-right")
                button.classList.add("pi-angle-down")
                const children = document.createElement("div")
                children.classList.add("children")
                t.children.forEach(child => {
                    const e = this.renderOrgUnit(child)
                    children.appendChild(e)
                })
                el.appendChild(children)
            } else {
                button.classList.remove("pi-angle-down")
                button.classList.add("pi-angle-right")
                this.setCollapsedChildren(t, false)
                const children = el.querySelector(".children")
                if (children) {
                    el.removeChild(children)
                }
            }
        })
        return button
    }

    private walkTree(
        t: FolderStructureNode,
        actionBefore: (t: FolderStructureNode) => boolean,
        actionAfter: (t: FolderStructureNode) => void,
        cancellationCondition?: () => boolean,
        callback?: (t: FolderStructureNode) => void
    ) {
        if (cancellationCondition && cancellationCondition()) {
            return
        }
        if (actionBefore(t)) {
            return
        }
        if (callback) {
            callback(t)
        }
        t.children.forEach(child => {
            this.walkTree(child, actionBefore, actionAfter, cancellationCondition, callback)
        })
        actionAfter(t)
    }

    private getNodeState(t: FolderStructureNode): Checked {
        if (t.children.length === 0) {
            return t.checked
        }
        const states = t.children.map(c => c.checked)
        const uniqueStates = new Set(states)
        if (uniqueStates.size === 1) {
            const state = uniqueStates.values().next().value
            if (state) {
                if (state === Checked.Yes || state === Checked.Only) {
                    if (t.checked === Checked.No || t.checked === Checked.PartialNo) {
                        return Checked.PartialNo
                    }
                    return Checked.Yes
                } else if (
                    state === Checked.No &&
                    (t.checked === Checked.Only || t.checked === Checked.Yes || t.checked === Checked.PartialYes)
                ) {
                    return Checked.Only
                } else if (state === Checked.No) {
                    return Checked.No
                } else {
                    return state
                }
            } else {
                return t.checked
            }
        } else {
            if (t.checked === Checked.Yes || t.checked === Checked.Only || t.checked === Checked.PartialYes) {
                return Checked.PartialYes
            } else {
                return Checked.PartialNo
            }
        }
    }

    private setNodeState(masterId: string, checked: Checked) {
        if (this.tree) {
            const node = this.findNode(this.tree, masterId)
            if (node) {
                node.checked = checked
                this.setCheckedButtonClasses(node, checked, undefined, false)
            }
        }
    }

    private setParentsChecked(t: FolderStructureNode, checked: Checked) {
        if (t.checked !== checked) {
            return
        }
        const treeCopy = this.treeCopy()
        const path: string[] = []
        if (treeCopy) {
            let found = false
            const tempPath: string[] = []
            this.walkTree(
                treeCopy,
                tt => {
                    tempPath.push(tt.orgUnit.masterId)
                    if (tt.orgUnit.masterId === t.orgUnit.masterId) {
                        found = true
                        path.push(...tempPath)
                        path.pop()
                        return true
                    }
                    return false
                },
                _ => {
                    if (!found) {
                        tempPath.pop()
                    }
                },
                () => found
            )
        }
        for (let i = path.length - 1; i >= 0; i--) {
            const node = this.findNode(this.tree!, path[i])
            const state = this.getNodeState(node!)
            this.setNodeState(node!.orgUnit.masterId, state)
        }
    }

    private setChecked(t: FolderStructureNode, checked: Checked, el: HTMLElement | null = null) {
        if (checked === Checked.Yes) {
            t.checked = Checked.Yes
            t.children.forEach(child => {
                this.setChecked(child, Checked.Yes)
            })
            if (el) {
                this.setParentsChecked(t, Checked.Yes)
                this.setCheckedButtonClasses(t, Checked.Yes, true)
                this.contextService.UpdateCurrentOrgUnitTree(this.tree!, this.opsCsSelected)
            }
        } else if (checked === Checked.Only) {
            t.checked = Checked.Only
            t.children.forEach(child => {
                this.setChecked(child, Checked.No)
            })
            if (el) {
                this.setParentsChecked(t, Checked.Only)
                this.setCheckedButtonClasses(t, Checked.Only, true)
                this.contextService.UpdateCurrentOrgUnitTree(this.tree!, this.opsCsSelected)
            }
        } else if (checked === Checked.No) {
            t.checked = Checked.No
            t.children.forEach(child => {
                this.setChecked(child, Checked.No)
            })
            if (el) {
                this.setParentsChecked(t, Checked.No)
                this.setCheckedButtonClasses(t, Checked.No, true)
                this.contextService.UpdateCurrentOrgUnitTree(this.tree!, this.opsCsSelected)
            }
        }
    }

    private findNode(t: FolderStructureNode, masterId: string): FolderStructureNode | undefined {
        if (t.orgUnit.masterId === masterId) {
            return t
        }
        for (let i = 0; i < t.children.length; i++) {
            const found = this.findNode(t.children[i], masterId)
            if (found) {
                return found
            }
        }
        return undefined
    }

    private removeCroatianCharacters(s: string): string {
        return s
            .replace("č", "c")
            .replace("ć", "c")
            .replace("š", "s")
            .replace("đ", "d")
            .replace("ž", "z")
    }

    private findNodesByName(t: FolderStructureNode, name: string): FolderStructureNode[] {
        const nodes: FolderStructureNode[] = []
        if (this.removeCroatianCharacters(t.orgUnit.names["hr"].toLowerCase()).includes(this.removeCroatianCharacters(name.toLowerCase()))) {
            nodes.push(t)
        }
        for (let i = 0; i < t.children.length; i++) {
            const found = this.findNodesByName(t.children[i], name)
            nodes.push(...found)
        }
        return nodes
    }

    private treeCopy(): FolderStructureNode | undefined {
        if (this.tree) {
            return JSON.parse(JSON.stringify(this.tree))
        } else {
            return undefined
        }
    }

    private setCollapsedChildren(t: FolderStructureNode, collapsed: boolean) {
        t.collapsed = collapsed
        t.children.forEach(child => {
            this.setCollapsedChildren(child, collapsed)
        })
    }

    private render(root: HTMLElement, data: FolderStructureNode) {
        root.innerHTML = ""
        const el = this.renderOrgUnit(data)
        root.appendChild(el)
    }

    private removeCheckedButtonClasses(node: FolderStructureNode) {
        const el = document.getElementById(node.orgUnit.masterId)
        if (!el) {
            return
        }
        const b = el.querySelector(".check")
        if (!b) {
            return
        }
        b.classList.remove("bg-valamarblueprimary")
        b.classList.remove("bg-valamargray")
        b.classList.remove("bg-valamarblueprimary/20")
        b.classList.remove("pi-minus")
        b.classList.remove("pi-times")
        b.classList.remove("pi-check")
        b.classList.remove("text-white")
        b.classList.remove("text-valamarblueprimary")
    }

    private setCheckedButtonClasses(
        node: FolderStructureNode,
        checked: Checked,
        first: boolean = false,
        propagate: boolean = true
    ) {
        this.removeCheckedButtonClasses(node)
        const el = document.getElementById(node.orgUnit.masterId)
        if (!el) {
            return
        }
        const bb = el.querySelector(".check")
        if (!bb) {
            return
        }
        if (checked === Checked.Yes) {
            bb.classList.add("bg-valamarblueprimary")
            bb.classList.add("pi-check")
            bb.classList.add("text-white")
        } else if (checked === Checked.No) {
            bb.classList.add("bg-valamargray")
            bb.classList.add("pi-times")
            bb.classList.add("text-valamarblueprimary")
        } else if (checked === Checked.Only) {
            bb.classList.add("bg-valamarblueprimary/20")
            bb.classList.add("pi-check")
            bb.classList.add("text-valamarblueprimary")
        } else {
            bb.classList.add("bg-valamarblueprimary/20")
            bb.classList.add("text-valamarblueprimary")
            bb.classList.add("pi-minus")
        }
        if (!propagate) {
            return
        }
        node.children.forEach(child => {
            let c = checked
            if (first && c === Checked.Only) {
                c = Checked.No
            }
            this.setCheckedButtonClasses(child, c)
        })
    }

    private walkTreeOpsCs(
        t: FolderStructureNode,
        callback: (n: FolderStructureNode) => boolean,
        callbackResult: boolean,
        emptyResult: boolean
    ): boolean {
        if (callback(t)) {
            return callbackResult
        }
        if (t.children.length === 0) {
            return emptyResult
        }
        let result = false
        const c: FolderStructureNode[] = []
        t.children.forEach((child: FolderStructureNode) => {
            const r = this.walkTreeOpsCs(child, callback, callbackResult, emptyResult)
            if (r) {
                result = true
                c.push(child)
            }
        })
        t.children = c
        return result
    }

    private isOps(t: FolderStructureNode): boolean {
        return this.contextService.IsOps(t.orgUnit)
    }

    private filterOpsCsOrgUnits(t: FolderStructureNode, filter: OpsCsSelected): FolderStructureNode {
        let tree = JSON.parse(JSON.stringify(t))
        if (filter === OpsCsSelected.All) {
            return tree
        } else if (filter === OpsCsSelected.Ops) {
            this.walkTreeOpsCs(
                tree,
                t => {
                    return this.isOps(t)
                },
                true,
                false
            )
            tree = this.createOpsTree(t)
        } else if (filter === OpsCsSelected.Cs) {
            this.walkTreeOpsCs(
                tree,
                t => {
                    return this.isOps(t)
                },
                false,
                true
            )
        }
        return tree
    }

    private createOpsTree(t: FolderStructureNode): FolderStructureNode {
        const root: FolderStructureNode = {
            orgUnit: JSON.parse(JSON.stringify(t.orgUnit)),
            checked: t.checked,
            children: [],
            collapsed: t.collapsed,
            level: t.level,
        }

        t.children.forEach(child => {
            if (this.contextService.IsCompany(child.orgUnit)) {
                const c: FolderStructureNode = {
                    orgUnit: JSON.parse(JSON.stringify(child.orgUnit)),
                    checked: child.checked,
                    children: [],
                    collapsed: child.collapsed,
                    level: child.level,
                }
                root.children.push(c)
            }
        })

        root.children.forEach(child => {
            const c: FolderStructureNode = this.findNode(t, child.orgUnit.masterId)!
            this.walkTree(
                c,
                _ => false,
                _ => { },
                () => false,
                t => {
                    if (this.contextService.IsOps(t.orgUnit)) {
                        const cc: FolderStructureNode = JSON.parse(JSON.stringify(t))
                        child.children.push(cc)
                    }
                }
            )
        })

        return root
    }

    private scrollToNode(node: FolderStructureNode) {
        const path: string[] = []
        const tempPath: string[] = []
        let found = false
        this.walkTree(
            this.tree!,
            t => {
                tempPath.push(t.orgUnit.masterId)
                if (t.orgUnit.masterId === node.orgUnit.masterId) {
                    path.push(...tempPath)
                    path.pop()
                    found = true
                    return true
                }
                return false
            },
            _ => {
                if (!found) {
                    tempPath.pop()
                }
            },
            () => found
        )
        this.setCollapsedChildren(this.tree!, false)
        for (let i = 0; i < path.length; i++) {
            const node = this.findNode(this.tree!, path[i])
            node!.collapsed = true
        }
        this.render(this.root, this.tree!)
        const n = document.getElementById(node.orgUnit.masterId) as HTMLElement
        const input = document.getElementById("search-input") as HTMLInputElement
        if (n && input) {
            input.blur()
            n.scrollIntoView({ block: "center" })
            let range = new Range()
            range.selectNode(n)
            window.getSelection()?.removeAllRanges()
            window.getSelection()?.addRange(range)

            const el = document.getElementById("root")
            if (el) {
                el.scrollLeft = 0
            }
        }
    }

    constructor(private contextService: ContextService) { }

    ngOnInit(): void {
        this.contextService.OrgUnits().subscribe(orgUnits => {
            if (orgUnits) {
                const tr = this.contextService.CurrentOrgUnitTreeRepresentation()
                if (tr) {
                    const tree = this.createTree(tr, orgUnits)
                    this.tree = tree
                    this.render(this.root, tree)
                    this.opsCsSelected = tr.opsCsSelected
                    this.orgUnitsToFilter = tr.opsCsSelected.toUpperCase()
                }
            }
        })
    }

    ngAfterViewInit(): void {
        if (this.rootElement) {
            this.rootElement.nativeElement.appendChild(this.root)
        }
    }

    orgUnitsRadioButtonOnClick(event: any) {
        if (this.previousOrgUnitsToFilter === event.value) {
            return
        }
        const t = this.contextService.OrgUnitTree()
        if (!t) {
            return
        }
        this.previousOrgUnitsToFilter = event.value
        let tree = fromNode(t, 0)
        if (event.value == "OPS" && t) {
            this.opsCsSelected = OpsCsSelected.Ops
            this.walkTreeOpsCs(
                tree,
                t => {
                    return this.isOps(t)
                },
                true,
                false
            )
            tree = this.createOpsTree(tree)
        } else if (event.value == "CS" && t) {
            this.opsCsSelected = OpsCsSelected.Cs
            this.walkTreeOpsCs(
                tree,
                t => {
                    return this.isOps(t)
                },
                false,
                true
            )
        } else {
            this.opsCsSelected = OpsCsSelected.All
        }
        this.tree = tree
        this.contextService.UpdateCurrentOrgUnitTree(tree, this.opsCsSelected)
        this.render(this.root, tree)
    }

    createTree(tr: OrgUnitsRepresentation, n: Node): FolderStructureNode {
        let node = create(tr, n)
        node = this.filterOpsCsOrgUnits(node, tr.opsCsSelected)
        return node
    }

    onSearchClick() {
        const nodes = this.findNodesByName(this.tree!, this.searchValue)
        this.searchResults = nodes
        this.searchIndex = 0
        if (nodes.length > 0) {
            if (nodes.length > 1) {
                this.nextButtonDisabled = false
                this.previousButtonDisabled = false
            } else {
                this.nextButtonDisabled = true
                this.previousButtonDisabled = true
            }
            this.scrollToNode(nodes[0])
        } else {
            this.nextButtonDisabled = true
            this.previousButtonDisabled = true
        }
    }

    onSearchNextClick() {
        if (this.searchResults.length <= 1) {
            return
        }
        if (this.searchIndex === null) {
            this.searchIndex = 0
        } else {
            this.searchIndex++
        }
        if (this.searchIndex >= this.searchResults.length) {
            this.searchIndex = 0
        }
        this.scrollToNode(this.searchResults[this.searchIndex])
    }

    onSearchPreviousClick() {
        if (this.searchResults.length <= 1) {
            return
        }
        if (this.searchIndex === null) {
            this.searchIndex = 0
        } else {
            this.searchIndex--
        }
        if (this.searchIndex < 0) {
            this.searchIndex = this.searchResults.length - 1
        }
        this.scrollToNode(this.searchResults[this.searchIndex])
    }

    closeOnClick() {
        this.closeSignal.emit()
    }

    confirmOnClick() {
        this.confirmSignal.emit()
    }
}
