import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "genderLabels",
    pure: false,
})
export class GenderLabelsPipe implements PipeTransform {
    transform(genders: string[]): string[] {
        const labels: string[] = []
        if (genders.includes("M")) {
            labels.push("Muško")
        }
        if (genders.includes("Z")) {
            labels.push("Žensko")
        }
        return labels
    }
}
