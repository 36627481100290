<div class="flex flex-col gap-3">
    <div class="flex justify-between items-center">
        <div class="text-lg font-semibold my-1">Tip odnosa</div>
        <div class="flex justify-end items-center gap-4">
            <button class="valben-button" (click)="closeOnClick()">X</button>
            <button class="valben-button" (click)="confirmOnClick()">Spremi</button>
        </div>
    </div>
    <div class="relative p-4 border-2 border-gray-900/10 rounded-md min-w-[16rem] min-h-[8rem] mb-2">
        <ul>
            <li *ngFor="let employee of employeeContracts" class="flex flex-col mb-2">
                <div class="flex items-center">
                    <button
                        class="border-2 w-7 h-7 border-valamarblueprimary rounded-md p-1 mr-2 pi"
                        (click)="parentCheckBoxClicked(employee.masterId)"
                        [ngClass]="{
                            '': employee.checked === 'No',
                            'pi-check bg-valamarblueprimary text-white': employee.checked === 'Yes',
                            'pi-minus bg-valamarblueprimary/20 text-valamarblueprimary': employee.checked === 'Partial'
                        }"
                    ></button>
                    {{ employee.names["hr"] }}
                </div>
                <ul class="ml-8 mt-2">
                    <li *ngFor="let child of employee.children" class="flex items-center mb-1">
                        <button
                            class="border-2 w-7 h-7 border-valamarblueprimary rounded-md p-1 mr-2 pi"
                            (click)="childCheckBoxClicked(employee.masterId, child.masterId)"
                            [ngClass]="{
                                '': child.checked === 'No',
                                'pi-check bg-valamarblueprimary text-white': child.checked === 'Yes'
                            }"
                        ></button>
                        {{ child.names["hr"] }}
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
