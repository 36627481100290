import { OrgUnit, Node as PlainNode } from "../../models"

export enum OpsCsSelected {
    Ops = "Ops",
    Cs = "Cs",
    All = "All",
}

export enum Checked {
    Yes = "Yes",
    No = "No",
    PartialYes = "PartialYes",
    PartialNo = "PartialNo",
    Only = "Only",
}

export interface Node {
    orgUnit: OrgUnit
    checked: Checked
    collapsed: boolean
    children: Node[]
    level: number
}

export interface OrgUnitsRepresentation {
    initialState: Checked
    opsCsSelected: OpsCsSelected
    changes: OrgUnitsRepresentationChange[]
}

export interface OrgUnitsRepresentationChange {
    masterId: string
    checked: Checked
}

export const from = (pn: PlainNode, level: number): Node => {
    const pnCopy = JSON.parse(JSON.stringify(pn))
    const node: Node = {
        orgUnit: pnCopy.orgUnit,
        checked: Checked.No,
        collapsed: false,
        children: [],
        level: level,
    }

    pnCopy.children.forEach((child: PlainNode) => {
        node.children.push(from(child, level + 1))
    })

    return node
}

const createNode = (
    pn: PlainNode,
    level: number,
    previous_state: Checked,
    changes: OrgUnitsRepresentationChange[]
): Node => {
    const orgUnitCopy = JSON.parse(JSON.stringify(pn.orgUnit))
    let state = previous_state
    if (changes.find(c => c.masterId === orgUnitCopy.masterId)) {
        state = changes.find(c => c.masterId === orgUnitCopy.masterId)!.checked
    }
    const node: Node = {
        orgUnit: orgUnitCopy,
        checked: state,
        collapsed: false,
        children: [],
        level: level,
    }

    pn.children.forEach((child: PlainNode) => {
        node.children.push(createNode(child, level + 1, state, changes))
    })

    return node
}

export const create = (t: OrgUnitsRepresentation, pn: PlainNode): Node => {
    const orgUnitCopy = JSON.parse(JSON.stringify(pn.orgUnit))
    const node: Node = {
        orgUnit: orgUnitCopy,
        checked: t.initialState,
        collapsed: false,
        children: [],
        level: 0,
    }

    pn.children.forEach((child: PlainNode) => {
        node.children.push(createNode(child, 1, t.initialState, t.changes))
    })

    return node
}
