import { Component, Input, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { HistoryService } from "src/app/services"

@Component({
    selector: "valben-history",
    templateUrl: "./history.component.html",
})

export class HistoryComponent implements OnDestroy {
    @Input() entity!: any
    historyEntities: any[] = []
    visible: boolean = false
    error: string = ""
    collection: string = ""
    subs!: Subscription

    constructor(private historyService: HistoryService) { }

    getHistory() {
        if (this.historyEntities.length > 0) return

        this.subs = this.historyService.Get(this.entity.masterId).subscribe({
            next: (res) => {
                const resSorted = res.sort((a, b) => b.currentVersion - a.currentVersion)
                resSorted.pop()
                if (resSorted.length === 0) {
                    this.error = "Najnovija verzija"
                    return
                }
                for (const item of resSorted) {
                    this.historyEntities.push(
                        this._diff(
                            item.modifiedDate,
                            item.currentVersion - 1,
                            item.modifiedByUser,
                            item.oldValues,
                        )
                    )
                }
                this.collection = res[0].collectionName
            },
            error: () => { this.error = "Greška prilikom dohvaćanja promjena" },
        })
    }

    private _diff(
        modDate: Date,
        lastVersion: number,
        modUser: string,
        oldValues: Record<string, any>
    ): object {
        const arrRef: any[][] = []
        const retObj = JSON.parse(JSON.stringify(
            this.historyEntities.length === 0
                ? this.entity
                : this.historyEntities[this.historyEntities.length - 1]
        ))

        for (const field in oldValues) {
            const splittedFields = field.split(".")
            let retObjField = retObj

            for (let i = 0; i < splittedFields.length; i++) {
                if (i === splittedFields.length - 1) {
                    if (oldValues[field] === null) {
                        if (!arrRef.includes(retObjField) && Array.isArray(retObjField)) {
                            arrRef.push(retObjField)
                        }
                        delete retObjField[splittedFields[i]]
                    } else {
                        retObjField[splittedFields[i]] = oldValues[field]
                    }
                } else {
                    if (retObjField[splittedFields[i]]) {
                        retObjField = retObjField[splittedFields[i]]
                    } else {
                        retObjField[splittedFields[i]] = {}
                        retObjField = retObjField[splittedFields[i]]
                    }
                }
            }
        }

        for (const arr of arrRef) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (!arr[i]) arr.splice(i, 1)
            }
        }

        retObj.modifiedDate = new Date(modDate)
        retObj.version = lastVersion
        retObj.modifiedByUser = modUser

        return retObj
    }

    ngOnDestroy(): void {
        this.subs?.unsubscribe()
    }
}

