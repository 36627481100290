export const environment = {
    production: false,
    envName: "dev",
    apiBaseUrl: "https://api-managment-digitalizcija.azure-api.net/valamar-benefits-api-development/api/v1/",
    appBaseUrl: "https://benefits.cms.dev.valamar.hr/",
    redirectUri: "https://benefits.cms.dev.valamar.hr",
    appClientId: "32677bca-2275-49c5-93f1-7c436ed40182",
    tenantId: "c7223f2c-1ba2-43c8-be7f-57e6e1465036",
    apiClientId: "5b920c2d-ec20-4824-94dc-8aa77e695761",
    graphEndpoint: "https://graph.microsoft.com/v1.0/me",
    endpointRoutes: {
        awards: "awards",
        benefits: "benefits",
        categories: "categories",
        dependencies: "dependencies",
        employee: "employeeData",
        history: "history",
        locations: "locations",
        news: "news",
        perks: "perks",
        promotionalContent: "promotionalContent",
        surveys: "surveys",
    },
}
