import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
    name: "phoneNumberPrefix",
    pure: false,
})
export class PhoneNumberPrefixPipe implements PipeTransform {
    transform(value: string): string {
        if (value.startsWith("1") && value.length > 1) {
            return "+1-" + value.substring(1)
        } else {
            return "+" + value
        }
    }
}
