<div *ngFor="let value of values; let i = index" class="text-xl mb-10 space-y-4">
    <div class="flex gap-5 items-center">
        <hr class="h-2 bg-valamardarkgray rounded-xl w-[10rem]">
        <p class="whitespace-nowrap text-black">Verzija {{ value.version }}</p>
        <hr class="h-2 bg-valamardarkgray rounded-xl w-full">
    </div>

    <div class="flex gap-10">
        <div>
            <b>Izmjena:</b><p class="ml-5 inline-block">{{ value.modifiedByUser }}</p>
        </div>
        <div>
            <b>Vrijeme izmjene:</b><p class="ml-5 inline-block">{{ value.modifiedDate | date: "dd.MM.yyyy., HH:mm:ss" }}</p>
        </div>
    </div>
    <div class="flex gap-10">
        <div class="flex items-center">
            <b>Aktivan:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isActive" [binary]="true" />
        </div>
        <div class="flex items-center">
            <b>Skica:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isDraft" [binary]="true" />
        </div>
    </div>
    <div class="flex items-center">
        <b>Naziv <p-dropdown [options]="value.names | objKeys" [(ngModel)]="langs[i]" />:</b>
        <p class="ml-5">{{ value.names[langs[i]] }}</p>
    </div>
    <p *ngIf="!value.geolocation.latitude || !value.geolocation.longitude">Nepoznate koordinate</p>
    <div *ngIf="value.geolocation.latitude && value.geolocation.longitude">
        <b>Koordinate:</b>
        <div [id]="mapId + '-' + i" class="border-4 rounded-md border-valamargray h-[16rem] w-[30rem]"></div>
    </div>
</div>
