import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { MsalInterceptor, MsalService } from "@azure/msal-angular"
import { catchError, Observable, throwError } from "rxjs"
import { environment } from "src/environments/environment"

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(
        private _authService: MsalService,
        private _msalInterceptor: MsalInterceptor
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var authReq = req.clone()
        authReq = this.addAuthHeader(authReq)
        return this._msalInterceptor.intercept(authReq, next).pipe(
            catchError(err => {
                return this.handleMsalResponseError(err)
            })
        )
    }

    private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        if (request.url.includes(environment.apiBaseUrl)) {
            const req = request.clone({
                setHeaders: {
                    "Content-Type": "application/json",
                    Accept: "*/*",
                },
            })
            return req
        } else {
            return request.clone()
        }
    }

    private handleMsalResponseError(err: HttpErrorResponse) {
        const er = { ...err }
        er.message = err?.error?.Error
        if (err.status === 401) {
            this._authService.logoutRedirect({
                postLogoutRedirectUri: environment.appBaseUrl,
            })
        }
        return throwError(() => er)
    }
}
