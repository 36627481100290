import { Component, Input, OnInit } from "@angular/core"
import { Observable, first } from "rxjs"
import { Category } from "../../models"

@Component({
    selector: "valben-parent-category-input",
    templateUrl: "./parent-category-input.component.html",
})
export class ParentCategoryInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() dataObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() currentEditedMasterId!: () => string | undefined
    @Input() title: string = ""
    @Input() optional: boolean = false
    @Input() error: boolean = false

    options: { masterId: string; name: string }[] = []
    selectedOption: { masterId: string; name: string } | undefined = undefined

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (
                    (this.selectedOption === undefined && value[this.key] !== "") ||
                    (this.selectedOption !== undefined && value[this.key] !== this.selectedOption.masterId)
                ) {
                    const options: { masterId: string; name: string }[] = []
                    this.dataObservable()
                        ?.pipe(first())
                        .subscribe(categories => {
                            options.push(
                                ...categories.map((category: Category) => {
                                    return { masterId: category.masterId, name: category.names["hr"] || "" }
                                })
                            )
                            this.selectedOption = options.find(o => o.masterId === value[this.key])
                        })
                }
            }
        })
        this.dataObservable()?.subscribe(categories => {
            this.options = categories.map((category: Category) => {
                return { masterId: category.masterId, name: category.names["hr"] || "" }
            })
            if (this.currentEditedMasterId !== undefined) {
                this.options = this.options.filter(o => o.masterId !== this.currentEditedMasterId())
            }
            this.checkOption()
        })
    }

    checkOption(): void {
        if (
            this.selectedOption !== undefined &&
            this.options.map(o => o.masterId).includes(this.selectedOption.masterId) === false
        ) {
            this.selectedOption = undefined
            this.update("")
        }
    }

    onChange(): void {
        if (this.selectedOption) {
            this.update(this.selectedOption.masterId)
        }
    }
}
