import { FileContent, FileContentFactory, News } from ".."

export interface ModifyNews {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    link: string
    image: FileContent
}

export class ModifyNewsFactory {
    static from(news: News): ModifyNews {
        const modifyNews: ModifyNews = JSON.parse(
            JSON.stringify({
                isDraft: news.isDraft,
                isActive: news.isActive,
                isDeleted: news.isDeleted,
                names: news.names,
                descriptions: news.descriptions,
                link: news.link,
                image: FileContentFactory.fromUrl(news.imageUrl),
            })
        )
        return modifyNews
    }
}

enum ModifyNewsKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    Descriptions = "descriptions",
    Link = "link",
    Image = "image",
}

export function ModifyNewsKeysObject() {
    return {
        IsDraft: ModifyNewsKeys.IsDraft,
        IsActive: ModifyNewsKeys.IsActive,
        IsDeleted: ModifyNewsKeys.IsDeleted,
        Names: ModifyNewsKeys.Names,
        Descriptions: ModifyNewsKeys.Descriptions,
        Link: ModifyNewsKeys.Link,
        Image: ModifyNewsKeys.Image,
    }
}

export function ModifyEmptyNewsErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyNewsKeys.IsActive]: false,
        [ModifyNewsKeys.IsDeleted]: false,
        [ModifyNewsKeys.IsDraft]: false,
        [ModifyNewsKeys.Names]: false,
        [ModifyNewsKeys.Descriptions]: false,
        [ModifyNewsKeys.Image]: false,
        [ModifyNewsKeys.Link]: false,
    }
}
