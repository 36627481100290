import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { History } from "../models"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class HistoryService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.history

    constructor(private http: HttpClient) {}

    Get(masterId: string): Observable<History[]> {
        return this.http.get<History[]>(this.apiUrl + `/${masterId}`)
    }
}
