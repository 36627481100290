export { AwardsPage } from "./awards/awards.page"
export { BenefitsPage } from "./benefits/benefits.page"
export { CategoriesPage } from "./categories/categories.page"
export { LoginPage } from "./login/login.page"
export { NavigationPage } from "./navigation/navigation.page"
export { NewsPage } from "./news/news.page"
export { PerksPage } from "./perks/perks.page"
export { SurveysPage } from "./surveys/surveys.page"
export { PromotionalContentPage } from "./promotional-content/promotional-content.page"
export { LocationsPage } from "./locations/locations.page"
