import { FilterGroup, OrgUnits, OrgUnitsRepresentation } from "../models"

export function compareDictionaries(d1: { [key: string]: string }, d2: { [key: string]: string }): boolean {
    if (Object.keys(d1).length !== Object.keys(d2).length) {
        return false
    }
    for (const key in d1) {
        if (d1[key] !== d2[key]) {
            return false
        }
    }
    return true
}

export function compareContactObject(
    d1: { email: string; phoneNumber: string },
    d2: { email: string; phoneNumber: string }
): boolean {
    return d1.email === d2.email && d1.phoneNumber === d2.phoneNumber
}

export const compareLists = (l1: string[], l2: string[]): boolean => {
    if (l1.length !== l2.length) {
        return false
    }
    for (let i = 0; i < l1.length; i++) {
        if (l2.find(e => e === l1[i]) === undefined) {
            return false
        }
    }
    return true
}

export const compareOrgUnits = (ou1: OrgUnits | null, ou2: OrgUnits | null): boolean => {
    if (ou1 === null && ou2 === null) {
        return true
    }
    if (ou1 === null || ou2 === null) {
        return false
    }
    if (
        !compareTreeRepresentations(
            ou1.treeRepresentation as OrgUnitsRepresentation,
            ou2.treeRepresentation as OrgUnitsRepresentation
        )
    ) {
        return false
    }
    if (!compareLists(ou1.orgUnitIds, ou2.orgUnitIds)) {
        return false
    }
    return true
}

export const compareFilterGroups = (fg1: FilterGroup | undefined, fg2: FilterGroup | undefined): boolean => {
    if (fg1 === undefined && fg2 === undefined) {
        return true
    }
    if (fg1 === undefined || fg2 === undefined) {
        return false
    }
    if (!compareOrgUnits(fg1.orgUnits, fg2.orgUnits)) {
        return false
    }
    return true
}

export const compareTreeRepresentations = (tr1: OrgUnitsRepresentation, tr2: OrgUnitsRepresentation): boolean => {
    if (tr1.opsCsSelected !== tr2.opsCsSelected || tr1.initialState !== tr2.initialState) {
        return false
    }
    if (tr1.changes.length !== tr2.changes.length) {
        return false
    }
    for (let i = 0; i < tr1.changes.length; i++) {
        for (let j = 0; j < tr2.changes.length; j++) {
            if (tr1.changes[i].masterId === tr2.changes[j].masterId) {
                if (tr1.changes[i].checked !== tr2.changes[j].checked) {
                    return false
                }
            }
        }
    }
    return true
}
