import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core"
import { Checked, PositionsNode, createPositions } from "src/app/models"
import { ContextService } from "src/app/services"

@Component({
    selector: "valben-positions",
    templateUrl: "./positions.component.html",
})
export class PositionsComponent implements OnInit, AfterViewInit {
    private tree: PositionsNode | undefined = undefined
    searchValue: string = ""
    searchResults: PositionsNode[] = []
    searchIndex: number = 0
    nextButtonDisabled: boolean = true
    previousButtonDisabled: boolean = true
    root: HTMLElement = document.createElement("div")

    @ViewChild("root") rootElement: ElementRef | null = null
    @Output() closeSignal = new EventEmitter<any>()
    @Output() confirmSignal = new EventEmitter<any>()

    private renderPositions(t: PositionsNode): HTMLElement {
        const child = this.createChildElement(t)
        const element = this.createOrgUnitElement(t, child)
        const checkButton = this.createCheckButton(t, element)
        const collapseButton = this.createCollapseButton(t, element)

        const p: HTMLElement = document.createElement("p")
        if (t.position !== null) {
            p.textContent = t.position.names["hr"]
        } else {
            p.textContent = "Pozicije"
        }
        if (t.children.length > 0) {
            child.appendChild(collapseButton)
            child.appendChild(p)
            child.appendChild(checkButton)
        } else {
            child.appendChild(p)
            child.appendChild(checkButton)
        }
        return element
    }

    private createChildElement(t: PositionsNode): HTMLElement {
        const child: HTMLElement = document.createElement("div")
        child.classList.add("flex", "justify-start", "items-center", "gap-2")
        child.id = t.position?.masterId || "root-position"
        return child
    }

    private createOrgUnitElement(t: PositionsNode, child: HTMLElement): HTMLElement {
        const element: HTMLElement = document.createElement("div")
        element.appendChild(child)
        if (t.level > 0) {
            element.style.marginLeft = "3rem"
        }
        element.classList.add("min-w-full")
        return element
    }

    private createCheckButton(t: PositionsNode, el: HTMLElement): HTMLElement {
        const button: HTMLElement = document.createElement("button")
        button.classList.add(
            "border",
            "border-2",
            "w-[2rem]",
            "h-[2rem]",
            "border-valamarblueprimary",
            "rounded-lg",
            "p-1",
            "m-1",
            "check"
        )
        button.addEventListener("click", () => {
            if (t.checked === Checked.Yes) {
                if (t.children.length > 0) {
                    this.setChecked(t, Checked.Only, el)
                } else {
                    this.setChecked(t, Checked.No, el)
                }
            } else if (
                t.checked === Checked.Only ||
                t.checked === Checked.PartialYes ||
                t.checked === Checked.PartialNo
            ) {
                this.setChecked(t, Checked.No, el)
            } else if (t.checked === Checked.No) {
                this.setChecked(t, Checked.Yes, el)
            }
        })
        if (t.checked === Checked.Yes) {
            button.classList.add("bg-valamarblueprimary", "pi", "pi-check", "text-white")
        } else if (t.checked === Checked.No) {
            button.classList.add("bg-valamargray", "pi", "pi-times", "text-valamarblueprimary")
        } else if (t.checked === Checked.Only) {
            button.classList.add("bg-valamarblueprimary/20", "pi", "pi-check", "text-valamarblueprimary")
        } else {
            button.classList.add("bg-valamarblueprimary/20", "pi", "pi-minus", "text-valamarblueprimary")
        }
        return button
    }

    private createCollapseButton(t: PositionsNode, el: HTMLElement): HTMLElement {
        const button: HTMLElement = document.createElement("button")
        button.classList.add(
            "border",
            "border-2",
            "w-[2rem]",
            "h-[2rem]",
            "border-valamarblueprimary",
            "text-valamarblueprimary",
            "rounded-xl",
            "p-1",
            "m-1"
        )
        button.classList.add("pi")
        if (t.collapsed) {
            button.classList.remove("pi-angle-right")
            button.classList.add("pi-angle-down")
            const children = document.createElement("div")
            children.classList.add("children")
            t.children.forEach(child => {
                const e = this.renderPositions(child)
                children.appendChild(e)
            })
            el.appendChild(children)
        } else {
            button.classList.remove("pi-angle-down")
            button.classList.add("pi-angle-right")
            this.setCollapsedChildren(t, false)
            const children = el.querySelector(".children")
            if (children) {
                el.removeChild(children)
            }
        }
        button.addEventListener("click", () => {
            t.collapsed = !t.collapsed
            if (t.collapsed) {
                button.classList.remove("pi-angle-right")
                button.classList.add("pi-angle-down")
                const children = document.createElement("div")
                children.classList.add("children")
                t.children.forEach(child => {
                    const e = this.renderPositions(child)
                    children.appendChild(e)
                })
                el.appendChild(children)
            } else {
                button.classList.remove("pi-angle-down")
                button.classList.add("pi-angle-right")
                this.setCollapsedChildren(t, false)
                const children = el.querySelector(".children")
                if (children) {
                    el.removeChild(children)
                }
            }
        })
        return button
    }

    private walkTree(
        t: PositionsNode,
        actionBefore: (t: PositionsNode) => boolean,
        actionAfter: (t: PositionsNode) => void,
        cancellationCondition?: () => boolean
    ) {
        if (cancellationCondition && cancellationCondition()) {
            return
        }
        if (actionBefore(t)) {
            return
        }
        t.children.forEach(child => {
            this.walkTree(child, actionBefore, actionAfter, cancellationCondition)
        })
        actionAfter(t)
    }

    private getNodeState(t: PositionsNode): Checked {
        if (t.children.length === 0) {
            return t.checked
        }
        const states = t.children.map(c => c.checked)
        const uniqueStates = new Set(states)
        if (uniqueStates.size === 1) {
            const state = uniqueStates.values().next().value
            if (state) {
                if (state === Checked.Yes || state === Checked.Only) {
                    if (t.checked === Checked.No || t.checked === Checked.PartialNo) {
                        return Checked.PartialNo
                    }
                    return Checked.Yes
                } else if (
                    state === Checked.No &&
                    (t.checked === Checked.Only || t.checked === Checked.Yes || t.checked === Checked.PartialYes)
                ) {
                    return Checked.Only
                } else if (state === Checked.No) {
                    return Checked.No
                } else {
                    return state
                }
            } else {
                return t.checked
            }
        } else {
            if (t.checked === Checked.Yes || t.checked === Checked.Only || t.checked === Checked.PartialYes) {
                return Checked.PartialYes
            } else {
                return Checked.PartialNo
            }
        }
    }

    private setNodeState(masterId: string, checked: Checked) {
        if (this.tree) {
            const node = this.findNode(this.tree, masterId)
            if (node) {
                node.checked = checked
                this.setCheckedButtonClasses(node, checked, undefined, false)
            }
        }
    }

    private setParentsChecked(t: PositionsNode, checked: Checked) {
        if (t.checked !== checked) {
            return
        }
        const treeCopy = this.treeCopy()
        const path: string[] = []
        if (treeCopy) {
            let found = false
            const tempPath: string[] = []
            this.walkTree(
                treeCopy,
                tt => {
                    let id = t.position?.masterId || "root-position"
                    if (tt.position !== null) {
                        tempPath.push(tt.position.masterId)
                        if (id === tt.position.masterId) {
                            found = true
                            path.push(...tempPath)
                            path.pop()
                            return true
                        }
                    } else {
                        tempPath.push("root-position")
                        if ("root-position" === id) {
                            found = true
                            path.push(...tempPath)
                            path.pop()
                            return true
                        }
                    }
                    return false
                },
                _ => {
                    if (!found) {
                        tempPath.pop()
                    }
                },
                () => found
            )
        }
        for (let i = path.length - 1; i >= 0; i--) {
            const node = this.findNode(this.tree!, path[i])
            const state = this.getNodeState(node!)
            this.setNodeState(node!.position?.masterId || "root-position", state)
        }
    }

    private setChecked(t: PositionsNode, checked: Checked, el: HTMLElement | null = null) {
        if (checked === Checked.Yes) {
            t.checked = Checked.Yes
            t.children.forEach(child => {
                this.setChecked(child, Checked.Yes)
            })
            if (el) {
                this.setParentsChecked(t, Checked.Yes)
                this.setCheckedButtonClasses(t, Checked.Yes, true)
                this.contextService.UpdateCurrentPositionsTree(this.tree!)
            }
        } else if (checked === Checked.Only) {
            t.checked = Checked.Only
            t.children.forEach(child => {
                this.setChecked(child, Checked.No)
            })
            if (el) {
                this.setParentsChecked(t, Checked.Only)
                this.setCheckedButtonClasses(t, Checked.Only, true)
                this.contextService.UpdateCurrentPositionsTree(this.tree!)
            }
        } else if (checked === Checked.No) {
            t.checked = Checked.No
            t.children.forEach(child => {
                this.setChecked(child, Checked.No)
            })
            if (el) {
                this.setParentsChecked(t, Checked.No)
                this.setCheckedButtonClasses(t, Checked.No, true)
                this.contextService.UpdateCurrentPositionsTree(this.tree!)
            }
        }
    }

    private findNode(t: PositionsNode, masterId: string): PositionsNode | undefined {
        const id = t.position?.masterId || "root-position"
        if (id === masterId) {
            return t
        }
        for (let i = 0; i < t.children.length; i++) {
            const found = this.findNode(t.children[i], masterId)
            if (found) {
                return found
            }
        }
        return undefined
    }

    private removeCroatianCharacters(s: string): string {
        return s
            .replace("č", "c")
            .replace("ć", "c")
            .replace("š", "s")
            .replace("đ", "d")
            .replace("ž", "z")
    }

    private findNodesByName(t: PositionsNode, name: string): PositionsNode[] {
        const nodes: PositionsNode[] = []
        if (this.removeCroatianCharacters(t.position?.names["hr"].toLowerCase() || "").includes(name.toLowerCase())) {
            nodes.push(t)
        }
        for (let i = 0; i < t.children.length; i++) {
            const found = this.findNodesByName(t.children[i], name)
            nodes.push(...found)
        }
        return nodes
    }

    private treeCopy(): PositionsNode | undefined {
        if (this.tree) {
            return JSON.parse(JSON.stringify(this.tree))
        } else {
            return undefined
        }
    }

    private setCollapsedChildren(t: PositionsNode, collapsed: boolean) {
        t.collapsed = collapsed
        t.children.forEach(child => {
            this.setCollapsedChildren(child, collapsed)
        })
    }

    private render(root: HTMLElement, data: PositionsNode) {
        root.innerHTML = ""
        const el = this.renderPositions(data)
        root.appendChild(el)
    }

    private removeCheckedButtonClasses(node: PositionsNode) {
        const el = document.getElementById(node.position?.masterId || "root-position")
        if (!el) {
            return
        }
        const b = el.querySelector(".check")
        if (!b) {
            return
        }
        b.classList.remove("bg-valamarblueprimary")
        b.classList.remove("bg-valamargray")
        b.classList.remove("bg-valamarblueprimary/20")
        b.classList.remove("pi-minus")
        b.classList.remove("pi-times")
        b.classList.remove("pi-check")
        b.classList.remove("text-white")
        b.classList.remove("text-valamarblueprimary")
    }

    private setCheckedButtonClasses(
        node: PositionsNode,
        checked: Checked,
        first: boolean = false,
        propagate: boolean = true
    ) {
        this.removeCheckedButtonClasses(node)
        const el = document.getElementById(node.position?.masterId || "root-position")
        if (!el) {
            return
        }
        const bb = el.querySelector(".check")
        if (!bb) {
            return
        }
        if (checked === Checked.Yes) {
            bb.classList.add("bg-valamarblueprimary")
            bb.classList.add("pi-check")
            bb.classList.add("text-white")
        } else if (checked === Checked.No) {
            bb.classList.add("bg-valamargray")
            bb.classList.add("pi-times")
            bb.classList.add("text-valamarblueprimary")
        } else if (checked === Checked.Only) {
            bb.classList.add("bg-valamarblueprimary/20")
            bb.classList.add("pi-check")
            bb.classList.add("text-valamarblueprimary")
        } else {
            bb.classList.add("bg-valamarblueprimary/20")
            bb.classList.add("text-valamarblueprimary")
            bb.classList.add("pi-minus")
        }
        if (!propagate) {
            return
        }
        node.children.forEach(child => {
            let c = checked
            if (first && c === Checked.Only) {
                c = Checked.No
            }
            this.setCheckedButtonClasses(child, c)
        })
    }

    private scrollToNode(node: PositionsNode) {
        const path: string[] = []
        const tempPath: string[] = []
        let found = false
        this.walkTree(
            this.tree!,
            t => {
                let id = node.position?.masterId || "root-position"
                if (t.position !== null) {
                    tempPath.push(t.position.masterId)
                    if (id === t.position.masterId) {
                        found = true
                        path.push(...tempPath)
                        path.pop()
                        return true
                    }
                } else {
                    tempPath.push("root-position")
                    if ("root-position" === id) {
                        found = true
                        path.push(...tempPath)
                        path.pop()
                        return true
                    }
                }
                return false
            },
            _ => {
                if (!found) {
                    tempPath.pop()
                }
            },
            () => found
        )
        this.setCollapsedChildren(this.tree!, false)
        for (let i = 0; i < path.length; i++) {
            const node = this.findNode(this.tree!, path[i])
            node!.collapsed = true
        }
        this.render(this.root, this.tree!)
        const n = document.getElementById(node.position?.masterId || "root-position") as HTMLElement
        const input = document.getElementById("search-input") as HTMLInputElement
        if (n && input) {
            input.blur()
            n.scrollIntoView({ block: "center" })
            let range = new Range()
            range.selectNode(n)
            window.getSelection()?.removeAllRanges()
            window.getSelection()?.addRange(range)

            const el = document.getElementById("root")
            if (el) {
                el.scrollLeft = 0
            }
        }
    }

    constructor(private contextService: ContextService) { }

    ngOnInit(): void {
        this.contextService.Positions().subscribe(positions => {
            if (positions) {
                const tr = this.contextService.CurrentPositionsTreeRepresentation()
                if (tr) {
                    const tree = createPositions(tr, positions)
                    this.tree = tree
                    this.render(this.root, tree)
                }
            }
        })
    }

    ngAfterViewInit(): void {
        if (this.rootElement) {
            this.rootElement.nativeElement.appendChild(this.root)
        }
    }

    onSearchClick() {
        const nodes = this.findNodesByName(this.tree!, this.searchValue)
        this.searchResults = nodes
        this.searchIndex = 0
        if (nodes.length > 0) {
            if (nodes.length > 1) {
                this.nextButtonDisabled = false
                this.previousButtonDisabled = false
            } else {
                this.nextButtonDisabled = true
                this.previousButtonDisabled = true
            }
            this.scrollToNode(nodes[0])
        } else {
            this.nextButtonDisabled = true
            this.previousButtonDisabled = true
        }
    }

    onSearchNextClick() {
        if (this.searchResults.length <= 1) {
            return
        }
        if (this.searchIndex === null) {
            this.searchIndex = 0
        } else {
            this.searchIndex++
        }
        if (this.searchIndex >= this.searchResults.length) {
            this.searchIndex = 0
        }
        this.scrollToNode(this.searchResults[this.searchIndex])
    }

    onSearchPreviousClick() {
        if (this.searchResults.length <= 1) {
            return
        }
        if (this.searchIndex === null) {
            this.searchIndex = 0
        } else {
            this.searchIndex--
        }
        if (this.searchIndex < 0) {
            this.searchIndex = this.searchResults.length - 1
        }
        this.scrollToNode(this.searchResults[this.searchIndex])
    }

    closeOnClick() {
        this.closeSignal.emit()
    }

    confirmOnClick() {
        this.confirmSignal.emit()
    }
}
