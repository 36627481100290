<p-table
    #dataTable
    dataKey="masterId"
    [value]="values"
    [columns]="columns"
    [rowHover]="true"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    currentPageReportTemplate="Prikazuje se {first} do {last} (ukupno {totalRecords} stavki)"
    styleClass="p-datatable-striped p-datatable-gridlines"
    [globalFilterFields]="globalFilters"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    sortField="createdDate"
    [sortOrder]="-1"
    [scrollable]="true"
    scrollDirection="horizontal">

    <ng-template pTemplate="caption">
        <div class="flex justify-between items-center">
            <button class="valben-button" (click)="refresh()">Osvježi</button>
            <div class="flex justify-center items-center gap-6">
                <a
                    class="h-12 w-12 flex justify-center items-center border-2 rounded-full cursor-pointer hover:bg-valamargray"
                    download="CMS-manual.pdf"
                    href="assets/CMS-manual.pdf"
                >
                    <i class="pi pi-question-circle"></i>
                </a>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                        class=""
                        #gFilter
                        pInputText
                        type="text"
                        placeholder="Pretraži..."
                        (input)="dataTable.filterGlobal(gFilter.value, 'contains')"
                    />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns let-rows="value">
        <tr class="text-base">
            <th colspan="1"></th>
            <th
                *ngFor="let col of columns"
                [pSortableColumnDisabled]="col.sortable === false"
                [pSortableColumn]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field"
                class="relative overflow-hidden">
                <div class="flex justify-between items-center min-w-full relative">
                    <span class="flex justify-between items-center gap-2">
                        {{ col.header }}
                        <p-sortIcon
                            *ngIf="col.sortable !== false"
                            [field]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field">
                        </p-sortIcon>
                    </span>
                    <div class="flex gap-2">
                        <p-columnFilter
                            *ngIf="col.filterable !== false"
                            [type]="col.filterType ? col.filterType : 'text'"
                            [field]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field"
                            display="menu"
                        >
                        </p-columnFilter>
                        <button
                            *ngIf="col.default"
                            class="pi pi-chevron-down"
                            (click)="$event.stopPropagation(); overlay.toggle($event)">
                        </button>
                        <p-overlayPanel #overlay>
                            <p-listbox
                                *ngIf="col.default"
                                emptyMessage="Nema podataka"
                                [options]="col.fields | langsDistinct: col.field"
                                [ngModel]="langsDefaults.get(col.field)"
                                (ngModelChange)="langsDefaults.set(col.field, $event); overlay.toggle($event)"
                            />
                        </p-overlayPanel>
                    </div>
                </div>
                <span
                    (click)="$event.stopPropagation()"
                    (mousedown)="resizeDown($event)"
                    class="absolute right-0 top-0 cursor-ew-resize w-[10px] h-full">
                </span>
            </th>
            <th>Povijest</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-columns="columns" let-expanded="expanded">
        <tr class="text-base">
            <td>
                <button class="valben-button" (click)="editEvent.emit(row['masterId'])">Uredi</button>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.bodyType">
                <div >
                    <div *ngSwitchCase="'boolean'" class="flex justify-center items-center">
                        <p-checkbox class="pointer-events-none" [(ngModel)]="row[col.field]" [binary]="true" />
                    </div>
                    <div *ngSwitchCase="'dateTime'" class="whitespace-nowrap">
                        <ng-container *ngIf="row[col.field]">
                            datum: {{ row[col.field] | date: "dd.MM.yyyy." }}
                            <br />
                            vrijeme: {{ row[col.field] | date: "HH:mm:ss" }}
                        </ng-container>
                    </div>
                    <div *ngSwitchCase="'date'" class="flex justify-center items-center">
                        {{ row[col.field] | date: "dd.MM.yyyy." }}
                    </div>
                    <div *ngSwitchCase="'number'" class="text-center whitespace-nowrap">{{ row[col.field] }}</div>
                    <div *ngSwitchCase="'string'" class="line-clamp-3">{{ row[col.field] }}</div>
                    <a *ngSwitchCase="'link'" class="line-clamp-3 hover:text-valamarbluesecondary" [href]="row[col.field]" target="_blank">{{ row[col.field] }}</a>
                    <div *ngSwitchCase="'langs'" class="line-clamp-3"> {{ row[col.field][langsDefaults.get(col.field)!] }}</div>
                    <valben-expand-modal *ngSwitchCase="'modal'" [data]="row[col.field]" class="flex justify-center items-center"></valben-expand-modal>
                    <valben-map-modal *ngSwitchCase="'map'" [data]="row[col.field]" class="flex justify-center items-center"></valben-map-modal>
                    <valben-file-modal *ngSwitchCase="'file'" [src]="row[col.field]"></valben-file-modal>
                    <p *ngSwitchDefault>{{ row[col.field] }}</p>
                </div>
            </td>
            <td>
                <valben-history [entity]="row"></valben-history>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="100%">Nema podataka.</td>
        </tr>
    </ng-template>

</p-table>
