import { Component, Input, OnInit } from "@angular/core"
import { Benefit } from "src/app/models"
import { EmployeeService } from "src/app/services"

@Component({
    selector: "valben-benefits-history",
    templateUrl: "./benefits-history.component.html",
    styles: [`
        b {
            white-space: nowrap;
        }
    `],
})

export class BenefitsHistoryComponent implements OnInit {
    @Input() values: Benefit[] = []
    nameLangs: string[] = []
    descLangs: string[] = []
    showDepends: boolean[][] = []
    included: string[][] = []
    excluded: string[][] = []

    constructor(private _empService: EmployeeService) {}

    toggle(i: number, j: number): void {
        this.showDepends[i][j] = !this.showDepends[i][j]

        if (j !== 2 && this.showDepends[i][j]) {
            this._empService.GetEmployeesNames(
                this.values[i].dependencies.includedEmployees || [],
                this.values[i].dependencies.excludedEmployees || [],
            ).subscribe(res => {
                this.included[i] = res.filter(x => x.included).map(x => x.mbradName)
                this.excluded[i] = res.filter(x => !x.included).map(x => x.mbradName)
            })
        }
    }

    ngOnInit(): void {
        this.nameLangs = this.values.map(_ => "hr")
        this.descLangs = this.values.map(_ => "hr")
        this.showDepends = this.values.map(_ => [false, false, false])
        this.included = this.values.map(_ => [])
        this.excluded = this.values.map(_ => [])
    }
}

