import { Pipe, PipeTransform } from "@angular/core"
import { PickListItem, PickListSide } from "../models"

@Pipe({
    name: "pickListRender",
    pure: false,
})
export class PickListPipe implements PipeTransform {
    transform(arr: PickListItem[] | undefined, side: PickListSide): PickListItem[] {
        if (arr === undefined) {
            return []
        }
        return arr
            .filter(el => el.currentSide === side && el.filtered)
            .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1))
    }
}
