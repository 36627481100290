import { Checked, Position, Positions } from "../../models"

export interface Node {
    position: Position | null
    checked: Checked
    collapsed: boolean
    children: Node[]
    level: number
}

export interface PositionsRepresentation {
    initialState: Checked
    changes: PositionsRepresentationChange[]
}

export interface PositionsRepresentationChange {
    masterId: string
    checked: Checked
}

export const from = (positions: Positions, level: number): Node => {
    const root: Node = {
        position: null,
        checked: Checked.No,
        collapsed: false,
        children: [],
        level: level,
    }

    const positionsCopy = JSON.parse(JSON.stringify(positions))
    positionsCopy.jobGroups.forEach((position: Position) => {
        root.children.push({
            position: position,
            checked: Checked.No,
            collapsed: false,
            children: [],
            level: level + 1,
        })
    })

    root.children.forEach((child: Node) => {
        child.children = positionsCopy.workPlaces
            .filter((p: Position) => p.parentId === child.position?.masterId)
            .map((position: Position) => {
                return {
                    position: position,
                    checked: Checked.No,
                    collapsed: false,
                    children: [],
                    level: level + 2,
                }
            })
    })

    return root
}

export const create = (t: PositionsRepresentation, positions: Positions): Node => {
    const root: Node = {
        position: null,
        checked: t.initialState,
        collapsed: false,
        children: [],
        level: 0,
    }

    const positionsCopy = JSON.parse(JSON.stringify(positions))
    positionsCopy.jobGroups.forEach((position: Position) => {
        const state = t.changes.find(c => c.masterId === position.masterId)
            ? t.changes.find(c => c.masterId === position.masterId)!.checked
            : t.initialState
        root.children.push({
            position: position,
            checked: state,
            collapsed: false,
            children: [],
            level: 1,
        })
    })

    root.children.forEach((child: Node) => {
        positionsCopy.workPlaces.forEach((position: Position) => {
            if (position.parentId === child.position?.masterId) {
                const state = t.changes.find(c => c.masterId === position.masterId)
                    ? t.changes.find(c => c.masterId === position.masterId)!.checked
                    : child.checked
                child.children.push({
                    position: position,
                    checked: state,
                    collapsed: false,
                    children: [],
                    level: 2,
                })
            }
        })
    })

    return root
}
