import { Pipe, PipeTransform } from "@angular/core"
import { ContextService } from "../services"
import { Node } from "../models"

@Pipe({
    name: "orgUnitsNames",
    pure: false,
})
export class OrgUnitsNamesPipe implements PipeTransform {
    constructor(private _cs: ContextService) {}

    private _findNameRec(node: Node, id: string): string | undefined {
        if (node.orgUnit.masterId === id) {
            return node.orgUnit.names["hr"] || ""
        }
        for (const child of node.children) {
            const result = this._findNameRec(child, id)
            if (result) {
                return result
            }
        }
        return undefined
    }

    transform(orgUnitsIds: string[]): string[] {
        const names: string[] = []
        const tree = this._cs.OrgUnitTree()
        orgUnitsIds.forEach(id => {
            names.push(tree ? this._findNameRec(tree, id) || "" : "")
        })
        return names
    }
}
