<p-table
    #dataTable
    dataKey="masterId"
    [value]="values"
    [columns]="columns"
    [rowHover]="true"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    currentPageReportTemplate="Prikazuje se {first} do {last} (ukupno {totalRecords} stavki)"
    styleClass="p-datatable-striped p-datatable-gridlines"
    [globalFilterFields]="globalFilters()"
    [expandedRowKeys]="expandedRows"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    sortField="createdDate"
    [sortOrder]="-1"
    [scrollable]="true"
    scrollDirection="horizontal">

    <ng-template pTemplate="caption">
        <div class="flex justify-between items-center">
            <button class="valben-button" (click)="refresh()">Osvježi</button>
            <div class="flex gap-5">
                <button class="valben-button" (click)="expandedRows = {}">Sažmi sve</button>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                        class=""
                        #gFilter
                        pInputText
                        type="text"
                        placeholder="Pretraži..."
                        (input)="dataTable.filterGlobal(gFilter.value, 'contains')"
                    />
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns let-rows="value">
        <tr class="text-base">
            <th colspan="2"></th>
            <th
                *ngFor="let col of columns"
                [pSortableColumnDisabled]="col.sortable === false"
                [pSortableColumn]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field"
                class="relative overflow-hidden">
                <div class="flex justify-between items-center min-w-full relative">
                    <span class="flex justify-between items-center gap-2">
                        {{ col.header }}
                        <p-sortIcon
                            *ngIf="col.sortable !== false"
                            [field]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field">
                        </p-sortIcon>
                    </span>
                    <div class="flex gap-2">
                        <p-columnFilter
                            *ngIf="col.filterable !== false"
                            [type]="col.filterType ? col.filterType : 'text'"
                            [field]="col.default ? col.field + '.' + langsDefaults.get(col.field) : col.field"
                            display="menu"
                        >
                        </p-columnFilter>
                        <button
                            *ngIf="col.default"
                            class="pi pi-chevron-down"
                            (click)="$event.stopPropagation(); overlay.toggle($event)">
                        </button>
                        <p-overlayPanel #overlay>
                            <p-listbox
                                *ngIf="col.default"
                                emptyMessage="Nema podataka"
                                [options]="getLangsDistinct(col.field)"
                                [ngModel]="langsDefaults.get(col.field)"
                                (ngModelChange)="langsDefaults.set(col.field, $event); overlay.toggle($event)"
                            />
                        </p-overlayPanel>
                    </div>
                </div>
                <span
                    (click)="$event.stopPropagation()"
                    (mousedown)="resizeDown($event)"
                    class="absolute right-0 top-0 cursor-ew-resize w-[10px] h-full">
                </span>
            </th>
            <th>Povijest</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row let-columns="columns" let-expanded="expanded">
        <tr class="text-base">
            <td [pRowToggler]="row" class="cursor-pointer">
                <i
                    class="pi text-valamarblueprimary font-bold text-lg"
                    [ngClass]="expanded ? 'pi-minus' : 'pi-plus'"
                ></i>
            </td>
            <td>
                <button class="valben-button" (click)="editEvent.emit(row['masterId'])">Uredi</button>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.bodyType">
                <div >
                    <div *ngSwitchCase="'boolean'" class="flex justify-center items-center">
                        <p-checkbox class="pointer-events-none" [(ngModel)]="row[col.field]" [binary]="true" />
                    </div>
                    <div *ngSwitchCase="'date'" class="whitespace-nowrap">
                        datum: {{ row[col.field] | date: "dd.MM.yyyy." }}
                        <br />
                        vrijeme: {{ row[col.field] | date: "HH:mm:ss" }}
                    </div>
                    <div *ngSwitchCase="'number'" class="text-center whitespace-nowrap">{{row[col.field]}}</div>
                    <div
                        *ngSwitchCase="'string'"
                        [pTooltip]="row[col.field]"
                        [showDelay]="500"
                        tooltipPosition="bottom"
                        tooltipStyleClass="max-w-2xl"
                        class="line-clamp-3">
                        {{ row[col.field] }}
                    </div>
                    <div
                        *ngSwitchCase="'langs'"
                        [pTooltip]="row[col.field][langsDefaults.get(col.field)!]"
                        [showDelay]="500"
                        tooltipPosition="bottom"
                        tooltipStyleClass="max-w-2xl"
                        class="line-clamp-3">
                        {{ row[col.field][langsDefaults.get(col.field)!] }}
                    </div>
                    <valben-expand-modal *ngSwitchCase="'modal'" [data]="row[col.field]" class="flex justify-center items-center"></valben-expand-modal>
                    <valben-map-modal *ngSwitchCase="'map'" [data]="row[col.field]" class="flex justify-center items-center"></valben-map-modal>
                    <valben-id-map-modal *ngSwitchCase="'mapIds'" [data]="row[col.field]" class="flex justify-center items-center"></valben-id-map-modal>
                    <valben-file-modal *ngSwitchCase="'file'" [src]="row[col.field]"></valben-file-modal>
                    <p *ngSwitchDefault>{{ row[col.field] }}</p>
                </div>
            </td>
            <td>
                <valben-history [entity]="row"></valben-history>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-row>
        <tr>
            <td colspan="100%">
                <valben-expand-row class="block" [value]="row"></valben-expand-row>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="100%">Nema podataka.</td>
        </tr>
    </ng-template>

</p-table>
