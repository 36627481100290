import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MsalService } from "@azure/msal-angular"

@Component({
    selector: "valben-login",
    templateUrl: "./login.page.html",
})
export class LoginPage implements OnInit{
    constructor(
        private msalService: MsalService,
        private router: Router
    ) {}

    login() {
        this.msalService.loginRedirect()
    }

    ngOnInit() {
        if (this.msalService.instance.getAllAccounts().length > 0) {
            this.router.navigateByUrl("/news")
        }
    }
}
