import { Perk, FileContent, FileContentFactory, Dependencies } from ".."

export interface ModifyPerk {
    activeFrom: Date | null
    activeTo: Date | null
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    contact: {
        email: string
        phoneNumber: string
    }
    link: string
    categoryIds: string[]
    locationIds: string[]
    dependencies: Dependencies
}

export class ModifyPerkFactory {
    static from(perk: Perk): ModifyPerk {
        const modifyPerk: ModifyPerk = JSON.parse(
            JSON.stringify({
                activeFrom: perk.activeFrom,
                activeTo: perk.activeTo,
                isDraft: perk.isDraft,
                isActive: perk.isActive,
                isDeleted: perk.isDeleted,
                names: perk.names,
                descriptions: perk.descriptions,
                image: FileContentFactory.fromUrl(perk.imageUrl),
                contact: perk.contact,
                link: perk.link,
                categoryIds: perk.categoryIds,
                locationIds: perk.locationIds,
                dependencies: perk.dependencies,
            })
        )
        return modifyPerk
    }
}

enum ModifyPerkKeys {
    ActiveFrom = "activeFrom",
    ActiveTo = "activeTo",
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    Contact = "contact",
    Link = "link",
    CategoryIds = "categoryIds",
    LocationIds = "locationIds",
    Dependencies = "dependencies",
}

export function ModifyPerkKeysObject() {
    return {
        ActiveFrom: ModifyPerkKeys.ActiveFrom,
        ActiveTo: ModifyPerkKeys.ActiveTo,
        IsDraft: ModifyPerkKeys.IsDraft,
        IsActive: ModifyPerkKeys.IsActive,
        IsDeleted: ModifyPerkKeys.IsDeleted,
        Names: ModifyPerkKeys.Names,
        Descriptions: ModifyPerkKeys.Descriptions,
        Image: ModifyPerkKeys.Image,
        Contact: ModifyPerkKeys.Contact,
        Link: ModifyPerkKeys.Link,
        CategoryIds: ModifyPerkKeys.CategoryIds,
        LocationIds: ModifyPerkKeys.LocationIds,
        Dependencies: ModifyPerkKeys.Dependencies,
    }
}

export function ModifyEmptyPerkErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyPerkKeys.ActiveFrom]: false,
        [ModifyPerkKeys.ActiveTo]: false,
        [ModifyPerkKeys.IsActive]: false,
        [ModifyPerkKeys.IsDeleted]: false,
        [ModifyPerkKeys.IsDraft]: false,
        [ModifyPerkKeys.Names]: false,
        [ModifyPerkKeys.Descriptions]: false,
        [ModifyPerkKeys.Image]: false,
        [ModifyPerkKeys.Contact]: false,
        [ModifyPerkKeys.Link]: false,
        [ModifyPerkKeys.CategoryIds]: false,
        [ModifyPerkKeys.LocationIds]: false,
        [ModifyPerkKeys.Dependencies]: false,
    }
}
