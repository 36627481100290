import { Component, OnDestroy, OnInit } from "@angular/core"
import { MessageService } from "primeng/api"
import { Observable, Subscription } from "rxjs"
import {
    Column,
    ContentType,
    CreateEmptyLocationErrorsObject,
    CreateLocation,
    CreateLocationKeysObject,
    ModifyEmptyLocationErrorsObject,
    ModifyLocation,
    ModifyLocationKeysObject,
    setErrors,
} from "src/app/models"
import { ContextService, LoadingService } from "src/app/services"

@Component({
    selector: "valben-locations",
    templateUrl: "./locations.page.html",
    styles: [
        `
            :host {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        `,
    ],
})
export class LocationsPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getAddLocationSubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditLocationSubject()?.asObservable()

    updateAddNames: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateAddGeolocation: (value: any) => void
    updateEditNames: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditGeolocation: (value: any) => void

    createLocationKeys = CreateLocationKeysObject()
    modifyLocationKeys = ModifyLocationKeysObject()
    currentAddItem: () => CreateLocation
    currentEditItem: () => ModifyLocation

    addFormType: string = ""
    editFormType: string = ""
    locationKey: string = ""

    loading$ = this.loadingService.locationsLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyLocationErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyLocationErrorsObject()
    refresh = () => this.contextService.resetLocations()
    deleteModalVisible: boolean = false

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "dateTime" },
        { field: "modifiedDate", header: "Datum izmjene", filterType: "date", bodyType: "dateTime" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "geolocation", header: "Geolokacija", bodyType: "map", sortable: false, filterable: false },
    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.locationKey = this.contextService.getLocationsKey()

        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.locationKey, this.createLocationKeys.Names, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.locationKey, this.createLocationKeys.IsActive, value, this.addFormType)
        }
        this.updateAddGeolocation = (value: any) => {
            if (value === null) {
                this.contextService.setItem(
                    this.locationKey,
                    this.createLocationKeys.Geolocation,
                    { latitude: null, longitude: null },
                    this.addFormType
                )
                return
            }
            this.contextService.setItem(this.locationKey, this.createLocationKeys.Geolocation, value, this.addFormType)
        }
        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.locationKey, this.modifyLocationKeys.Names, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.locationKey, this.modifyLocationKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.locationKey, this.modifyLocationKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.locationKey, this.modifyLocationKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditGeolocation = (value: any) => {
            if (value === null) {
                return
            }
            this.contextService.setItem(this.locationKey, this.modifyLocationKeys.Geolocation, value, this.editFormType)
        }

        this.currentAddItem = () => this.contextService.getAddLocationSubject()?.value
        this.currentEditItem = () => this.contextService.getEditLocationSubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getLocationsSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (x.createdDate = new Date(x.createdDate))
            })
        })
        this.resultSubscription = this.contextService.getLocationsResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                    life: 10000,
                })
            }
        })
    }

    ngOnDestroy() {
        this.tableValues = []
        this.resultSubscription?.unsubscribe()
        this.itemsSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditLocation(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveLocation()
    }

    draft() {
        this.contextService.draftLocation()
    }

    modify() {
        this.contextService.modifyLocation()
    }

    delete() {
        this.deleteModalVisible = false
        this.updateEditIsDeleted(true)
        this.modify()
    }

    showDeleteModal() {
        this.deleteModalVisible = true
    }

    cancel() {
        this.deleteModalVisible = false
    }

    back(edit?: boolean) {
        if (edit) {
            this.contextService.setCurrentEditLocation(undefined)
            this.editEnabled = false
        } else {
            this.contextService.resetAddLocation()
        }

        this.setContentType("Search")
    }
}
