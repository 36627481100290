import { Component, OnDestroy, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MsalBroadcastService, MsalService } from "@azure/msal-angular"
import { InteractionStatus, SsoSilentRequest } from "@azure/msal-browser"
import { tap, catchError, filter, takeUntil, BehaviorSubject } from "rxjs"

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnDestroy, OnInit {
    private readonly _destroying$ = new BehaviorSubject<void>(undefined)

    constructor(
        private _broadcastService: MsalBroadcastService,
        private _r: Router,
        private _authService: MsalService
    ) {}

    ngOnDestroy(): void {
        this._destroying$.next(undefined)
        this._destroying$.complete()
    }

    ngOnInit(): void {
        this._authService
            .initialize()
            .pipe(
                takeUntil(this._destroying$),
                tap(() => {
                    const silentRequest: SsoSilentRequest = {
                        scopes: ["User.Read"],
                        loginHint: this._authService.instance.getActiveAccount()?.username,
                    }
                    this._authService
                        .ssoSilent(silentRequest)
                        .pipe(
                            takeUntil(this._destroying$),
                            catchError(err => {
                                localStorage.clear()
                                this._r.navigate(["login"])
                                return err
                            })
                        )
                        .subscribe()
                })
            )
            .subscribe()

        this._broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$),
                catchError(err => {
                    this._r.navigate(["login"])
                    return err
                })
            )
            .subscribe()
    }
}
