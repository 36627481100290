<valben-header
    [contentType]="contentType"
    [editEnabled]="editEnabled"
    (contentTypeChange)="setContentType($event)">
</valben-header>

<div
    class="relative grow overflow-y-auto"
    [ngSwitch]="contentType"
    [ngClass]="{'blur-[2px] pointer-events-none [&_*]:select-none': loading$ | async}">

    <div *ngIf="loading$ | async" class="absolute flex left-1/2 top-1/2 justify-center items-center z-50">
        <div class="pi pi-spin pi-spinner text-[10rem] text-black/80"></div>
    </div>

    <div *ngSwitchCase="0">
        <valben-table
            (editEvent)="editOnClick($event)"
            [columns]="cols"
            [values]="tableValues"
            [refresh]="refresh">
        </valben-table>
    </div>

    <div *ngSwitchCase="1" class="flex flex-col gap-4 bg-valamargray border-2 static p-4">
        <button class="flex valben-button text-lg ml-auto" (click)="save()">Spremi</button>
        <valben-boolean-input
            [key]="createPromContentKeys.IsActive"
            [itemObservable]="addItemObservable"
            [update]="updateAddIsActive"
            title="Aktivan"
            [error]="cErrors[createPromContentKeys.IsActive]">
        </valben-boolean-input>
        <valben-file-input
            [key]="createPromContentKeys.Media"
            [itemObservable]="addItemObservable"
            [update]="updateAddMedia"
            title="Prenesi datoteku ili direktni url postojeće datoteke"
            fileType="image/*"
            [error]="cErrors[createPromContentKeys.Media]"
            [optional]="false">
        </valben-file-input>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="valben-button text-lg" (click)="back()">Natrag</button>
            <button class="text-lg valben-button" (click)="draft()">Skica</button>
            <button class="text-lg valben-button" (click)="save()">Spremi</button>
        </div>
    </div>

    <div
        *ngSwitchCase="2"
        class="flex flex-col gap-4 bg-valamargray border-2 static p-4"
        [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''"
    >
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
        <valben-boolean-input
            [key]="modifyPromContentKeys.IsActive"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsActive"
            title="Aktivan"
            [error]="mErrors[modifyPromContentKeys.IsActive]">
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyPromContentKeys.IsDraft"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDraft"
            title="Skica"
            [error]="mErrors[modifyPromContentKeys.IsDraft]">
        </valben-boolean-input>
        <valben-file-input
            [key]="modifyPromContentKeys.Media"
            [itemObservable]="editItemObservable"
            [update]="updateEditMedia"
            title="Prenesi datoteku ili direktni url postojeće datoteke"
            fileType="image/*"
            [error]="mErrors[createPromContentKeys.Media]"
            [optional]="false">
        </valben-file-input>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="valben-button text-lg" (click)="back(true)">Natrag</button>
            <button class="valben-button text-lg" (click)="showDeleteModal()">Obriši</button>
            <button class="valben-button text-lg" (click)="modify()">Spremi</button>
        </div>
        <p-dialog
            header="Jeste li sigurni da želite obrisati entitet?"
            [modal]="true"
            [(visible)]="deleteModalVisible"
            [style]="{ width: '35rem' }"
        >
            <div class="flex gap-4 pt-16 justify-center items-center select-none">
                <button class="valben-button text-lg" (click)="delete()">Da</button>
                <button class="valben-button text-lg" (click)="cancel()">Ne</button>
            </div>
        </p-dialog>
    </div>
</div>
