import { Component, Input, OnInit } from "@angular/core"
import { CostCenters } from "../../models"
import { ContextService } from "src/app/services"

@Component({
    selector: "valben-cost-centers-renderer",
    templateUrl: "./cost-centers-renderer.component.html",
})
export class CostCentersRendererComponent implements OnInit {
    @Input() costCenters!: CostCenters
    costCentersNames: string[] = []
    constructor(private contextService: ContextService) {}

    ngOnInit(): void {
        this.costCentersNames = this.costCenters.costCenters.map(ccId => {
            const foundCostCenter = this.contextService.CostCenters().find(c => c.masterId === ccId)
            return foundCostCenter ? `${foundCostCenter.code} - ${foundCostCenter.names["hr"]}` : "Unknown"
        })
    }
}
