import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { SortEvent } from "primeng/api"
import { Column } from "../../models"

@Component({
    selector: "valben-table",
    templateUrl: "./table.component.html",
})
export class TableComponent implements OnInit {
    @Input() values!: any[]
    @Input() columns!: Column[]
    @Input() refresh!: () => void
    @Output() editEvent = new EventEmitter<string>()
    expandedRows = {}
    langsDefaults!: Map<string, string>
    private _resizeElem: HTMLElement | null = null

    globalFilters(): string[] {
        return this.columns.map(item =>
            item.default ? item.field + "." + this.langsDefaults.get(item.field) : item.field
        )
    }

    getLangsDistinct(field: string): string[] {
        const langsArr: string[] = []
        this.values.forEach(item => {
            const langs = Object.keys(item[field])
            langs.forEach(lang => {
                if (!langsArr.includes(lang)) {
                    langsArr.push(lang)
                }
            })
        })
        return langsArr
    }

    customSort(event: SortEvent) {
        event.data?.sort((data1, data2) => {
            const splitField = event.field?.split(".") || []
            let value1 = data1
            let value2 = data2
            let result = null

            for (const f of splitField) {
                value1 = value1[f]
                value2 = value2[f]
            }

            if (value1 == null && value2 != null) {
                result = -1
            } else if (value1 != null && value2 == null) {
                result = 1
            } else if (value1 == null && value2 == null) {
                result = 0
            } else if (typeof value1 === "string" && typeof value2 === "string") {
                result = value1.localeCompare(value2)
            } else {
                result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0
            }

            return event.order ? event.order * result : result
        })
    }

    resizeDown(event: MouseEvent) {
        event.stopPropagation()
        document.addEventListener("mousemove", this.resizeMove)
        document.addEventListener("mouseup", this.resizeUp)
        const target = event.target as HTMLElement
        this._resizeElem = target.previousElementSibling as HTMLElement
    }

    private resizeMove = (event: MouseEvent): void => {
        const dx = event.movementX
        if (this._resizeElem) {
            this._resizeElem.style.width = `${this._resizeElem.offsetWidth + dx}px`
        }
    }

    private resizeUp = (event: MouseEvent): void => {
        event.stopPropagation()
        document.removeEventListener("mousemove", this.resizeMove)
        document.removeEventListener("mouseup", this.resizeUp)
        this._resizeElem = null
    }

    ngOnInit() {
        this.langsDefaults = new Map<string, string>(
            this.columns
                .filter(item => item.default)
                .map(item => [item.field, item.default] as [string, string])
        )
    }
}
