import { Geolocation } from ".."

export interface CreateLocation {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    geolocation: Geolocation
}

export class CreateLocationFactory {
    static create(): CreateLocation {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            geolocation: {
                latitude: null,
                longitude: null,
            },
        }
    }
}

enum CreateLocationKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    Geolocation = "geolocation",
}

export function CreateLocationKeysObject() {
    return {
        IsDraft: CreateLocationKeys.IsDraft,
        IsActive: CreateLocationKeys.IsActive,
        Names: CreateLocationKeys.Names,
        Geolocation: CreateLocationKeys.Geolocation,
    }
}

export function CreateEmptyLocationErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateLocationKeys.IsDraft]: false,
        [CreateLocationKeys.IsActive]: false,
        [CreateLocationKeys.Names]: false,
        [CreateLocationKeys.Geolocation]: false,
    }
}
