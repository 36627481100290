import { Injectable } from "@angular/core"
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http"
import { Observable, catchError, filter, tap } from "rxjs"
import { LoadingService } from "../services"
import { environment } from "src/environments/environment"

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes(environment.apiBaseUrl)) {
            const postfix = request.url.split(environment.apiBaseUrl)[1]
            this.loadingService.increment(postfix)
        }
        return next.handle(request).pipe(
            filter(event => event instanceof HttpResponse),
            tap(() => {
                if (request.url.includes(environment.apiBaseUrl)) {
                    const postfix = request.url.split(environment.apiBaseUrl)[1]
                    this.loadingService.decrement(postfix)
                }
            }),
            catchError(err => {
                if (request.url.includes(environment.apiBaseUrl)) {
                    const postfix = request.url.split(environment.apiBaseUrl)[1]
                    this.loadingService.decrement(postfix)
                }
                throw err
            })
        )
    }
}
