import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"
import { Survey, CreateSurvey, ModifyObject } from "../models"

@Injectable({
    providedIn: "root",
})
export class SurveyService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.surveys

    constructor(private http: HttpClient) {}

    Get(): Observable<Survey[]> {
        return this.http.get<Survey[]>(this.apiUrl)
    }

    Create(createNews: CreateSurvey): Observable<Survey> {
        return this.http.post<Survey>(this.apiUrl, createNews)
    }

    Modify(masterId: string, data: ModifyObject): Observable<Survey> {
        return this.http.patch<Survey>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<any> {
        return this.http.delete(this.apiUrl + `/${masterId}`)
    }
}
