import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { MsalGuard } from "@azure/msal-angular"
import {
    BenefitsPage,
    NavigationPage,
    AwardsPage,
    PerksPage,
    CategoriesPage,
    NewsPage,
    SurveysPage,
    PromotionalContentPage,
    LocationsPage,
} from "./pages"

const routes: Routes = [
    {
        path: "",
        redirectTo: "surveys",
        pathMatch: "full",
    },
    {
        path: "",
        component: NavigationPage,
        canActivate: [MsalGuard],
        canActivateChild: [MsalGuard],
        children: [
            {
                path: "news",
                component: NewsPage,
            },
            {
                path: "benefits",
                component: BenefitsPage,
            },
            {
                path: "awards",
                component: AwardsPage,
            },
            {
                path: "perks",
                component: PerksPage,
            },
            {
                path: "categories",
                component: CategoriesPage,
            },
            {
                path: "surveys",
                component: SurveysPage,
            },
            {
                path: "promotional-content",
                component: PromotionalContentPage,
            },
            {
                path: "locations",
                component: LocationsPage,
            },
        ],
    },
    {
        path: "**",
        redirectTo: "surveys",
        pathMatch: "full",
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
