import { Component, Input, OnInit } from "@angular/core"
import { Survey } from "src/app/models"

@Component({
    selector: "valben-survey-history",
    templateUrl: "./survey-history.component.html",
    styles: [`
        b {
            white-space: nowrap;
        }
    `],
})

export class SurveyHistoryComponent implements OnInit {
    @Input() values: Survey[] = []
    nameLangs: string[] = []
    descLangs: string[] = []

    ngOnInit(): void {
        this.nameLangs = this.values.map(_ => "hr")
        this.descLangs = this.values.map(_ => "hr")
    }
}

