import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"

@Component({
    selector: "valben-expand-row",
    templateUrl: "./expand-row.component.html",
})
export class ExpandRowComponent implements OnInit, AfterViewInit {
    @Input() value: any = {}
    root: HTMLElement = document.createElement("ul")
    @ViewChild("root") rootElement: ElementRef | null = null

    private render(root: HTMLElement, data: any) {
        for (const field in data) {
            const fieldRef = data[field]
            const li: HTMLElement = document.createElement("li")
            const title: HTMLElement = document.createElement("p")
            title.textContent = field

            if (fieldRef !== null && typeof fieldRef === "object") {
                if (fieldRef instanceof Date) {
                    const display: string = fieldRef.toLocaleString()
                    const value: HTMLElement = document.createElement("p")

                    value.textContent = display
                    title.classList.add("field-title")
                    value.classList.add("field-value")

                    li.appendChild(title)
                    li.appendChild(value)
                    root.appendChild(li)
                } else {
                    const button: HTMLElement = document.createElement("button")
                    const arrow: HTMLElement = document.createElement("i")
                    const expandContainer: HTMLElement = document.createElement("span")
                    const newRoot: HTMLElement = document.createElement("ul")

                    expandContainer.classList.add("expand-wrapper")
                    title.classList.add("field-title")
                    button.classList.add("valben-button")
                    arrow.classList.add("pi", "pi-caret-right")
                    newRoot.classList.add("expand-gap")

                    button.addEventListener("click", () => {
                        if (newRoot.innerHTML === "") {
                            this.render(newRoot, fieldRef)
                            arrow.classList.remove("pi-caret-right")
                            arrow.classList.add("pi-caret-down")
                        } else {
                            newRoot.innerHTML = ""
                            arrow.classList.remove("pi-caret-down")
                            arrow.classList.add("pi-caret-right")
                        }
                    })

                    button.appendChild(arrow)
                    expandContainer.appendChild(button)
                    expandContainer.appendChild(title)
                    li.appendChild(expandContainer)
                    li.appendChild(newRoot)
                    root.appendChild(li)
                }
            }
            else {
                let value: HTMLElement
                switch (typeof fieldRef) {
                    case "boolean":
                        value = document.createElement("input")
                        value.style.pointerEvents = "none"
                        value.setAttribute("type", "checkbox")
                        if (fieldRef) {
                            value.setAttribute("checked", "true")
                        }
                        break
                    default:
                        value = document.createElement("p")
                        break
                }

                if (!(data instanceof Array)) {
                    title.textContent = field
                    title.classList.add("field-title")
                    li.appendChild(title)
                }
                value.textContent = typeof fieldRef === "object" ? "____" : fieldRef
                value.classList.add("field-value")

                li.appendChild(value)
                root.appendChild(li)
            }
        }
    }

    ngOnInit() {
        this.render(this.root, this.value)
    }

    ngAfterViewInit() {
        this.rootElement?.nativeElement.appendChild(this.root)
    }
}

