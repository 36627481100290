<valben-header
    [contentType]="contentType"
    [editEnabled]="editEnabled"
    (contentTypeChange)="setContentType($event)">
</valben-header>

<div
    class="relative grow overflow-y-auto"
    [ngSwitch]="contentType"
    [ngClass]="{'blur-[2px] pointer-events-none [&_*]:select-none': loading$ | async}">

    <div *ngIf="loading$ | async" class="absolute flex left-1/2 top-1/2 justify-center items-center z-50">
        <div class="pi pi-spin pi-spinner text-[10rem] text-black/80"></div>
    </div>

    <div *ngSwitchCase="0">
        <valben-table
            (editEvent)="editOnClick($event)"
            [columns]="cols"
            [values]="tableValues"
            [refresh]="refresh">
        </valben-table>
    </div>

    <div *ngSwitchCase="1" class="flex flex-col gap-4 bg-valamargray border-2 static p-4">
        <button class="flex valben-button text-lg ml-auto" (click)="save()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="createAwardKeys.Names"
            [itemObservable]="addItemObservable"
            [update]="updateAddNames"
            [currentItem]="currentAddItem"
            title="Naziv"
            [error]="cErrors[createAwardKeys.Names]"
        ></valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="createAwardKeys.Descriptions"
            [itemObservable]="addItemObservable"
            [update]="updateAddDescriptions"
            [currentItem]="currentAddItem"
            title="Opis"
            [error]="cErrors[createAwardKeys.Descriptions]"
        ></valben-dictionary-input>
        <valben-boolean-input
            [key]="createAwardKeys.IsActive"
            [itemObservable]="addItemObservable"
            [update]="updateAddIsActive"
            title="Aktivan"
            [error]="cErrors[createAwardKeys.IsActive]"
        ></valben-boolean-input>
        <valben-file-input
            [key]="createAwardKeys.Image"
            [itemObservable]="addItemObservable"
            [update]="updateAddImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="cErrors[createAwardKeys.Image]"
        >
        </valben-file-input>
        <valben-number-input
            [key]="createAwardKeys.Amount"
            [itemObservable]="addItemObservable"
            [update]="updateAddAmount"
            title="Iznos"
            [error]="cErrors[createAwardKeys.Amount]"
        >
        </valben-number-input>
        <valben-categories-multiple-choice
            [itemObservable]="addItemObservable"
            [update]="updateAddCategories"
            [key]="createAwardKeys.CategoryIds"
            [type]="categoriesKey"
            title="Kategorije"
            [error]="cErrors[createAwardKeys.CategoryIds]">
        </valben-categories-multiple-choice>
        <valben-dependencies
            [title]="'Filteri'"
            [itemObservable]="addItemObservable"
            [key]="createAwardKeys.Dependencies"
            [update]="updateAddDependencies"
            [error]="cErrors[createAwardKeys.Dependencies]"
        ></valben-dependencies>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="valben-button text-lg" (click)="back()">Natrag</button>
            <button class="text-lg valben-button" (click)="draft()">Skica</button>
            <button class="text-lg valben-button" (click)="save()">Spremi</button>
        </div>
    </div>

    <div
        *ngSwitchCase="2"
        class="flex flex-col gap-4 bg-valamargray border-2 static p-4"
        [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''"
    >
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="modifyAwardKeys.Names"
            [itemObservable]="editItemObservable"
            [update]="updateEditNames"
            [currentItem]="currentEditItem"
            title="Naziv"
            [error]="mErrors[modifyAwardKeys.Names]"
        >
        </valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="modifyAwardKeys.Descriptions"
            [itemObservable]="editItemObservable"
            [update]="updateEditDescriptions"
            [currentItem]="currentEditItem"
            title="Opis"
            [error]="mErrors[modifyAwardKeys.Descriptions]"
        >
        </valben-dictionary-input>
        <valben-boolean-input
            [key]="modifyAwardKeys.IsActive"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsActive"
            title="Aktivan"
            [error]="mErrors[modifyAwardKeys.IsActive]"
        >
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyAwardKeys.IsDraft"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDraft"
            title="Skica"
            [error]="mErrors[modifyAwardKeys.IsDraft]"
        >
        </valben-boolean-input>
        <valben-file-input
            [key]="modifyAwardKeys.Image"
            [itemObservable]="editItemObservable"
            [update]="updateEditImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="mErrors[createAwardKeys.Image]"
        >
        </valben-file-input>
        <valben-number-input
            [key]="modifyAwardKeys.Amount"
            [itemObservable]="editItemObservable"
            [update]="updateEditAmount"
            title="Iznos"
            [error]="mErrors[modifyAwardKeys.Amount]"
        >
        </valben-number-input>
        <valben-categories-multiple-choice
            [itemObservable]="editItemObservable"
            [update]="updateEditCategories"
            [key]="modifyAwardKeys.CategoryIds"
            [type]="categoriesKey"
            title="Kategorije"
            [error]="mErrors[modifyAwardKeys.CategoryIds]"
        >
        </valben-categories-multiple-choice>
        <valben-dependencies
            [title]="'Filteri'"
            [itemObservable]="editItemObservable"
            [key]="modifyAwardKeys.Dependencies"
            [update]="updateEditDependencies"
            [error]="mErrors[modifyAwardKeys.Dependencies]"
        ></valben-dependencies>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="valben-button text-lg" (click)="back(true)">Natrag</button>
            <button class="valben-button text-lg" (click)="showDeleteModal()">Obriši</button>
            <button class="valben-button text-lg" (click)="modify()">Spremi</button>
        </div>
        <p-dialog
            header="Jeste li sigurni da želite obrisati entitet?"
            [modal]="true"
            [(visible)]="deleteModalVisible"
            [style]="{ width: '35rem' }"
        >
            <div class="flex gap-4 pt-16 justify-center items-center select-none">
                <button class="valben-button text-lg" (click)="delete()">Da</button>
                <button class="valben-button text-lg" (click)="cancel()">Ne</button>
            </div>
        </p-dialog>
    </div>
</div>
