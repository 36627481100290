import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Perk, CreatePerk, ModifyObject } from "../models"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class PerksService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.perks

    constructor(private http: HttpClient) {}

    Get(): Observable<Perk[]> {
        return this.http.get<Perk[]>(this.apiUrl)
    }

    Create(createNews: CreatePerk): Observable<Perk> {
        return this.http.post<Perk>(this.apiUrl, createNews)
    }

    Modify(masterId: string, data: ModifyObject): Observable<Perk> {
        return this.http.patch<Perk>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<any> {
        return this.http.delete(this.apiUrl + `/${masterId}`)
    }
}
