import { Component, Input } from "@angular/core"
import { PositionsPayload } from "../../models"

@Component({
    selector: "valben-positions-renderer",
    templateUrl: "./positions-renderer.component.html",
})
export class PositionsRendererComponent {
    @Input() positions!: PositionsPayload
}
