<valben-header
    [contentType]="contentType"
    [editEnabled]="editEnabled"
    (contentTypeChange)="setContentType($event)">
</valben-header>

<div
    class="relative grow overflow-y-auto"
    [ngSwitch]="contentType"
    [ngClass]="{'blur-[2px] pointer-events-none [&_*]:select-none': loading$ | async}">

    <div *ngIf="loading$ | async" class="absolute flex left-1/2 top-1/2 justify-center items-center z-50">
        <div class="pi pi-spin pi-spinner text-[10rem] text-black/80"></div>
    </div>

    <div *ngSwitchCase="0">
        <valben-table
            (editEvent)="editOnClick($event)"
            [columns]="cols"
            [values]="tableValues"
            [refresh]="refresh">
        </valben-table>
    </div>

    <div *ngSwitchCase="1" class="flex flex-col gap-4 bg-valamargray border-2 static p-4">
        <button class="flex valben-button text-lg ml-auto" (click)="save()">Spremi</button>
        <valben-text-input
            [key]="createSurveyKeys.Name"
            [itemObservable]="addItemObservable"
            [update]="updateAddName"
            title="Naziv"
            [error]="cErrors[createSurveyKeys.Name]">
        </valben-text-input>
        <valben-text-input
            [key]="createSurveyKeys.Description"
            [itemObservable]="addItemObservable"
            [update]="updateAddDescription"
            title="Opis"
            [error]="cErrors[createSurveyKeys.Description]">
        </valben-text-input>
        <valben-boolean-input
            [key]="createSurveyKeys.IsActive"
            [itemObservable]="addItemObservable"
            [update]="updateAddIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="cErrors[createSurveyKeys.IsActive]">
        </valben-boolean-input>
        <valben-text-input
            [key]="createSurveyKeys.Link"
            [itemObservable]="addItemObservable"
            [update]="updateAddLink"
            title="Link"
            [error]="cErrors[createSurveyKeys.Link]">
        </valben-text-input>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="text-lg valben-button" (click)="draft()">Skica</button>
            <button class="text-lg valben-button" (click)="save()">Spremi</button>
        </div>
    </div>

    <div
        *ngSwitchCase="2"
        class="flex flex-col gap-4 bg-valamargray border-2 static p-4"
        [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''"
    >
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
        <valben-text-input
            [key]="modifySurveyKeys.Name"
            [itemObservable]="editItemObservable"
            [update]="updateEditName"
            title="Naziv"
            [error]="mErrors[modifySurveyKeys.Name]">
        </valben-text-input>
        <valben-text-input
            [key]="modifySurveyKeys.Description"
            [itemObservable]="editItemObservable"
            [update]="updateEditDescription"
            title="Opis"
            [error]="mErrors[modifySurveyKeys.Description]">
        </valben-text-input>
        <valben-boolean-input
            [key]="modifySurveyKeys.IsActive"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="mErrors[modifySurveyKeys.IsActive]">
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifySurveyKeys.IsDraft"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDraft"
            title="Skica"
            [optional]="true"
            [error]="mErrors[modifySurveyKeys.IsDraft]">
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifySurveyKeys.IsDeleted"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDeleted"
            title="Obriši"
            [optional]="true"
            [error]="mErrors[modifySurveyKeys.IsDeleted]">
        </valben-boolean-input>
        <valben-text-input
            [key]="modifySurveyKeys.Link"
            [itemObservable]="editItemObservable"
            [update]="updateEditLink"
            title="Link"
            [error]="mErrors[modifySurveyKeys.Link]">
        </valben-text-input>
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
    </div>
</div>
