import { Component, Input, Output, EventEmitter } from "@angular/core"

@Component({
    selector: "valben-countries-dropdown",
    templateUrl: "./countries-dropdown.component.html",
    styleUrls: ["../../helpers/flags.css"]
})
export class CountriesDropdownComponent {
    @Output() signal = new EventEmitter<{ code: string }>()

    @Input() options!: { code: string, name: string }[]

    focused: boolean = false

    selectedCountry: { code: string; name: string } | undefined = undefined

    focusOnChange(): void {
        this.focused = !this.focused
    }

    selectCountryCodeOnClick(): void {
        if (this.selectedCountry && this.focused) {
            this.signal.emit({
                code: this.selectedCountry.code,
            })
        }
    }
}
