import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { HttpClient } from "@angular/common/http"
import { News, CreateNews, ModifyObject } from "../models"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class NewsService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.news

    constructor(private http: HttpClient) {}

    Get(): Observable<News[]> {
        return this.http.get<News[]>(this.apiUrl)
    }

    Create(createNews: CreateNews): Observable<News> {
        return this.http.post<News>(this.apiUrl, createNews)
    }

    Modify(masterId: string, data: ModifyObject): Observable<News> {
        return this.http.patch<News>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<any> {
        return this.http.delete(this.apiUrl + `/${masterId}`)
    }
}
