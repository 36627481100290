import { NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { FormsModule } from "@angular/forms"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { environment } from "src/environments/environment"

import { PanelMenuModule } from "primeng/panelmenu"
import { MessageModule } from "primeng/message"
import { TableModule } from "primeng/table"
import { ButtonModule } from "primeng/button"
import { InputTextModule } from "primeng/inputtext"
import { CheckboxModule } from "primeng/checkbox"
import { DropdownModule } from "primeng/dropdown"
import { DialogModule } from "primeng/dialog"
import { ToastModule } from "primeng/toast"
import { MessageService } from "primeng/api"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { ListboxModule } from "primeng/listbox"
import { FileUploadModule } from "primeng/fileupload"
import { ToggleButtonModule } from "primeng/togglebutton"
import { RadioButtonModule } from "primeng/radiobutton"
import { CalendarModule } from "primeng/calendar"

import {
    AwardsPage,
    BenefitsPage,
    CategoriesPage,
    NavigationPage,
    NewsPage,
    PerksPage,
    SurveysPage,
    PromotionalContentPage,
    LocationsPage,
} from "./pages"

import {
    OnlyNumbersDirective,
    BooleanInputComponent,
    CountriesDropdownComponent,
    DictionaryInputComponent,
    SingleChoiceListComponent,
    TypeInputComponent,
    ContactInputComponent,
    TextInputComponent,
    NumberInputComponent,
    ParentCategoryInputComponent,
    CategoriesMultipleChoiceComponent,
    MultipleChoiceListComponent,
    FileModalComponent,
    ExpandModalComponent,
    HeaderComponent,
    AppMsalRedirectComponent,
    TableComponent,
    ExpandRowComponent,
    HistoryComponent,
    FileInputComponent,
    OrgUnitsRendererComponent,
    OrgUnitsComponent,
    PositionsComponent,
    PositionsRendererComponent,
    DependenciesComponent,
    PickListComponent,
    CostCentersRendererComponent,
    CostCentersComponent,
    EmployeeContractsComponent,
    EmployeeContractsRendererComponent,
    GendersComponent,
    GendersRendererComponent,
    LocationInputComponent,
    LocationsMultipleChoiceComponent,
    MapModalComponent,
    DateTimePickerComponent,
    VirtualListBoxComponent,
    NewsHistoryComponent,
    PerksHistoryComponent,
    PromContentHistoryComponent,
    BenefitsHistoryComponent,
    SurveyHistoryComponent,
    AwardsHistoryComponent,
    LocationsHistoryComponent,
    CategoriesHistoryComponent,
} from "./components"

import { LoadingInterceptor } from "./interceptors"

import {
    PickListPipe,
    PositionsNamesPipe,
    PhoneNumberPrefixPipe,
    OrgUnitsNamesPipe,
    GenderLabelsPipe,
    CostCenterNamesPipe,
    ContractNamesPipe,
    LangsDistinctPipe,
    ObjectKeysPipe,
} from "./pipes"

import {
    BrowserCacheLocation,
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
} from "@azure/msal-browser"

import {
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalGuardConfiguration,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MSAL_GUARD_CONFIG,
    MsalModule,
    MsalInterceptor,
} from "@azure/msal-angular"

const MSALInstanceFactory = (): IPublicClientApplication => {
    return new PublicClientApplication({
        auth: {
            clientId: environment.appClientId,
            authority: "https://login.microsoftonline.com/" + environment.tenantId,
            redirectUri: environment.redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    })
}

const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
            [environment.apiBaseUrl, ["api://" + environment.apiClientId + "/benefits.all"]],
        ]),
    }
}

const MSALGuardConfigFactory = (): MsalGuardConfiguration => {
    return {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: "/",
    }
}

@NgModule({
    declarations: [
        AppComponent,
        BenefitsPage,
        NavigationPage,
        AwardsPage,
        PerksPage,
        CategoriesPage,
        NewsPage,
        SurveysPage,
        PromotionalContentPage,
        LocationsPage,
        CountriesDropdownComponent,
        MultipleChoiceListComponent,
        TextInputComponent,
        NumberInputComponent,
        BooleanInputComponent,
        DictionaryInputComponent,
        CategoriesMultipleChoiceComponent,
        ParentCategoryInputComponent,
        SingleChoiceListComponent,
        ContactInputComponent,
        ExpandRowComponent,
        TypeInputComponent,
        TableComponent,
        HeaderComponent,
        FileModalComponent,
        ExpandModalComponent,
        FileInputComponent,
        HistoryComponent,
        OnlyNumbersDirective,
        DependenciesComponent,
        OrgUnitsComponent,
        PickListComponent,
        OrgUnitsRendererComponent,
        PositionsComponent,
        PositionsRendererComponent,
        CostCentersComponent,
        CostCentersRendererComponent,
        PickListPipe,
        PhoneNumberPrefixPipe,
        PositionsNamesPipe,
        LangsDistinctPipe,
        ObjectKeysPipe,
        OrgUnitsNamesPipe,
        EmployeeContractsComponent,
        EmployeeContractsRendererComponent,
        GendersComponent,
        GendersRendererComponent,
        GenderLabelsPipe,
        CostCenterNamesPipe,
        ContractNamesPipe,
        LocationInputComponent,
        LocationsMultipleChoiceComponent,
        MapModalComponent,
        DateTimePickerComponent,
        VirtualListBoxComponent,
        NewsHistoryComponent,
        PerksHistoryComponent,
        PromContentHistoryComponent,
        BenefitsHistoryComponent,
        SurveyHistoryComponent,
        AwardsHistoryComponent,
        LocationsHistoryComponent,
        CategoriesHistoryComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        PanelMenuModule,
        HttpClientModule,
        MsalModule,
        FormsModule,
        TableModule,
        ButtonModule,
        ToggleButtonModule,
        InputTextModule,
        CheckboxModule,
        RadioButtonModule,
        DropdownModule,
        DialogModule,
        MessageModule,
        ToastModule,
        OverlayPanelModule,
        ListboxModule,
        FileUploadModule,
        ScrollingModule,
        CalendarModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MessageService,
        MsalInterceptor,
    ],
    bootstrap: [AppComponent, AppMsalRedirectComponent],
})
export class AppModule { }
