export interface FileContent {
    url: string
    base64: string
    fileName: string
    contentType: string
}

export class FileContentFactory {
    static create(): FileContent {
        return {
            url: "",
            base64: "",
            fileName: "",
            contentType: "",
        }
    }

    static fromUrl(url: string): FileContent {
        return {
            url: url,
            base64: "",
            fileName: "",
            contentType: "",
        }
    }
}
