export class Iso3166alpha2 {
    private static readonly _iso3166alpha2: { [key: string]: string } = {
        hr: "Croatian",     
        cz: "Czech",        
        en: "English",      
        fr: "French",       
        de: "German",       
        it: "Italian",      
        pl: "Polish",       
        sk: "Slovak",       
        si: "Slovenian",    
    }

    public static get iso3166alpha2(): { [key: string]: string } {
        return this._iso3166alpha2
    }

    public static getCountryName(countryCode: string): string {
        return this._iso3166alpha2[countryCode.toLowerCase()]
    }

    public static getFormated(): { code: string, name: string }[] {
        return Object.keys(this._iso3166alpha2).map((key) => {
            return {
                code: key,
                name: this._iso3166alpha2[key],
            }
        })
    }
}
