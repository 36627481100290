import { Component, Input, OnInit } from "@angular/core"
import { Iso3166alpha2 } from "src/app/helpers/ISO3166CountryCodes"
import { Observable } from "rxjs"
import { compareDictionaries } from "src/app/helpers/Comparators"

@Component({
    selector: "valben-dictionary-input",
    templateUrl: "./dictionary-input.component.html",
})
export class DictionaryInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() textArea: boolean = false
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() currentItem!: () => any | undefined
    @Input() title: string = ""
    @Input() error: boolean = false

    currentValues: { [key: string]: string } = {}
    currentValuesKeys: string[] = []

    showSelectCountry: boolean = false
    showCountryNameInput: boolean = false
    showNewCountryButton: boolean = false
    selectedCountry: string | null = null
    inputedValue: string = ""

    private setCurrValObjectKeys(): void {
        this.currentValuesKeys = Object.keys(this.currentValues)
    }

    getCountries(): { code: string; name: string }[] {
        return Iso3166alpha2.getFormated()
            .filter(country => {
                return !this.currentValues.hasOwnProperty(country.code)
            })
            .map(country => {
                return { code: country.code, name: country.name }
            })
    }

    getCountryName(code: string | null): string {
        if (code) {
            return Iso3166alpha2.getCountryName(code)
        } else {
            return ""
        }
    }

    newCountryOnClick(): void {
        this.showSelectCountry = !this.showSelectCountry
        this.showNewCountryButton = !this.showNewCountryButton
    }

    selectCountryCodeOnClick(): void {
        this.showSelectCountry = !this.showSelectCountry
        this.showCountryNameInput = !this.showCountryNameInput
    }

    selectCountry(result: { code: string }) {
        this.selectedCountry = result.code
        this.showSelectCountry = !this.showSelectCountry
        this.showCountryNameInput = !this.showCountryNameInput
    }

    deleteFromDictionary(key: string): void {
        const curr = this.currentItem()?.[this.key]
        delete curr[key]
        this.update(curr)
    }

    pushToDictionary(key: string | null, value: string): void {
        this.inputedValue = ""
        this.selectedCountry = null
        this.showCountryNameInput = !this.showCountryNameInput
        this.showNewCountryButton = !this.showNewCountryButton
        if (key) {
            const curr = this.currentItem()?.[this.key]
            curr[key] = value
            this.update(curr)
        }
    }

    cancelOnClick(): void {
        this.showSelectCountry = !this.showSelectCountry
        this.showNewCountryButton = !this.showNewCountryButton
    }

    onChange(event: any) {
        const curr = this.currentItem()?.[this.key]
        curr[event.target.id] = event.target.value
        this.update(curr)
    }

    ngOnInit(): void {
        this.setCurrValObjectKeys()
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (!compareDictionaries(this.currentValues, value[this.key])) {
                    this.currentValues = JSON.parse(JSON.stringify(value[this.key]))
                    this.setCurrValObjectKeys()
                }
            }
        })
    }
}
