<div *ngFor="let value of values; let i = index" class="text-xl mb-10 space-y-4">
    <div class="flex gap-5 items-center">
        <hr class="h-2 bg-valamardarkgray rounded-xl w-[10rem]">
        <p class="whitespace-nowrap text-black">Verzija {{ value.version }}</p>
        <hr class="h-2 bg-valamardarkgray rounded-xl w-full">
    </div>

    <div class="flex gap-10">
        <div>
            <b>Izmjena:</b><p class="ml-5 inline-block">{{ value.modifiedByUser }}</p>
        </div>
        <div>
            <b>Vrijeme izmjene:</b><p class="ml-5 inline-block">{{ value.modifiedDate | date: "dd.MM.yyyy., HH:mm:ss" }}</p>
        </div>
    </div>
    <div class="flex gap-10">
        <div class="flex items-center">
            <b>Aktivan:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isActive" [binary]="true" />
        </div>
        <div class="flex items-center">
            <b>Skica:</b><p-checkbox class="ml-5 pointer-events-none" [(ngModel)]="value.isDraft" [binary]="true" />
        </div>
    </div>
    <div>
        <b>Naziv <p-dropdown [options]="value.names | objKeys" [(ngModel)]="nameLangs[i]" />:</b>
        <p class="inline-block ml-5">{{ value.names[nameLangs[i]] }}</p>
    </div>
    <div>
        <b>Opis <p-dropdown [options]="value.descriptions | objKeys" [(ngModel)]="descLangs[i]" />:</b>
        <p class="inline-block ml-5">{{ value.names[descLangs[i]] }}</p>
    </div>
    <div>
        <b>Link slike:</b>
        <a *ngIf="value.imageUrl; else defaultView" href="{{ value.imageUrl }}" target="_blank" class="break-all ml-5 hover:text-valamarbluesecondary cursor-pointer">{{ value.imageUrl }}</a>
    </div>
    <div>
        <b>Vanjska poveznica:</b>
        <a *ngIf="value.link; else defaultView" href="{{ value.link }}" target="_blank" class="break-all ml-5 hover:text-valamarbluesecondary cursor-pointer">{{ value.link }}</a>
    </div>
    <div>
        <b>Kategorije:</b>
        <p class="ml-5 inline-block">{{ value.categoryIds.join(', ') }}</p>
    </div>
    <div>
        <b>Lokacije:</b>
        <p class="ml-5 inline-block">{{ value.locationIds.join(', ') }}</p>
    </div>
    <div>
        <b>Aktivan od - do:</b>
        <p class="ml-5 inline-block">{{ value.activeFrom | date: "dd.MM.yyyy." }} - {{ value.activeTo | date: "dd.MM.yyyy." }}</p>
    </div>
    <div>
        <b>Uključeni zaposlenici:</b>
        <button
            *ngIf="value.dependencies.includedEmployees && value.dependencies.includedEmployees.length > 0; else defaultView"
            class="inline-block ml-5 valben-button pi"
            [ngClass]="showDepends[i][0] ? 'pi-caret-down' : 'pi-caret-right'"
            (click)="toggle(i, 0)">
        </button>
        <valben-virtual-listbox class="block w-fit" *ngIf="showDepends[i][0]" [items]="included[i]" />
    </div>
    <div>
        <b>Isključeni zaposlenici:</b>
        <button
            *ngIf="value.dependencies.excludedEmployees && value.dependencies.excludedEmployees.length > 0; else defaultView"
            class="ml-5 valben-button pi"
            [ngClass]="showDepends[i][1] ? 'pi-caret-down' : 'pi-caret-right'"
            (click)="toggle(i, 1)">
        </button>
        <valben-virtual-listbox class="block w-fit" *ngIf="showDepends[i][1]" [items]="excluded[i]" />
    </div>
    <div>
        <b>Filter grupe:</b>
        <button
            *ngIf="value.dependencies.filterGroups && value.dependencies.filterGroups.length > 0; else defaultView"
            class="ml-5 valben-button pi"
            [ngClass]="showDepends[i][2] ? 'pi-caret-down' : 'pi-caret-right'"
            (click)="toggle(i, 2)">
        </button>
        <ng-container *ngIf="showDepends[i][2]">
            <div class="flex my-2 gap-4" *ngFor="let group of value.dependencies.filterGroups">
                <valben-virtual-listbox
                    *ngIf="group.orgUnits && group.orgUnits.orgUnitIds.length > 0"
                    title="Organizacijske jedinice"
                    [items]="group.orgUnits.orgUnitIds | orgUnitsNames" />
                <valben-virtual-listbox
                    *ngIf="group.costCenters && group.costCenters.costCenters.length > 0"
                    title="Mjesta troška"
                    [items]="group.costCenters.costCenters | costCenterNames" />
                <valben-virtual-listbox
                    *ngIf="group.positions && group.positions.positions.length > 0"
                    title="Pozicije"
                    [items]="group.positions.positions | positionsNames" />
                <valben-virtual-listbox
                    *ngIf="group.contracts && (group.contracts.types.length > 0 || group.contracts.subTypes.length > 0)"
                    title="Ugovori"
                    [items]="group.contracts | contractNames" />
                <valben-virtual-listbox
                    *ngIf="group.genders && group.genders.length > 0"
                    title="Spol"
                    [items]="group.genders" />
            </div>
        </ng-container>
    </div>
</div>

<ng-template #defaultView>
    <p class="inline-block ml-5">/</p>
</ng-template>
