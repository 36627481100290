import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MsalService } from "@azure/msal-angular"
import { AuthenticationResult } from "@azure/msal-browser"
import { tap } from "rxjs"

@Component({
    selector: "app-msal-redirect",
    template: "",
})
export class AppMsalRedirectComponent implements OnInit {
    constructor(
        private authService: MsalService,
        private _r: Router
    ) {}

    ngOnInit(): void {
        this.authService
            .handleRedirectObservable()
            .pipe(
                tap((result: AuthenticationResult) => {
                    if (result !== null && result.account) {
                        this._r.navigateByUrl("login")
                    }
                })
            )
            .subscribe()
    }
}
