import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
    selector: "valben-header",
    templateUrl: "./header.component.html",
})
export class HeaderComponent {
    @Input() contentType!: number
    @Input() editEnabled!: boolean
    @Output() contentTypeChange = new EventEmitter<string>()

    setContentType(value: string) {
        this.contentTypeChange.emit(value)
    }
}
