import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { Award, CreateAward, ModifyObject } from "../models"
import { environment } from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class AwardsService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.awards

    constructor(private http: HttpClient) {}

    Get(): Observable<Award[]> {
        return this.http.get<Award[]>(this.apiUrl)
    }

    Create(createAward: CreateAward): Observable<Award> {
        return this.http.post<Award>(this.apiUrl, createAward)
    }

    Modify(masterId: string, data: ModifyObject): Observable<any> {
        return this.http.patch(this.apiUrl + `/${masterId}`, data)
    }

    // need to change the return type to string in all the services
    Delete(masterId: string): Observable<Award> {
        return this.http.delete<Award>(this.apiUrl + `/${masterId}`)
    }
}
