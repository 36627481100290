import { HttpClient } from "@angular/common/http"
import { Component, Input, OnInit } from "@angular/core"

enum FileType {
    IMAGE = "img",
    VIDEO = "video",
    UNKNOWN = "unknown",
}

@Component({
    selector: "valben-file-modal",
    templateUrl: "./file-modal.component.html",
})
export class FileModalComponent implements OnInit {
    @Input() src: string = ""
    fileType: FileType = FileType.UNKNOWN
    visible: boolean = false
    error: boolean = false
    private readonly _imgExts: string[] = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"]
    private readonly _vidExts: string[]= ["mp4", "webm", "ogg", "avi", "mov"]

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.http.head(this.src, { observe: "response" }).subscribe({
            next: res => {
                this.fileType = FileType.UNKNOWN
                const contentType = res.headers.get("Content-Type")
                if (contentType?.startsWith("image")) {
                    this.fileType = FileType.IMAGE
                } else if (contentType?.startsWith("video")) {
                    this.fileType = FileType.VIDEO
                }
            },
            error: () => {
                this.fileType = FileType.UNKNOWN
                try {
                    const url = new URL(this.src)
                    const fileExt = url.pathname.split(".").pop()?.toLowerCase()
                    if (fileExt && this._imgExts.includes(fileExt)) {
                        this.fileType = FileType.IMAGE
                    } else if (fileExt && this._vidExts.includes(fileExt)) {
                        this.fileType = FileType.VIDEO
                    }
                } catch {}
            },
        })
    }
}
