<p-dropdown
    (onChange)="selectOptionOnClick()"
    [editable]="false"
    [autoDisplayFirst]="false"
    [options]="options"
    [filter]="true"
    styleClass="w-52"
    panelStyleClass="w-52"
    [(ngModel)]="selectedOption"
    optionLabel="name"
></p-dropdown>
