import { Component } from "@angular/core"
import { ContextService } from "src/app/services"
import { Observable } from "rxjs"
import { Input } from "@angular/core"

@Component({
    selector: "valben-locations-multiple-choice",
    templateUrl: "./locations-multiple-choice.component.html",
})
export class LocationsMultipleChoiceComponent {
    @Input() key!: string
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    dataObservable: () => Observable<any> | undefined = () => this.contextService.getLocationsSubject()?.asObservable()

    constructor(private contextService: ContextService) {}
}
