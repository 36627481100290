import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from "@angular/core"
import { Dropdown } from "primeng/dropdown"

@Component({
    selector: "valben-countries-dropdown",
    templateUrl: "./countries-dropdown.component.html",
    styleUrls: ["../../helpers/flags.css"]
})
export class CountriesDropdownComponent implements AfterViewInit {
    @Output() signal = new EventEmitter<{ code: string }>()

    @Input() options!: { code: string, name: string }[]

    focused: boolean = false

    selectedCountry: { code: string; name: string } | undefined = undefined

    focusOnChange(): void {
        this.focused = !this.focused
    }

    selectCountryCodeOnClick(): void {
        if (this.selectedCountry && this.focused) {
            this.signal.emit({
                code: this.selectedCountry.code,
            })
        }
    }

    @ViewChild("dropdown") dropdown!: Dropdown

    ngAfterViewInit(): void {
        if (this.dropdown) {
            this.dropdown.options = this.options
        }
    }
}
